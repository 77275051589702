import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

export function validateCurrentAffairData(formData) {
    if (!formData.title) {
        return "Title is Required";
    } else if (!formData.urlTitle) {
        return "URL Title is Required";
    } else if (!formData.published) {
        return "Published status is Required";
    } else if (!formData.featured) {
        return "Featured status is Required";
    } else if (!formData.description) {
        return "Description is Required";
    } else if (!formData.imageURL) {
        return "Image URL is Required";
    } else if (!formData.imageTitle) {
        return "Image Title is Required";
    } else if (!formData.imageCaption) {
        return "Image Caption is Required";
    } else if (!formData.categories) {
        return "Categories are Required";
    } else if (!formData.primaryCategoryId){
        return "Please Select Category"
    } else if (!formData.metaTitle) {
        return "Meta Title is Required";
    } else if (!formData.metaDescription) {
        return "Meta Description is Required";
    } else if (!formData.publishDate) {
        return "Publish Date is Required";
    } else if (!formData.writtenBy) {
        return "Author Name is Required";
    } else {
        return null; // Return null if all validations pass
    }
}

export async function fetchNewsData(newsId) {
    return (await axios.get(BACKEND_SERVER_URL + '/getCurrentAffair/' + newsId)).data.news
}

export async function formateNewsData(data) {
    return {
        title: data.Title,
        videoURL: data.VideoUrl,
        urlTitle: data.ImageAlt,
        published: data.IsPublish == 1 ? "YES" : "NO",
        featured: data.Features,
        description: data.Description,
        imageURL: data.Image,
        pdfURL: data.NotePDF,
        imageTitle: data.ImageTitle,
        imageCaption: data.ImageCaption,
        categories: data.CategoryTag,
        primaryCategories: data.Category,
        primaryCategoryId: data.CategoryID,
        tags: data.Tag,
        metaTitle: data.MetaTitle,
        metaDescription: data.MetaDescription,
        publishDate: data.PublishDate,
        writtenBy: data.WrittenBy,

    };
}