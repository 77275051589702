import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { IMAGES } from '../../Dashboard/Content';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

const EditStudentProfile = () => {
    const navigate = useNavigate();
    const [id, setId] = useState()
    const [photo, setPhoto] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [contactNumber, setContactNumber] = useState(null)

    console.log(id, name, email, contactNumber, photo, address)

    //for edit student
    const tokenDetailsString = localStorage.getItem('userDetails');
    const studentDetails = JSON.parse(tokenDetailsString)
    useEffect(() => {
        setId(studentDetails.studentId)
        setName(studentDetails.displayName);
        setEmail(studentDetails.email);
        setAddress(studentDetails.address);
        setContactNumber(studentDetails.contact);
        const imageId = studentDetails?.photo;

        axios.get(BACKEND_SERVER_URL + '/getStudentImage/' + imageId, { responseType: 'arraybuffer' }).then((response) => {
            const blob = new Blob([response.data], { type: 'image/png' });
            setPhoto(blob);
        })


    }, [])

    const fileHandler = (e) => {
        setPhoto(e.target.files[0]);
    }
    const RemoveFile = () => {
        setPhoto(null)
    }

    const handleSaveStudent = async () => {
        let formData = new FormData();
        formData.append('uploadedFile', photo, photo?.name);
        // Create an object with student details
        const details = {
            name: name,
            email: email,
            contactNumber: contactNumber,
            address: address,
            oldPhoto: studentDetails?.photo,
        };
        console.log(details)
        // Convert the object to a JSON string and append it to the formData
        formData.append('details', JSON.stringify(details));

        axios.post(BACKEND_SERVER_URL + `/student/update-student/${id}`, formData)
            .then(async (response) => {
                console.log('currnt'.response)
                let tokenDetails = await JSON.parse(localStorage.getItem('userDetails')) || {};
                let updatedData = response.data.updatedData;

                console.log(updatedData)
                console.log(tokenDetails.userRole)
                localStorage.setItem('userDetails', JSON.stringify({ ...updatedData, idToken: tokenDetails.idToken, expireDate: tokenDetails.expireDate, expiresIn: tokenDetails.expiresIn, title: tokenDetails.title, subtitle: tokenDetails.subtitle, rectangularLogo: tokenDetails.rectangularLogo, logo: tokenDetails.logo, userRole: tokenDetails.userRole }));
                toast.success(response.data.message);
                navigate('/student/my-account');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });

    };

    const resetHandler = () => {
        setName('');
        setEmail('');
        setAddress('');
        setContactNumber('');
        setPhoto(null);
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Student Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 d-flex  justify-content-between ">
                                    <div className="col-xl-3 col-lg-3">
                                        <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                        <div className="avatar-upload">
                                            <div className="avatar-preview">
                                                <div id="imagePreview"
                                                    style={{ backgroundImage: photo ? "url(" + URL.createObjectURL(photo) + ")" : "url(" + IMAGES.noimage + ")" }}
                                                >
                                                </div>
                                            </div>
                                            <div className="change-btn mt-2 mb-lg-0 mb-3">
                                                <input type='file' className="form-control d-none" onChange={(e) => fileHandler(e, 'photo')} id="imageUpload1" accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload1" className="dlab-upload mb-0 btn btn-primary btn-xs">Choose File</label>
                                                <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-xs" onClick={() => RemoveFile('photo')}>Remove</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row mt-3">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="James" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="+123456789" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Email<span className="required">*</span></label>
                                                <input type="email" className="form-control" id="exampleFormControlInput3" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label text-primary">Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={address} onChange={(e) => setAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex justify-content-between mt-0">
                                    <div>
                                        <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/student/my-account')} >Go Back</button>
                                    </div>
                                    <div>
                                        <button className="btn btn-outline-warning me-3 btn-sm" onClick={resetHandler}>Reset</button>

                                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleSaveStudent}>{!id ? 'Save Student' : 'Update Student'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditStudentProfile