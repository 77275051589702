import axios from "axios"
import { BACKEND_SERVER_URL } from "../../../../http/http-config"


export async function getTopic(topicId) {
    return (await axios.get(BACKEND_SERVER_URL + `/getTopic/${topicId}`)).data.topic
}


export function validateTopicData(formData) {
    const requiredFields = ['title', 'packageId', 'courseId', 'subject', 'description', 'imageURL', 'pdfURL', 'videoContent', 'tags','videoId'];

    for (const field of requiredFields) {
        if (!formData[field]) {
            return `${field} is Required`;
        }
    }

    return null; // Return null if all validations pass
}
