import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import Input from '../../Common-Components/Input';
import FileInput from '../../Common-Components/FileInput';
import DropdownInput from '../../Common-Components/DropdownInput';
import DateInput from '../../Common-Components/DateInput';
import '../../Course/Course.css'
import CKEditorInput from '../../Common-Components/CKEditorInput';
import { generateUniqueID } from './../CourseHelper';
import { getCourses, getSubjects, getTopic, validateTopicData } from './TopicHelper';
import CourseAndSubjectDropDown from '../../Common-Components/CourseAndSubjectDropDown';



const TopicCount = forwardRef((props, ref) => {
    let initialFormData = {
        title: '',
        sNo: 0,
        sectionId: generateUniqueID(),
        packageId: '',
        courseId: '',
        subject: '',
        subjectId: 0,
        demo: 'NO',
        publishDate: new Date(),
        description: '',
        imageURL: '',
        pdfURL: '',
        videoContent: '',
        videoId: '',
        tags: '',
    }
    const [modalBox, setModalBox] = useState(false);    //new course
    const [updatingTopicId, setUpdatingTopicId] = useState();



    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    console.log(formData)
    const handleChange = (name, value) => {
        console.log(name, value)
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useImperativeHandle(ref, () => ({
        openModal(topicId) {
            if (topicId) {
                setUpdatingTopicId(topicId)
                getTopic(topicId).then((res) => {
                    console.log(res)
                    setFormData(res);
                    setUpdatingDataReady(true);
                    setModalBox(true);
                })
            } else {
                setUpdatingDataReady(true);
                setModalBox(true);
            }
        }
    }));

    const handleSubmitBlog = () => {
        let errorMessgae = validateTopicData(formData)
        console.log(errorMessgae)
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        }

        axios.post(BACKEND_SERVER_URL + (updatingTopicId ? `/updateTopic` : '/saveTopic'), formData).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                setModalBox(false);
                props.setTopicsLoading(!props.loadTopics)
                setUpdatingTopicId(0);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setFormData(initialFormData); // Reset form data when modal is closed 
            setUpdatingTopicId(0)
        }
    }, [modalBox]);


    return (
        <>
            {((updatingTopicId && updatingDataReady && modalBox) || (!updatingTopicId && modalBox /* && formData.packageId */)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingTopicId ? 'Update Topic' : 'Add Topic'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around  ">
                                <CourseAndSubjectDropDown setFormData={setFormData} formData={formData} courseId={formData.courseId} subjectId={formData.subjectId} />
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Package ID" inputType="text" inputValue={formData?.packageId} inputName='packageId' divSizes={[4, 8]} />
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Section ID" inputType="text" inputValue={formData?.sectionId} inputName='sectionId' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Demo" selectedValue={formData?.demo} inputName='demo' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="S. No." inputType="number" inputValue={formData?.sNo} inputName='sNo' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Video Content" inputType="number" inputValue={formData?.videoContent} inputName='videoContent' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Video ID" inputType="text" inputValue={formData?.videoId} inputName='videoId' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="PDF URL" inputType="text" uploadedFileURL={formData?.pdfURL} inputName='pdfURL' accept="pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Tags" inputType="textarea" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitBlog}>{updatingTopicId ? 'Update Topic' : 'Add Topic'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default TopicCount;