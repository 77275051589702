import React, { useEffect, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Button, Modal, Col, Card, Row, Alert, Spinner, Table, Badge } from "react-bootstrap";
//import { SRLWrapper } from "simple-react-lightbox";
import { LuCheck, LuX } from "react-icons/lu";
import { getRemarks, convertSpentTimeToSeconds } from "../StartTest/helper";

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import PageTitle from "../../../layouts/PageTitle";

//charts
import { QuestionPieChart, TimePieChart } from "./PeiChart";
import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_SERVER_URL } from "../../../../http/http-config";

const TestReport = ({ selectedTestId, selectedBatchId }) => {
    const { state } = useLocation();
    // Access the values
    console.log(selectedBatchId, selectedTestId)
    const batchId = state?.batchId || selectedBatchId;
    const testId = state?.testId || selectedTestId;
    const [testReport, setTestReport] = useState();
    const [shortReport, setShortReport] = useState([]);
    const [toppersDetails, setToppersDetails] = useState([]);
    const [averageDetails, setAverageDetails] = useState([]);

    useEffect(() => {
        if (!testId || !batchId) return;
        axios.get(`${BACKEND_SERVER_URL}/student/getTestReport`, {
            params: {
                param1: testId,
                param2: batchId
            }
        }).then((res) => {
            if (res.data.testResult) {
                setTestReport(res.data.testResult)
                setShortReport(res.data.testResult.arrayOfQuestionObj)
                setToppersDetails(res.data.testResult.toppersDetails)
                setAverageDetails(res.data.testResult.averageDetails)
            }
        }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message);
        });

    }, [testId, batchId])



    return (
        <div>
            {testReport ? <div>
                <PageTitle
                    activeMenu="Post Details"
                    motherMenu="Advanced"
                    pageContent="Post Details"
                />
                {/* row */}
                <div className="row">
                    <div className="col-xl-12 " >
                        <div className="card" style={{ background: "#093145" }}>
                            <div className="card-body">
                                <div className="">
                                    <h4 className="card-title text-center text-white "><span style={{ color: '#bca136' }}>Hi {" "}</span> {testReport?.studentName}</h4>
                                    <h5 className="card-title text-center " style={{ color: '#bca136' }}>Your rank is <span className="text-white">{testReport?.rank}</span> out of  <span className="text-white">{testReport?.studentCount}</span> examinees  </h5>
                                    {/*  <h5 className="card-title text-center text-white">Your performance is better than 49% candidates</h5> */}
                                    <div className="row pt-3 text-center text-white">
                                        <div className="col-md-3">
                                            <h5 id="score" className="text-white">
                                                {testReport?.finalMarks}/ {testReport?.totalMarks}
                                            </h5>
                                            <label htmlFor="score" className="text-white">
                                                Score
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <h5 id="attempted" className="text-white">
                                                {testReport?.correctQuestion + testReport?.wrongQuestion}/ {testReport?.totalQuestions}
                                            </h5>
                                            <label htmlFor="attempted" className="text-white">
                                                Qs Attempted
                                            </label>
                                        </div>
                                        <div className="col-md-3" >
                                            <h5 id="timeSpent" className="text-white">
                                                {testReport?.timeSpent}
                                            </h5 >
                                            <label htmlFor="timeSpent" className="text-white">
                                                Time spent
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <h5 id="accuray" className="text-white">
                                                {((testReport?.correctQuestion * 100) / testReport?.totalQuestions).toFixed(2)} %
                                            </h5>
                                            <label htmlFor="accuray">
                                                Accuracy
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-6">
                        <div className=" card ">
                            <div className="card-body">
                                <TimePieChart correctTime={testReport?.totalCorrectTime} inCorrectTime={testReport?.totalInCorrectTime} skipTime={testReport?.skipTime} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className=" card ">
                            <div className="card-body">
                                <QuestionPieChart correctQuestions={testReport?.correctQuestion} wrongQuestions={testReport?.wrongQuestion} skipQuestions={testReport?.skipQuestion} />
                            </div>
                        </div>
                    </div>
                </div>
                {" "}
                {/*  <div className="row mt-0 pt-0">
                    <div className="col-lg-12  mt-0 pt-0">
                        <div className="card  mt-0 pt-0">
                            <h2>Raaz</h2>
                        </div>
                    </div>
                </div> */}
                <div className="row ">
                    <div className="col-md-4 ">
                        <h4 className="text-center "> Cell Structure Camparision</h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h5 className="">Score Comparision</h5>
                                    </div>
                                    <div className="card-body pt-0"	>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Your Score</strong>
                                                <span className="mb-0 text-primary">{testReport?.finalMarks}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Topper's Score</strong>
                                                <span className="mb-0 text-primary">{toppersDetails[0]?.finalMarks}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Average Score</strong>
                                                <span className="mb-0 text-primary">{averageDetails.averageMarks}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h5 className="">Accuracy Comparision</h5>
                                    </div>
                                    <div className="card-body pt-0"	>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Your Accuracy</strong>
                                                <span className="mb-0 text-primary"> {((testReport?.correctQuestion * 100) / testReport?.totalQuestions).toFixed(2)} %</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Topper's Accuracy</strong>
                                                <span className="mb-0 text-primary">{((toppersDetails[0]?.correctQuestion * 100) / testReport?.totalQuestions).toFixed(2)} %</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Average Accuracy</strong>
                                                <span className="mb-0 text-primary">{averageDetails.averageAccuracy.toFixed(2)} %</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h5 className="">Time Comparision</h5>
                                    </div>
                                    <div className="card-body pt-0"	>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Your Time</strong>
                                                <span className="mb-0 text-primary"> {convertSpentTimeToSeconds(testReport?.timeSpent ?? '00:00:00')} SEC</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Topper's Time</strong>
                                                <span className="mb-0 text-primary">{convertSpentTimeToSeconds(toppersDetails[0]?.timeSpent ?? '00:00:00')} SEC</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Average Time</strong>
                                                <span className="mb-0 text-primary">{averageDetails.averageTimeSpent} SEC</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h5 className="">Questions Attempted</h5>
                                    </div>
                                    <div className="card-body pt-0"	>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>You</strong>
                                                <span className="mb-0 text-primary">{testReport?.correctQuestion + testReport?.wrongQuestion}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Topper</strong>
                                                <span className="mb-0 text-primary">{toppersDetails[0]?.correctQuestion + toppersDetails[0]?.wrongQuestion}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Average Attempts</strong>
                                                <span className="mb-0 text-primary">{averageDetails.averageAttemptedQuestions}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h5 className="">Top Perfomers</h5>
                                    </div>
                                    <div className="card-body pt-0"	>
                                        <div className="col-md-12">
                                            <h5 id="rank1" className="text-primary">
                                                {toppersDetails[0]?.studentName}
                                            </h5>
                                            <label htmlFor="rank1">
                                                <strong> RANK:  {toppersDetails[0]?.rank} </strong>
                                                <strong className="ms-3"> score: {toppersDetails[0]?.finalMarks}/{testReport?.totalQuestions} </strong>
                                            </label>
                                        </div>
                                        <div className="col-md-12">
                                            <h5 id="rank2" className="text-primary">
                                                {toppersDetails[1]?.studentName}
                                            </h5>
                                            <label htmlFor="rank2">
                                                <strong> RANK: {toppersDetails[1]?.rank} </strong>
                                                <strong className="ms-3"> score: {toppersDetails[1]?.finalMarks}/{testReport?.totalQuestions}</strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8 ">
                        <h4 className="text-start ms-3 "> Personalized Remarks</h4>
                        <div className="card h-auto">
                            <div className="card-body">
                                <Alert variant="light" className="solid alert-square">
                                    {getRemarks('performance', testReport?.finalPercentage || 0)}
                                </Alert>
                                <Alert variant="light" className="solid alert-square">
                                    {getRemarks('speed', (convertSpentTimeToSeconds(testReport?.timeSpent ?? '00:00:00') * 100) / (testReport?.totalTime * 60))}

                                </Alert>
                                <Alert variant="light" className="solid alert-square">
                                    {getRemarks('accuracy', (testReport?.correctQuestion * 100) / testReport?.totalQuestions)}
                                </Alert>
                            </div>
                        </div>
                        <Col lg={12}>
                            <Card>
                                <Card.Header className="">
                                    <Card.Title>Short Report</Card.Title>
                                </Card.Header>
                                <Card.Body className=" mt-1 pt-1">
                                    <Table responsive bordered className="text-center mt-0 pt-0">
                                        <thead>
                                            <tr>
                                                <th>Q.N.</th>
                                                <th>Correct Ans.</th>
                                                <th>Selected Ans.</th>
                                                <th>Time Spent</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shortReport?.map((item, ind) => (
                                                <tr key={ind}>
                                                    <th>{item.questionNumber}</th>
                                                    <td>{item.correctAnswer}</td>
                                                    <td>{item.studentAnswer}</td>
                                                    <td>{item.timeSpent} SEC</td>
                                                    <td>
                                                        {item.correctAnswer === item.studentAnswer ? (
                                                            <LuCheck color="green" size={25} />
                                                        ) : (
                                                            <LuX color="red" size={25} />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>


                                    </Table>

                                </Card.Body>
                                <div className="d-flex justify-content-center ">
                                    <div className="col-6 p-2">
                                        <button
                                            type="button"
                                            className="btn btn-info btn-sm btn-block m-auto ps-0 pe-3 w-75 p-auto"
                                        >
                                            View Complete Report
                                        </button>
                                    </div>
                                </div>

                            </Card>
                        </Col>
                    </div>
                </div>
            </div> :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Spinner animation="grow" variant="info" />
                </div>
            }
        </div>
    );
};

export default TestReport;