import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorInput = ({ inputValue, handleChangeCallBack, labelName, inputName }) => {
    return (
        <>
            <div className="col-xl-2 col-sm-2">
                <div className="card-body  ms-0 ps-0" >
                    <label htmlFor={labelName} className="form-label text-primary ">{labelName}<span className="required">*</span></label>
                </div>
            </div>
            <div className="col-xl-10 col-sm-10">
                <div className="card">
                    <div className="card-body custom-ekeditor ms-0 ps-0">
                        <CKEditor
                            editor={ClassicEditor}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            data={inputValue}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleChangeCallBack(inputName, data)

                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                                //console.log( 'Focus.', editor );
                            }}
                        />
                    </div>
                </div>

            </div>
        </>
    );
}

export default CKEditorInput;
