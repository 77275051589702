import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import ToggleButton from 'react-toggle-button'
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import TimePickerPicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button } from 'react-bootstrap';

const AddComprehension = () => {
    const { id } = useParams();
    const [comprehensionName, setComprehensionName] = useState('');
    const [beforeQuestion, setBeforeQuestion] = useState(null);
    const [comprehensionDetails, setComprehensionDetails] = useState('');
    const [tests, setTests] = useState();
    const [testNames, setTestNames] = useState();
    const [selectedTestName, setSelectedTestName] = useState('Select Test Name');
    const [testId, setTestId] = useState();
    console.log(testId, comprehensionDetails, selectedTestName, beforeQuestion, comprehensionName)


    const navigate = useNavigate();

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getTestsForQBank').then((response) => {
            const tests = response.data.tests;
            setTests(tests);
            const testNameArray = tests.map((test) => test?.testName);
            setTestNames(testNameArray);
        })
    }, [])
    const selectTestHandler = async (value) => {
        setSelectedTestName(value);
        let test = tests?.find((test) => test.testName == value);
        setTestId(test?.id);
    };

    //for edit
    useEffect(() => {
        if (id) {
            axios.get(BACKEND_SERVER_URL + '/getComprehension/' + id).then((res) => {
                if (res.data.comprehension) {
                    var comprehension = res.data.comprehension;
                    setComprehensionDetails(comprehension[0].comprehensionDetails);
                    setComprehensionName(comprehension[0].comprehensionName);
                    setBeforeQuestion(comprehension[0].beforeQuestion)

                    const test = tests?.find((test) => test.id == comprehension[0].testId);
                    setTestId(comprehension[0].testId);
                    setSelectedTestName(test?.testName);
                }
            }).catch((error) => {
                console.error("Error fetching comprehension details:", error);

            });
        }
    }, [id, tests]);

    //handle submit  

    const submitComprehensionHandler = async () => {

        if (!comprehensionName || !testId || !comprehensionDetails) {
            toast.error('Please enter all required fields');
            return;
        }
        axios.post(BACKEND_SERVER_URL + `${id ? `/editComprehension/${id}` : '/createComprehension'}`, { comprehensionName, beforeQuestion, testId, comprehensionDetails })
            .then((res) => {
                if (res.data.statusCode == 201) {
                    toast.success(res.data.message);
                    navigate('/comprehensions');
                } else {
                    toast.error(res.data.message);
                }
                //reset form
                // resetTestHandler();
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }
    //reset form
    const resetTestHandler = () => {
        setComprehensionName('');
        setComprehensionDetails('')
        setSelectedTestName('Select Test Name')
    }
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Comprehension Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Comprehension Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" required id="exampleFormControlInput1" placeholder="Enter Comprehension Title" value={comprehensionName} onChange={(e) => setComprehensionName(e.target.value)} />
                                                {/*         {testName.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                                                {isTestExisted && <p className='text-success'>This test already exists for this admin</p>} */}
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput2" className="form-label text-primary">Before Question<span className="required">*</span></label>
                                                <input type="number" className="form-control" required id="exampleFormControlInput2" placeholder="Enter Before Question value" value={beforeQuestion} onChange={(e) => setBeforeQuestion(e.target.value)} />
                                                {/*         {testName.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                                                {isTestExisted && <p className='text-success'>This test already exists for this admin</p>} */}
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="col-sm-12">
                                                <div className="basic-dropdown m-auto">
                                                    <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Select Test<span className="required">*</span></label>
                                                    <Select
                                                        value={{ value: selectedTestName, label: selectedTestName }}
                                                        onChange={(selectedOption) => selectTestHandler(selectedOption.value)}
                                                        options={testNames?.map((testName) => ({ value: testName, label: testName }))}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                minWidth: '100%',
                                                                backgroundColor: 'transparent', // Adjust as needed
                                                                borderColor: '#17a2b8', // Adjust as needed
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-0">
                                            <div className="card pt-0">
                                                <div className="card-header mb-0">
                                                    <p className="card-title mb-0">Details{/*  {selectedQuestionNumber > 0 ? selectedQuestionNumber : null} */} </p>
                                                </div>
                                                <div className="card-body custom-ekeditor">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        // data="<p>Hello from CKEditor 5!</p>"
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                        }}
                                                        data={comprehensionDetails}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setComprehensionDetails(data)
                                                        }}
                                                        onBlur={(event, editor) => {
                                                        }}
                                                        onFocus={(event, editor) => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" d-flex justify-content-between mt-0">
                                            <div>
                                                <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/comprehensions')}>
                                                    Go Back
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn btn-outline-warning me-3 btn-sm" onClick={resetTestHandler}>Reset</button>

                                                <button className="btn btn-outline-primary btn-sm" type="button" onClick={submitComprehensionHandler}>{id ? 'Update Comprehension' : 'Create Comprehension'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddComprehension;