import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const CoursTypeModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort:0,
        courseType: '',
    }

    const [courseTypeData, setCourseTypeData] = useState(initialData);
    console.log(courseTypeData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setCourseTypeData({ sn: item.sn, courseType: item.courseType ,sort: item.sort })
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        if (courseTypeData.courseType.trim().length === 0) {
            toast.error("Please Enter Course Type")
        }
        axios.post(BACKEND_SERVER_URL + `${!courseTypeData.sn ? '/saveCourseType' : `/updateCourseType`}`, courseTypeData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setCourseTypeData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setCourseTypeData(initialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {courseTypeData.sn ? "Update Course Type" : "Add Course Type "}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Course Type<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={courseTypeData.courseType} onChange={(e) => setCourseTypeData({ ...courseTypeData, courseType: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={courseTypeData.sort} onChange={(e) => setCourseTypeData({ ...courseTypeData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{courseTypeData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default CoursTypeModal;