import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Modal } from 'react-bootstrap';
import { ListItem } from '@mui/material';
import Select from 'react-select';

const SubscriberModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [currentOrder, setCurrentOrder] = useState('');
    const [currentCourseBatches, setCurrentCourseBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState([]);
    console.log(currentOrder)
    useImperativeHandle(ref, () => ({
        openModal(item = {}, allActivebatches) {
            console.log(item)
            setCurrentOrder(item)
            setSelectedBatch(item)
            setCurrentCourseBatches(allActivebatches.filter(batch => batch.courseId == item.courseId && batch.batchName != item.batchName))
            setModalBox(true)
        }
    }));

    const handleUpdateBatch = async () => {
        try {
            console.log('selected batch to be sent', { sn: currentOrder.sn, batchId: selectedBatch.sn })
            const res = await axios.post(BACKEND_SERVER_URL + '/updateSubscriberBatch', { sn: currentOrder.sn, batchId: selectedBatch.sn });
            if (res?.data.statusCode == 200) {
                toast.success(res.data.message);
                props.setStudentLoading(!props.loadStudents);
                setModalBox(false);
            }
        } catch (error) {
            console.error("An error occurred during the request:", error.message);

        }
    }
    useEffect(() => {
        if (!modalBox) {
            setCurrentCourseBatches([]);
            setCurrentOrder('');
            setSelectedBatch([]);
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <Fragment>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">Change Batch</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-end">
                                                <div className="col-4 d-flex justify-content-start">
                                                    <h4>Current Batch:</h4>
                                                </div>
                                                <div className="col-8">
                                                    <h4 className='text-success'>{currentOrder.batchName}</h4>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex">
                                                <div className="col-4 d-flex justify-content-start" >
                                                    <h4>New  Batch:</h4>
                                                </div>
                                                <div className="col-8">
                                                    <Select
                                                        value={{ value: selectedBatch, label: selectedBatch.batchName }}
                                                        onChange={(selectedOption) => setSelectedBatch(selectedOption.value)}
                                                        options={currentCourseBatches?.map((batch) => ({ value: batch, label: batch.batchName }))}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                                borderColor: '#17a2b8',
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer pb-0">
                                    <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                                    <button className='btn btn-primary  btn-xs ' onClick={handleUpdateBatch}>Change Batch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment >
            </Modal>
        </>
    )
})
export default SubscriberModal;