import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Modal, Table } from 'react-bootstrap';
import { ListItem } from '@mui/material';
import Select from 'react-select';
import '../common-css/Table.css'

const StudentsModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [courses, setCourses] = useState([]);
    const [selectedCourseId, setCourseId] = useState();
    console.log(selectedCourseId)

    const [subjectData, setSubjectData] = useState([])

    const [updatingSubjectData, setUpdatingSubjectData] = useState({ sn: 0, subjectName: '', subjectOrderNo: 0, })
    console.log(courses)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setUpdatingSubjectData({
                    sn: item.sn,
                    subjectName: item.subject,
                    subjectOrderNo: item.subjectOrderNo,
                })

            } else {
                axios.get(BACKEND_SERVER_URL + '/getCoursesForSubjects').then((res) => {
                    if (res.data) {
                        let courses = res.data.courses;
                        setCourses(courses)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
            setModalBox(true)
        }
    }));

    const handleSaveSubjects = async () => {
        if (!updatingSubjectData.sn && !selectedCourseId) {
            toast.error("Please Select any Course")
            return;
        }
        try {
            const res = await axios.post(BACKEND_SERVER_URL + (updatingSubjectData?.sn ? `/updateSubject` : '/createSubjects'), updatingSubjectData?.sn ? updatingSubjectData : { subjectData, selectedCourseId });
            if (res?.data.statusCode == 201) {
                toast.success(res.data.message);
                props.setSubjectLoading(!props.loadSubjects);
                setUpdatingSubjectData({})
                setModalBox(false);
            }
        } catch (error) {
            toast.error(error.response.data.message)
            console.error("An error occurred during the request:", error);

        }
    }
    useEffect(() => {
        if (!modalBox) {
            setSubjectData([]); // Reset form data when modal is closed
            setUpdatingSubjectData({})
        }
    }, [modalBox]);
    const handleNumberOfSubjectSelect = (number) => {
        let arr = new Array(number).fill({ sn: 1, subjectName: '', subOrderNo: 0 })
        let newArr = arr.map((element, index) => {
            return {
                sn: index + 1, subjectName: '', subOrderNo: 0
            }
        })
        setSubjectData(newArr)
    }
    const handleChange = (index, fieldName, value) => {
        let copy = [...subjectData]
        copy[index] = { ...copy[index], [fieldName]: value }
        setSubjectData(copy)
    }
    return (
        <>
            {
                !updatingSubjectData?.sn ? <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                    keyboard={false} >
                    <Fragment>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">{subjectData.sNo ? 'Update Subjects' : 'Create Subjects'}</h5>
                                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                                    </div>
                                    <div className="card-body pt-2 mt-0">
                                        <div className="row">
                                            <div className="col-10 m-auto d-flex  justify-content-around mb-3 ">
                                                <div className="col-4">
                                                    <label htmlFor="currentYear" className="form-label text-primary m-auto">No. of Subjects<span className="required">*</span></label>
                                                    <div className="basic-dropdown">
                                                        <Select
                                                            //  isDisabled={batchData.sNo}
                                                            //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                            onChange={(selectedOption) => handleNumberOfSubjectSelect(selectedOption?.value)}
                                                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((number) => ({ value: number, label: number }))}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    width: '100%',
                                                                    backgroundColor: 'white',
                                                                    borderColor: '#17a2b8',
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    {subjectData.length > 0 ? <>
                                                        <label htmlFor="currentYear" className="form-label text-primary m-auto">Course<span className="required">*</span></label>
                                                        <div className="basic-dropdown">
                                                            <Select
                                                                //  isDisabled={batchData.sNo}
                                                                //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                                onChange={(selectedOption) => setCourseId(selectedOption?.value.courseId)}
                                                                options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        width: '100%',
                                                                        backgroundColor: 'white',
                                                                        borderColor: '#17a2b8',
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </> : null
                                                    }
                                                </div>
                                            </div>




                                            <div className="col-sm-10 m-auto ">
                                                <Table responsive bordered className="text-center mt-0 pt-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th>SN.</th>
                                                            <th className='text-start'>SUBJECT NAME</th>
                                                            <th>SUBJECT ORDER  NO.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subjectData?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: "100px" }}><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                                                                <td > <input className='form-control' value={subjectData[index].subjectName} type="text" name="" id="" onChange={(e) => handleChange(index, 'subjectName', e.target.value)} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={subjectData[index].subOrderNo} type="number" name="" id="" onChange={(e) => handleChange(index, 'subOrderNo', e.target.value)} /></td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer pb-0">
                                        <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                                        <button className='btn btn-primary  btn-xs ' onClick={handleSaveSubjects}>{subjectData.sNo ? 'Update Subjects' : 'Create Subjects'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment >
                </Modal >
                    :
                    <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                        keyboard={false} >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> Update Subject</h5>
                                <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="subjectName" className="form-label text-primary">Subject<span className="required">*</span></label>
                                    <input className='form-control' type="text" name="" id="subjectName" value={updatingSubjectData.subjectName} onChange={(e) => setUpdatingSubjectData({ ...updatingSubjectData, subjectName: e.target.value })} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="sort" className="form-label text-primary">Order No.<span className="required">*</span></label>
                                    <input className='form-control' type="number" name="" id="sort" value={updatingSubjectData.subjectOrderNo} onChange={(e) => setUpdatingSubjectData({ ...updatingSubjectData, subjectOrderNo: e.target.value })} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                                <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveSubjects}>Update</button>
                            </div>
                        </div>
                    </Modal>
            }
        </>
    )
})
export default StudentsModal;




























































































































































// import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify'
// import BatchSampleFile from '../../../download files/batch_sample_file.docx'
// import { BACKEND_SERVER_URL } from '../../../http/http-config';
// import DatePicker from "react-datepicker";


// import Select from 'react-select';
// import { getCourses } from '../Batch-Management/BatchHelper';


// const SubjectsModal = forwardRef((props, ref) => {
//     const [modalBox, setModalBox] = useState(false);

//     let initialData = {
//         sn: 0,
//         sort: 0,
//         inputYear: '',
//         selectedCourse: 'selecte Course'
//     }

//     const [academicYearData, setAcademicYearData] = useState(initialData);
//     console.log(academicYearData)
//     useImperativeHandle(ref, () => ({
//         openModal(item = {}) {
//             console.log(item)
//             if (item.sn > 0) {
//                 setAcademicYearData({ sn: item.sn, inputYear: item.academicYear, selectedCourse: item.currentYear ? 'YES' : 'NO', sort: item.sort })
//             }
//             setModalBox(true);

//         }
//     }));

//     const hanldeSaveAcademicYear = (value) => {
//         if (academicYearData.inputYear.trim().length === 0) {
//             toast.error("Please EnterSubject")
//         }
//         axios.post(BACKEND_SERVER_URL + `${!academicYearData.sn ? '/saveAcademicYear' : `/updateAcademicYear`}`, { ...academicYearData, currentYear: academicYearData.selectedCourse === 'YES' ? 1 : 0 }).then((response) => {
//             if (response.data.statusCode === 201) {
//                 toast.success(response.data.message)
//                 /*  setSn(0);
//                  setInputYear(''); */
//                 props.setAcademicYearLoading(!props.loadAcademicYear);
//                 setAcademicYearData(initialData)
//                 setModalBox(false);
//             }
//         }).catch(error => {
//             console.log(error)
//             toast.error(error.response.data.message)
//         })

//     }
//     useEffect(() => {
//         if (!modalBox) {
//             setAcademicYearData(initialData); // Reset form data when modal is closed
//         }
//     }, [modalBox]);

//     return (
//         <>
//             <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
//                 keyboard={false} >
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title" id="exampleModalLabel"> {academicYearData.sn ? "Update Subject" : "Add Subject"}</h5>
//                         <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="mb-3">
//                             <label htmlFor="inputAcademicYear" className="form-label text-primary">Subject<span className="required">*</span></label>
//                             <input className='form-control' type="text" name="" id="inputAcademicYear" value={academicYearData.inputYear} onChange={(e) => setAcademicYearData({ ...academicYearData, inputYear: e.target.value })} />
//                         </div>
//                         <div className="mb-3">
//                             <label htmlFor="currentYear" className="form-label text-primary">Select Course<span className="required">*</span></label>
//                             <Select
//                                 isDisabled={academicYearData.sNo}
//                                 value={academicYearData.selectedCourse ? { value: academicYearData.selectedCourse, label: academicYearData.selectedCourse } : null}
//                                 onChange={(selectedOption) => setAcademicYearData({ ...academicYearData, selectedCourse: selectedOption?.value })}
//                                 options={['YES', 'NO']?.map((currentYear) => ({ value: currentYear, label: currentYear }))}
//                                 styles={{
//                                     control: (provided) => ({
//                                         ...provided,
//                                         width: '100%',
//                                         backgroundColor: 'white',
//                                         borderColor: '#17a2b8',
//                                     }),
//                                 }}
//                             />
//                         </div>
//                         <div className="mb-3">
//                             <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
//                             <input className='form-control' type="number" name="" id="sort" value={academicYearData.sort} onChange={(e) => setAcademicYearData({ ...academicYearData, sort: Number(e.target.value) })} />
//                         </div>

//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
//                         <button type="button" className="btn btn-primary btn-xs " onClick={hanldeSaveAcademicYear}>{academicYearData.sn ? "Update" : "Add "}</button>
//                     </div>
//                 </div>
//             </Modal>

//         </>
//     )
// })
// export default SubjectsModal;