import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import ToggleButton from 'react-toggle-button'
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import TimePickerPicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import './test.css'
const AddNewTest = () => {
    const { id } = useParams();

    const [testName, setTestName] = useState(null);
    const [paidTest, setPaidTest] = useState(1);
    const [startTime, setStartTime] = useState('00:01');
    const [endTime, setEndTime] = useState('23:59');
    const [totalTime, setTotalTime] = useState(60);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalMarks, setTotalMarks] = useState(0);
    const [passingMarks, setPassingMarks] = useState(0);
    // const [negativeMarks, setNegativeMarks] = useState(0);
    const [priority, setPriority] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [instantResult, setInstantResult] = useState(1);
    const [totalQuestions, setTotalQuestions] = useState();
    const navigate = useNavigate();
    const [isTestExisted, setIsTestExisted] = useState(false);
    const [isSectionDisabled, setIsSectionDisabled] = useState(false);

    const intialSectionDetails = { sectionName: "Section 1", sectionx: 'section1', totalQuestion: 10, marksPerQestion: 1, negativeMarks: 0, passingMarks: 0 }
    const [sectionsDetails, setSectionDetails] = useState([intialSectionDetails])

    const sentionHandler = (index, field, value) => {

        let newSection = { ...sectionsDetails[index] };

        newSection[field] = value;

        let newSectionDetails = [...sectionsDetails];
        newSectionDetails[index] = { ...newSection, sectionx: value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() };
        console.log(newSectionDetails)
        setSectionDetails(newSectionDetails)
    }
    //calculating total Questoins
    useEffect(() => {
        const totalQuestions = sectionsDetails.reduce((accumulator, currentSection) => accumulator + Number(currentSection.totalQuestion), 0);
        setTotalQuestions(totalQuestions)
        let totalMarks = 0;
        sectionsDetails.forEach((section) => {
            totalMarks += section.totalQuestion * section.marksPerQestion;
        });

        setTotalMarks(totalMarks);

    }, [sectionsDetails])


    const addSectionHandler = () => {
        setSectionDetails([...sectionsDetails, intialSectionDetails])


    }
    const removeSectionHandler = (index) => {
        // Create a new array without the section at the specified index
        if (sectionsDetails.length > 1) {
            const sectionsDetailsRef = [...sectionsDetails];
            sectionsDetailsRef.splice(index, 1);
            setSectionDetails(sectionsDetailsRef);
        }

    };

    //for edit test
    useEffect(() => {
        if (id) {
            axios.get(BACKEND_SERVER_URL + '/getTest/' + id).then((res) => {
                if (res.data.test) {
                    var test = res.data.test[0]
                    let dateArr = test.startDate.split('/');
                    setStartDate(new Date([dateArr[1], dateArr[0], dateArr[2]].join('/')));

                    setTestName(test.testName);
                    setPaidTest(test.paidTest);
                    // setStartDate(new Date(test.startDate));
                    setStartTime(test.startTime);
                    dateArr = test.endDate.split('/');
                    setEndDate(new Date([dateArr[1], dateArr[0], dateArr[2]].join('/')));
                    // setEndDate(new Date(test.endDate));
                    setEndTime(test.endTime);
                    setTotalTime(test.totalTime);
                    setTotalMarks(test.totalMarks);
                    setPassingMarks(test.passingMarks);
                    // setNegativeMarks(test.negativeMarks);
                    setPriority(test.priority);
                    setRemarks(test.remarks);
                    setInstantResult(test.instantResult);
                    setSectionDetails(JSON.parse(test.sectionDetails))
                }
            })
            axios.get(BACKEND_SERVER_URL + '/getTestQuestions/' + id).then((res) => {
                if (res?.data?.questions?.length != 0) {
                    setIsSectionDisabled(true)
                }
            })
        }


    }, [id])
    //handle submit

    //test validation

    const submitTestHandler = async () => {
        console.log(sectionsDetails)
        if (!testName || !startDate || !endDate || !startTime || !endTime) {
            toast.error('Please enter all required fields');
            return;
        } else if (isTestExisted && testName.length > 0) {
            toast.error("This Test Name already exist")
            return;
        } else if (sectionsDetails.length < 1) {
            toast.error("Atleast 1 section is required")
        }

        let sections = sectionsDetails.filter(section => section.sectionName)
        if (sections.length < 1) {
            toast.error("Atleast 1 section is required ")

        }

        axios.post(BACKEND_SERVER_URL + `${id ? `/editTest/${id}` : '/createTest'}`, { testName, totalMarks, paidTest, passingMarks, priority, remarks, totalTime, startTime, startDate: startDate.toLocaleDateString('en-GB'), endDate: endDate.toLocaleDateString('en-GB'), instantResult,/*  negativeMarks, */ endTime, sectionsDetails: sections })
            .then((res) => {
                toast.success(res.data.message);
                setIsSectionDisabled(false)
                //reset form
                navigate('/test-management');
                // resetTestHandler();
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }

    //reset form
    const resetTestHandler = () => {
        setTestName('');
        setPaidTest(1);
        setStartTime('00:01');
        setEndTime('23:59');
        setTotalTime(60);
        setStartDate(new Date());
        setEndDate(new Date());
        setTotalMarks(0);
        setPassingMarks(0);
        // setNegativeMarks(0);
        setPriority(0);
        setRemarks('');
        setInstantResult(1);
        setSectionDetails([intialSectionDetails])
    }
    //moving up and down
    const moveUpHandler = (index) => {
        if (index > 0) {
            const newSections = [...sectionsDetails]
            var temp = newSections[index]
            newSections[index] = newSections[index - 1]
            newSections[index - 1] = temp;
            setSectionDetails(newSections);
        }

    }
    //move down handler
    const moveDownHandler = (index) => {
        if (index < sectionsDetails.length - 1) {
            const newSections = [...sectionsDetails]
            var temp = newSections[index]
            newSections[index] = newSections[index + 1]
            newSections[index + 1] = temp;
            setSectionDetails(newSections);
        }

    }
    const handleTestNameChange = (value) => {
        setTestName(value);
        axios.post(BACKEND_SERVER_URL + '/checkExistingTest', { testName: value })
            .then((resposne) => {

                setIsTestExisted(resposne.data.existed)
                if (resposne.data.existed) {
                    toast.error("This Test Name already exist")

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Test Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row d-flex justify-content-center ">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="mb-3" style={{ minHeight: '' }}>
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Test Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" required id="exampleFormControlInput1" placeholder="Enter Test Title" value={testName} onChange={(e) => handleTestNameChange(e.target.value)} />
                                                {testName?.length == 0 && <span className='text-danger'>This field cannot be blank</span>}
                                                {(isTestExisted && testName.length !== 0) && <span className='text-danger'>This test already exists for this admin</span>}
                                            </div>

                                            <div className="mb-3 " >
                                                <label className="form-label text-primary  mt-0">End Date<span className="required">*</span></label>
                                                <br />
                                                <DatePicker
                                                    className="form-control full-width"
                                                    selected={endDate}
                                                    onChange={setEndDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Passing Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="Enter Passing Marks" value={passingMarks} onChange={(e) => setPassingMarks(e.target.value)} />
                                            </div>






                                        </div>
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="mb-3" >
                                                <label className="form-label text-primary">Start Date<span className="required">*</span></label>
                                                <br />
                                                <DatePicker
                                                    className="form-control full-width full-width-container .react-datepicker-wrapper"
                                                    selected={startDate}
                                                    onChange={setStartDate}
                                                    dateFormat="dd/MM/yyyy"

                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">End Time<span className="required">*</span></label>
                                                <input type="time" className="form-control" id="exampleFormControlInput6" placeholder="Enter End Time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />

                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Priority</label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter your priority number" value={priority} onChange={(e) => setPriority(e.target.value)} />
                                            </div>

                                            {/* <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Negative Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter negative marks" value={negativeMarks} onChange={(e) => setNegativeMarks(e.target.value)} />
                                            </div> */}
                                        </div>
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary ">Start Time<span className="required">*</span></label>
                                                <input type="time" className="form-control" id="exampleFormControlInput5" placeholder="Enter Start Time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />

                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Total Time<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="Enter Total Time" value={totalTime} onChange={(e) => setTotalTime(e.target.value)} />
                                            </div>



                                            {/*  <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">Total Marks<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput6" placeholder="Enter Total Marks" value={totalMarks} onChange={(e) => setTotalMarks(e.target.value)} />
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea2" className="form-label text-primary">Remarks<span className="required"></span></label>
                                                <textarea className="form-control" id="exampleFormControlTextarea2" rows="3" value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder="Enter Remarks" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-check form-switch mb-3 ">
                                                <input className="form-check-input cursor-pointer " type="checkbox" role="switch" id="flexSwitchCheckDefault1"
                                                    style={{ height: '20px', width: "40px", backgroundColor: `${paidTest ? 'green' : 'red'}`, border: `${paidTest ? 'green' : 'red'}` }}
                                                    checked={paidTest}
                                                    onChange={() => setPaidTest(!paidTest)} />
                                                <label className="form-check-label text-primary" htmlFor="flexSwitchCheckDefault1">Paid Test</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-check form-switch mb-3 ">
                                                <label className="form-check-label text-primary" htmlFor="flexSwitchCheckDefault2">Instant Result</label>
                                                <input className="form-check-input cursor-pointer " type="checkbox" role="switch" id="flexSwitchCheckDefault2" style={{ height: '20px', width: "40px", backgroundColor: `${instantResult ? 'green' : 'red'}`, border: `${instantResult ? 'green' : 'red'}` }}
                                                    checked={instantResult}
                                                    onChange={() => setInstantResult(!instantResult)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 " >
                <div className="card  ">
                    <div className="card-header">
                        <h5 className="mb-0">Section Details {isSectionDisabled ? <span className="required" style={{ fontSize: '0.8em' }}> (Only empty test's section details are editable)</span> : null} </h5>
                    </div>
                    <div className="card-body" style={{ pointerEvents: isSectionDisabled ? 'none' : 'auto' }} >
                        {sectionsDetails?.map((section, index) => (
                            <div className={`row  ${isSectionDisabled ? 'bg-light' : ''}`} key={index}>
                                <div className="col-xl-1 col-sm-1 me-0">
                                    <div className='mt-2'>
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light "><span className="required "></span>_</label> : null}
                                        {index !== 0 ? <button className="btn btn-outline-primary btn-xs me-3" onClick={() => moveUpHandler(index)}><FaArrowUp /></button> : null}
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 ">
                                    <div className='mt-2'>
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light "><span className="required"></span>-</label> : null}
                                        {index !== sectionsDetails.length - 1 ? <button className="btn btn-outline-primary me-3 btn-xs" onClick={() => moveDownHandler(index)}><FaArrowDown /></button> : null}
                                    </div>
                                </div>

                                <div className="col-xl-3 col-sm-3">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary ">Section Name<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].sectionName} onChange={(e) => { sentionHandler(index, 'sectionName', e.target.value) }} type="text" className="form-control " id="exampleFormControlInput1" placeholder="Section name" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 me-3 p-0">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">T.Q<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].totalQuestion} type="number" onChange={(e) => { sentionHandler(index, 'totalQuestion', e.target.value) }} className="form-control p-1" id="sectionDetails_" placeholder="Enter Total Questions" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 me-3 p-0">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Marks/Q<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].marksPerQestion} type="number" onChange={(e) => { sentionHandler(index, 'marksPerQestion', e.target.value) }} className="form-control p-1" id="sectionDetails_" placeholder="Enter Marks/Q " />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 me-3 p-0" >
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">N.Marks<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].negativeMarks} type="number" onChange={(e) => { sentionHandler(index, 'negativeMarks', e.target.value) }} className="form-control p-1" id="sectionDetails_" placeholder="Enter Negative Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 me-3 p-0">
                                    <div className="mb-3">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-primary">P.Marks<span className="required">*</span></label> : null}
                                        <input value={sectionsDetails[index].passingMarks} type="number" onChange={(e) => { sentionHandler(index, 'passingMarks', e.target.value) }} className="form-control p-1" id="sectionDetails_" placeholder="Enter Total Marks" />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-sm-1 ">
                                    {sectionsDetails.length > 1 ? <div className="mb-3 mt-2">
                                        {index == 0 ? <label htmlFor="exampleFormControlInput1" className="form-label text-light">+ </label> : null}
                                        <button className=' btn btn-danger btn-xs' onClick={() => removeSectionHandler(index)}>Remove</button>
                                    </div> : null
                                    }
                                </div>
                            </div>
                        ))}
                        <div className={`row  ${isSectionDisabled ? 'bg-light' : ''}`} >
                            <div className="col-xl-2 col-sm-2">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                                    <button className=' btn btn-success btn-xs text-light' type="button" onClick={addSectionHandler}>+ Add</button>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label text-grey ">Total Sections: {sectionsDetails.length}</label>

                                </div>
                            </div>
                            <div className="col-xl-2 col-sm-2  p-0">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label text-grey ">Total Questions: {totalQuestions} </label>

                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3  ps-3">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label text-grey ">Total Marks: {totalMarks} </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className=" d-flex justify-content-end">
                            {!id ? <button className="btn btn-outline-info me-3 btn-sm" onClick={resetTestHandler}>Reset</button> :
                                <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/test-management')}>Go Back</button>}

                            <button className="btn btn-outline-primary btn-sm" type="button" onClick={submitTestHandler}>{id ? 'Update Test' : 'Create Test'}</button>
                        </div> */}
                    </div>
                    <div className=" d-flex justify-content-between mt-0 p-3 pt-0">
                        <div>
                            {id ? <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/test-management')}>Go Back</button> : null}

                        </div>
                        <div>
                            <button className="btn btn-outline-warning  me-3 btn-sm" onClick={resetTestHandler}>Reset</button>

                            <button className="btn btn-primary btn-sm" type="button" onClick={submitTestHandler}>{id ? 'Update Test' : 'Create Test'}</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default AddNewTest;