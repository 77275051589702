import React from "react";
import { Pie } from "react-chartjs-2";

export const TimePieChart = ({ correctTime, inCorrectTime, skipTime }) => {
    const data = {
        datasets: [
            {
                data: [correctTime, inCorrectTime, skipTime],
                borderWidth: 0,
                backgroundColor: [
                    `${"rgba(77,68,181,1)"}`,
                    `${"rgba(77,68,181,0.7)"}`,
                    `${"rgba(156, 158, 166,0.5)"}`,
                ],
                hoverBackgroundColor: [
                    `${"rgba(77,68,181,1)"}`,
                    `${"rgba(77,68,181,0.7)"}`,
                    `${"rgba(156, 158, 166,0.5)"}`,
                ],
            },
        ],
        labels: ["Correct Time", "Wrong Time", "Skip Time"]
        // labels: ["Time Spent on Correct Questions", "Time Spent on Wrong Questions", "Time Spent on Skipped Questions"],
    };

    const options = {
        plugins: {
            legend: { position: 'bottom' },
            responsive: true,
        },

        maintainAspectRatio: false,
    };

    return (
        <>
            <Pie data={data} height={150} width={150} options={options} />
        </>
    );
};

export const QuestionPieChart = ({ correctQuestions, wrongQuestions, skipQuestions }) => {
  
    const data = {
        datasets: [
            {
                data: [correctQuestions, wrongQuestions, skipQuestions],
                borderWidth: 0,
                backgroundColor: [
                    `${"rgba(77,68,181,1)"}`,
                    `${"rgba(77,68,181,0.7)"}`,
                    `${"rgba(156, 158, 166,0.5)"}`,
                ],
                hoverBackgroundColor: [
                    `${"rgba(77,68,181,1)"}`,
                    `${"rgba(77,68,181,0.7)"}`,
                    `${"rgba(156, 158, 166,0.5)"}`,
                ],
            },
        ],
        labels: ["Correct Questions", "InCorrect Questions", "Skip Questions"],
    };

    const options = {
        plugins: {
            legend: { position: 'bottom' },
            responsive: true,
        },

        maintainAspectRatio: false,
    };

    return (
        <>
            <Pie data={data} height={150} width={150} options={options} />
        </>
    );
};

