import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit, CiLock, CiUnlock } from "react-icons/ci";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import SubscriberModal from './SubscriberModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { IoSearchSharp } from "react-icons/io5";
import { Pagination } from '@mui/material';
import Select from 'react-select';
import { setDropDownStyle } from '../Batch-Management/BatchHelper';
import { showTableResponse } from '../common-helpers/commonServices';

const Subscribers = () => {
    const childRef = useRef();
    const { state } = useLocation();
    const navigate = useNavigate();
    // Access the values

    const [courseId, setCourseId] = useState(state?.courseId)
    const [batchId, setBatchId] = useState(state?.batchId)
    const [allActivebatches, setBatches] = useState();
    const [currentCourseBatches, setCurrentCourseBatches] = useState([]);
    const [subscribers, setSubscribers] = useState([]);
    const [courseNameArray, setCourseNameArray] = useState([]);
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [selectedBatch, setSelectedBatch] = useState();
    const [courseWiseSelectedSubcribers, setCourseWiseSelectedSubcribers] = useState([]);
    const [studentType, setStudentType] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(subscribers);
    const [unchecked, setUnChecked] = useState(true);
    const [loadStudents, setStudentLoading] = useState(false);
    const [studentEmail, setStudentEmail] = useState('')
    console.log(courseId, batchId)
    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredStudents = subscribers?.filter(
            item => item?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase())
        );


        setChecked(filteredStudents)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getSubscribers').then((res) => {

            if (res.data.subscribers) {
                let subscribers = res.data.subscribers;
                let uniqueCourseTitles = [...new Set(subscribers.map(subscriber => subscriber.courseTitle))];
                uniqueCourseTitles.unshift("All Courses");
                setCourseNameArray(uniqueCourseTitles);
                setSubscribers(subscribers);
                if (batchId && courseId && subscribers.length > 0) {
                    setCourseWiseSelectedSubcribers(subscribers.filter((subscriber) => subscriber.courseId == courseId))
                    let selectedSubscribers = subscribers?.filter((subscriber) => subscriber.batchId == batchId && subscriber.courseId == courseId)
                    setSelectedCourseName(selectedSubscribers.find((subscriber) => subscriber.courseId == courseId)?.courseTitle)

                    if (selectedSubscribers.length > 0) {
                        setChecked(selectedSubscribers)
                    }
                } else {
                    setChecked(subscribers);
                }

            }
        })

        axios.get(BACKEND_SERVER_URL + '/getBatches').then((res) => {
            let batches = res.data.batches;
            if (batches) {
                setBatches(batches.filter(batch => batch.status == 'ACTIVE'))
                let currentCourseBatches = batches.filter(batch => batch.courseId == courseId)
                currentCourseBatches.unshift({ batchName: "All Batch" })
                setCurrentCourseBatches(currentCourseBatches);
                setSelectedBatch(currentCourseBatches.find((batch) => batch.sn == batchId))
            }
        })
    }, [loadStudents])

    const handleChecked = (sn) => {
        let temp = checked.map((data) => {
            if (sn === data.sn) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 15;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    //handle delete student
    const handleDeleteStudent = (studentId) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this student",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteStudent/' + studentId).then((res) => {
                    if (res) {
                        swal("Poof! Your student has been deleted!", {
                            icon: "success",
                        });
                        setStudentLoading(!loadStudents);
                    }
                })
            }
        })
    }

    const getSn = (index) => {
        return subscribers.length - index - ((currentPage - 1) * pageSize);
    }

    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.id);
        if (ids.length <= 0) {
            toast.error('Please select atleast one student');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these students",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteStudents', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setStudentLoading(!loadStudents)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "activateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to activate these students",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/activateStudents', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setStudentLoading(!loadStudents)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "InActivateBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to inactivate these students",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/inactivateStudents', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setStudentLoading(!loadStudents)
                                setUnChecked(!unchecked)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
        }
    }

    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }
    const selectCourseHandler = (value) => {
        setSelectedCourseName(value)

        if (value == "All Courses") {
            setChecked(subscribers)
            setCourseWiseSelectedSubcribers(subscribers)
            setCourseId(0)
            setCurrentCourseBatches([{ batchName: "All Batch" }])
            setSelectedBatch({ batchName: "All Batch" })
            return;
        }
        let selectedSubscribers = subscribers.filter((subscriber) => subscriber.courseTitle == value)
        setCourseId(selectedSubscribers.find(subscriber => subscriber.courseId).courseId)
        setBatchId(0);
        setCourseWiseSelectedSubcribers(selectedSubscribers)
        setChecked(selectedSubscribers)
        let currentCourseBatches = allActivebatches.filter((batch) => batch.courseTitle == value)
        currentCourseBatches.unshift({ batchName: "All Batch" })
        setCurrentCourseBatches(currentCourseBatches)
        setSelectedBatch({ batchName: "All Batch" })
    }

    const selectBatchHandler = (value) => {
        setBatchId(value.sn)
        setSelectedBatch(value)
        if (value.batchName == "All Batch") {
            setChecked(courseWiseSelectedSubcribers)
            return;
        }
        let selectedSubscribers = courseWiseSelectedSubcribers.filter(subscriber => subscriber.batchId == value.sn);

        setChecked(selectedSubscribers)
    }

    console.log(selectedBatch)

    const selectStudentHandler = (value) => {
        setStudentType(value)
        if (value == 'All Student') {
            return;
        } else if (value == 'Allow Student') {
            setChecked(checked.filter((subscriber) => subscriber.allow))
        } else {
            setChecked(checked.filter((subscriber) => !subscriber.allow))
        }

    }
    const handleAddStudent = async () => {
        if (!courseId) {
            toast.error("Please select any Course")
            return;
        } else if (!batchId) {
            toast.error("Please select any Batch")
            return;
        } else if (!studentEmail) {
            toast.error("Please Enter any valid Email")
            return;
        }

        try {
            const res = await axios.post(BACKEND_SERVER_URL + '/addStudentForCurrentBatch', { courseId, batchId, courseStartDate: selectedBatch.startDate, studentEmail });

            if (res?.data.statusCode == 200) {
                toast.success(res.data.message);
                setStudentLoading(loadStudents);

            }
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("An error occurred during the request:", error);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title  p-2 mb-2">{/*  flex-wrap  */}
                                <div className='d-flex justify-content-between w-10'>
                                    <button type="button" className="btn btn-info btn-xs ms-2" onClick={() => navigate('/batch-management')}
                                    >
                                        Go Back
                                    </button>
                                </div>
                                <div className='d-flex justify-content-between w-50'>
                                    <input className='form-control w-60' type="search" name="" id="" onChange={(e) => setStudentEmail(e.target.value)} />
                                    <button type="button" className="btn btn-dark btn-xs w-40 ms-2" onClick={handleAddStudent}
                                    >
                                        + Add Student
                                    </button>
                                </div>
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>

                            </div>
                            <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: '0' }}>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="basic-dropdown">
                                                <Select
                                                    value={{ value: selectedCourseName || "All Courses", label: selectedCourseName || "All Courses" }}
                                                    onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                    options={courseNameArray?.map((courseTitle) => ({ value: courseTitle, label: courseTitle }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#17a2b8',
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="basic-dropdown ms-3">
                                                <Select
                                                    value={{ value: selectedBatch, label: selectedBatch?.batchName || "All Batch" }}
                                                    onChange={(selectedOption) => selectBatchHandler(selectedOption.value)}
                                                    options={currentCourseBatches?.map((batch) => ({ value: batch, label: batch.batchName }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#17a2b8',
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                       {/*  <div className="col-sm-4">
                                            <div className="basic-dropdown ms-3">
                                                <Select
                                                    value={{ value: studentType || "All Student", label: studentType || "All Student" }}
                                                    onChange={(selectedOption) => selectStudentHandler(selectedOption.value)}
                                                    options={['All Student', 'Allow Student', 'Not Allowed Student']?.map((batchType) => ({ value: batchType, label: batchType }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#17a2b8',

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s" >
                            <div className="dataTables_wrapper no-footer">
                                <div className="d-sm-flex text-center justify-content-between align-items-center">                                    
                                    <div className='col-3'>
                                        <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                                        <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("activateBulk")}>Active</Button>
                                        <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("InActivateBulk")}>Inactive</Button>
                                    </div>
                                    <div className='col-3 dataTables_info text-dark'>
                                        Records: {records.length}
                                    </div>
                                    <div className=" col-5 d-flex justify-content-end ">
                                       {/*  <div className="col-5 d-flex justify-content-end">
                                            <label htmlFor="jumpPage" className='me-2 mt-2'>  Jump to Page</label>
                                            <input className='form-control p-1' type="number" name="" id="jumpPage" style={{ width: "40%" }} min="1" value={currentPage} onChange={(e) => setCurrentPage(parseInt(e.target.value))} />
                                        </div> */}
                                        <div className=" col-12 d-flex justify-content-end"
                                            id="example-student_wrapper">
                                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0  " id="example-student">
                                        <thead style={{ backgroundColor: "#cccccc" }} className='header-color'>
                                            <tr className='text-center  text-dark  border border-left'>
                                                <th className='p-2 text-dark '>SN</th>
                                                <th className='p-2 text-dark '>
                                                    <input type="checkbox" className="form-check-input" id="checkAll" checked={!unchecked}
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th className='p-2 text-dark '>ALLOW</th>
                                                <th className=' text-start p-2 text-dark  '>STUDENT NAME</th>
                                                <th className=' p-2  text-dark '>BATCH NAME</th>
                                                <th className=' p-2  text-dark '>COURSE NAME</th>
                                                <th className='  p-2   text-dark '>PAYMENT DATE</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom text-center' : 'row-height text-center'}`}>
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}><h6 className="text-primary mb-0"> {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc" }}>
                                                        {item.allow && <h6 className="text-success mb-0 ">YES </h6>}
                                                        {!item.allow && <h6 className="text-danger mb-0 ">NO </h6>}
                                                    </td>

                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="trans-list sweetalert">
                                                            <h4 className='mb-0 '>{item.firstName}{' '}{item.lastName}
                                                                {/*  <Link to={`/edit-student/${item.sn}`}>
                                                                    <CiEdit cursor='pointer' title='Edit Test' size={18} className='ms-3' />
                                                                </Link>

                                                                <MdDeleteOutline title='Delete Test' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteStudent(item.sn) }}
                                                                /> */}
                                                            </h4>
                                                        </div>
                                                    </td>



                                                    <td className='p-1 text-start' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0">{item.batchName}  <Badge bg="" className='badge-primary light badge-xs ms-2 cursor-pointer' onClick={() => childRef.current.openModal(item, allActivebatches)}>Change</Badge></h6>
                                                    </td>
                                                    <td className='p-1 text-start' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.courseTitle}</h6></td>

                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "85px" }} >
                                                        <h6 className="date mb-0">{format(item.paymentDate, "dd/MM/yyyy")}</h6>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(subscribers, checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <SubscriberModal ref={childRef} loadStudents={loadStudents} setStudentLoading={setStudentLoading} />
        </>
    )
}

export default Subscribers;
