import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';


const AddStudent = () => {
    const allStatesOfIndia = ["Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"]
    const { id } = useParams();
    const navigate = useNavigate();
    const [studentImage, setStudentImage] = useState(null)
    const [oldStudentImage, setOldStudentImage] = useState(null)
    const [gender, setGender] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [contactNumber, setContactNumber] = useState(null)
    const [password, setPassword] = useState(null)
    const [state, setState] = useState('')
    const [cityName, setCityName] = useState('')
    const [postalCode, setPostalCode] = useState(null)
    //parent details
    const [parentName, setParentName] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [parentContactNumber, setParentContactNumber] = useState(null)
    const [parentOccupation, setParentOccupation] = useState('')

    const [isStudentExisted, setIsStudentExisted] = useState(false);

    const fileHandler = (e) => {
        setStudentImage(e.target.files[0]);
    }
    const RemoveFile = () => {
        setStudentImage(null)
    }
    //validation for student contact number
    useEffect(() => {
        if ((contactNumber?.toString().length > 10) && (contactNumber?.toString().charAt(0) == 9 && contactNumber?.toString().charAt(1) == 1)) {
            let modifiedContact = contactNumber?.toString().replace('91', '')
            modifiedContact.length == 10 ? setContactNumber(modifiedContact) : toast.error("Please Enter valid 10 digit phone number")
        }
        else if ((contactNumber?.toString().length > 10) && (contactNumber?.toString().charAt(0) == 0)) {
            let modifiedContact = contactNumber?.toString().replace('0', '')
            modifiedContact.length == 10 ? setContactNumber(modifiedContact) : toast.error("Please Enter valid 10 digit phone number")
        }

    }, [contactNumber])
    //validation for parent's contact number
    useEffect(() => {
        if ((parentContactNumber?.toString().length > 10) && (parentContactNumber?.toString().charAt(0) == 9 && parentContactNumber?.toString().charAt(1) == 1)) {
            let modifiedContact = parentContactNumber?.toString().replace('91', '')
            modifiedContact.length == 10 ? setParentContactNumber(modifiedContact) : toast.error("Please Enter valid 10 digit phone number")
        }
        else if ((parentContactNumber?.toString().length > 10) && (parentContactNumber?.toString().charAt(0) == 0)) {
            let modifiedContact = parentContactNumber?.toString().replace('0', '')
            modifiedContact.length == 10 ? setParentContactNumber(modifiedContact) : toast.error("Please Enter valid 10 digit phone number")
        }
    }, [parentContactNumber])

    const handleSaveStudent = async () => {
        if (contactNumber?.toString().length != 10 || parentContactNumber?.toString().length != 10) {
            toast.error("Please Enter valid 10 digit phone number")
            return;
        }
        if (!studentImage || !name || !gender || !email || !address || !contactNumber || !password || !state || !cityName || !postalCode || !parentName || !parentEmail || !parentContactNumber) {
            toast.error('Please enter all required fields');
            return;
        }
        if (!validateEmail(email)) {
            toast.error("Please Enter valid student email")
            return;
        } else if (!validateEmail(parentEmail)) {
            toast.error("Please Enter valid parent email");
            return;
        } else if (isStudentExisted) {
            toast.error("This student already existed for this admin");
            return;
        }

        let formData = new FormData();
        formData.append('uploadedFile', studentImage, studentImage.name);

        // Create an object with student details
        const studentDetails = {
            name: name,
            gender: gender,
            email: email,
            postalCode: postalCode,
            contactNumber: contactNumber,
            address: address,
            cityName: cityName,
            password: password,
            state: state,
            parentName: parentName,
            parentEmail: parentEmail,
            parentContactNumber: parentContactNumber,
            parentOccupation: parentOccupation,
            oldStudentImage: oldStudentImage
        };

        // Convert the object to a JSON string and append it to the formData
        formData.append('details', JSON.stringify(studentDetails));

        axios.post(BACKEND_SERVER_URL + `/${!id ? 'create-student' : `update-student/${id}`}`, formData)
            .then((response) => {
                toast.success(response.data.message)
                navigate('/student-management')
            })
            .catch((error) => {
                console.error(error);
            });

    };

    //for edit student
    useEffect(() => {
        if (id) {
            axios.get(BACKEND_SERVER_URL + '/getStudent/' + id).then((res) => {
                if (res.data.student) {
                    var student = res.data.student[0]
                    setName(student.name);
                    setEmail(student.email);
                    setAddress(student.address);
                    setCityName(student.cityName);
                    setContactNumber(student.contactNumber);
                    setGender(student.gender);
                    setParentContactNumber(student.parentContactNumber);
                    setParentEmail(student.parentEmail)
                    setParentOccupation(student.parentOccupation)
                    axios.get(BACKEND_SERVER_URL + '/getStudentImage/' + student.photo, { responseType: 'arraybuffer' }).then((response) => {
                        const blob = new Blob([response.data], { type: 'image/png' });
                        // Set the blob file to the state
                        setStudentImage(blob);
                        setOldStudentImage(student.photo);
                    })

                    setState(student.state);
                    setPostalCode(student.postalCode)
                    setPassword(student.password)
                    setParentName(student.parentName)

                }
            })
        }

    }, [id])

    const resetHandler = () => {
        setName('');
        setEmail('');
        setAddress('');
        setCityName('');
        setContactNumber('');
        setGender('');
        setParentContactNumber('');
        setParentEmail('')
        setParentOccupation('')
        setStudentImage(null);
        setState('');
        setPostalCode('')
        setPassword('')
        setParentName('')
    }

    //validate email
    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
    //check existing student
    useEffect(() => {
        if ((email && validateEmail(email)) && !id) {
            axios.post(BACKEND_SERVER_URL + '/checkExistingStudent', { email })
                .then((resposne) => {
                    console.log(resposne.data.existed)
                    setIsStudentExisted(resposne.data.existed)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [email])
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Student Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4">
                                    <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                    <div className="avatar-upload">
                                        <div className="avatar-preview">
                                            <div id="imagePreview"
                                                style={{ backgroundImage: studentImage ? "url(" + URL.createObjectURL(studentImage) + ")" : "url(" + IMAGES.noimage + ")" }}
                                            >
                                            </div>
                                        </div>
                                        <div className="change-btn mt-2 mb-lg-0 mb-3">
                                            <input type='file' className="form-control d-none" onChange={fileHandler} id="imageUpload" accept=".png, .jpg, .jpeg" />
                                            <label htmlFor="imageUpload" className="dlab-upload mb-0 btn btn-primary btn-sm">Choose File</label>
                                            <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-sm" onClick={RemoveFile}>Remove</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="James" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput2" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleFormControlInput2" placeholder="Enter Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                            </div>

                                            <div className="mb-3 ">
                                                <label htmlFor="exampleFormControlInput3" className="form-label text-primary">Email<span className="required">*</span><span>*</span> </label>
                                                <input type="email" className="form-control" id="exampleFormControlInput3" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {isStudentExisted && <p className='text-danger'>This student already exists for this admin</p>}
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput4" className="form-label text-primary" >Password<span className="required">*</span><span>*</span> </label>
                                                <input type="password" className="form-control" id="exampleFormControlInput4" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput5" className="form-label text-primary">Gender<span className="required">*</span></label>

                                                <div className='d-flex '>
                                                    <label htmlFor="male" className="form-label text-primary" style={{ cursor: "pointer" }}></label>
                                                    <Form.Check className='me-sm-3' id='male'
                                                        reverse
                                                        label="Male"
                                                        name="gender"
                                                        type='radio'
                                                        value="male"
                                                        checked={gender === 'male'}
                                                        onChange={(e) => setGender(e.target.value)}
                                                    />
                                                    <label htmlFor="female" className="form-label text-primary" ></label>
                                                    <Form.Check id='female'
                                                        reverse
                                                        label="Female"
                                                        name="gender"
                                                        type={'radio'}
                                                        value="female"
                                                        checked={gender === 'female'}
                                                        onChange={(e) => setGender(e.target.value)}

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">



                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label text-primary">Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" value={address} onChange={(e) => setAddress(e.target.value)} />
                                            </div>
                                            <div className="mb-3">

                                                <label htmlFor="exampleFormControlInput6" className="form-label text-primary">City<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput6" placeholder="Enter city name" value={cityName} onChange={(e) => setCityName(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput7" className="form-label text-primary">State<span className="required">*</span></label>
                                                <Select
                                                    value={state ? { value: state.toUpperCase(), label: state.toUpperCase() } : null}
                                                    onChange={(selectedOption) => setState(selectedOption?.value)}
                                                    options={allStatesOfIndia.map((state) => ({ value: state, label: state }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            minWidth: '100%',
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#17a2b8',
                                                        }),
                                                    }}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput8" className="form-label text-primary">Postal Code<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleFormControlInput8" placeholder="Enter city name" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Parents Details</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput9" className="form-label text-primary" >Parent Name<span className="required">*</span></label>
                                    <input type="text" className="form-control" id="exampleFormControlInput9" placeholder="Enter Parent Name" value={parentName} onChange={(e) => setParentName(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput10" className="form-label text-primary" >Parent Email<span className="required">*</span></label>
                                    <input type="email" className="form-control" id="exampleFormControlInput10" placeholder="hello@example.com" value={parentEmail} onChange={(e) => setParentEmail(e.target.value)} />
                                </div>

                            </div>
                            <div className="col-xl-6 col-sm-6">

                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput11" className="form-label text-primary" >Parent Phone Number<span className="required">*</span></label>
                                    <input type="number" className="form-control" id="exampleFormControlInput11" placeholder="Enter Parent Contact Number" value={parentContactNumber} onChange={(e) => setParentContactNumber(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput12" className="form-label text-primary" >Parent's Occupation<span className="required">*</span></label>
                                    <input type="text" className="form-control" id="exampleFormControlInput12" placeholder="Enter Parent's Occupation" value={parentOccupation} onChange={(e) => setParentOccupation(e.target.value)} />
                                </div>

                            </div>
                        </div>

                        <div className=" d-flex justify-content-between mt-0">
                            <div>
                                <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/student-management')} >Go Back</button>

                            </div>
                            <div>
                                <button className="btn btn-outline-warning me-3 btn-sm" onClick={resetHandler}>Reset</button>

                                <button className="btn btn-primary btn-sm" type="button" onClick={handleSaveStudent}>{!id ? 'Save Student' : 'Update Student'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddStudent;