import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const FacultyModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        facultyName: '',
    }

    const [facultyData, setFacultyData] = useState(initialData);
    console.log(facultyData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setFacultyData({ sn: item.sn, facultyName: item.facultyName, sort: item.sort })
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        if (facultyData.facultyName.trim().length === 0) {
            toast.error("Please Enter Faculty")
        }
        axios.post(BACKEND_SERVER_URL + `${!facultyData.sn ? '/saveFaculty' : `/updateFaculty`}`, facultyData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setFacultyData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setFacultyData(initialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {facultyData.sn ? "Update Faculty" : "Add Faculty "}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Faculty Name<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={facultyData.facultyName} onChange={(e) => setFacultyData({ ...facultyData, facultyName: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={facultyData.sort} onChange={(e) => setFacultyData({ ...facultyData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{facultyData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default FacultyModal;