import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { TiTickOutline } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";


const EditMyProfile = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    let intialData = {
        sn: 0,
        firstName: '',
        lastName: '',
        contact: '',
        email: '',
        address: '',
        date: 0,
        month: 0,
        year: 0,
        communicationAddress: '',
        city: '',
        state: '',
        country: '',
        emailVerify: false,
        mobileVerify: false,
        optionalSubject: '',
        profileImage: ''
    }
    const [adminDetails, setAminDetails] = useState(intialData)
    console.log(adminDetails)
    //for edit admin
    const tokenDetailsString = localStorage.getItem('userDetails');
    const userDetails = JSON.parse(tokenDetailsString);
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + `/getAdminData/${userDetails.EmailId}`).then((res) => {
            console.log(res.data.adminData);
            setAminDetails(res.data.adminData);
        })
    }, []);


    const handleUpdateAdmin = async () => {

        axios.post(BACKEND_SERVER_URL + `/updateAdmin`, adminDetails)
            .then(async (response) => {
                console.log(response)
                let tokenDetails = await JSON.parse(localStorage.getItem('userDetails')) || {};
                let updatedData = response.data.updatedData;
                console.log(updatedData)
                localStorage.setItem('userDetails', JSON.stringify({ ...updatedData, ...tokenDetails }));
                toast.success(response.data.message);
                navigate('/my-account');
            })
            .catch((error) => {
                console.error(error);
            });

    };


    const inputChangeHandler = (target) => {
        setAminDetails({
            ...adminDetails,
            [target.name]: target.value
        })
    }

    useEffect(() => {
        if (selectedFile) {
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', selectedFile, selectedFile.name);
            axios.post(BACKEND_SERVER_URL + '/uploadFile', formData)
                .then((response) => {
                    if (response.data.statusCode == 201) {
                        console.log(response.data.imagePath)
                        setAminDetails({ ...adminDetails, profileImage: response.data.imagePath })
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
        }
    }, [selectedFile])
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">Admin Details</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 d-flex  justify-content-between ">
                                    <div className="col-xl-3 col-lg-3">
                                        <label className="form-label text-primary">Photo<span className="required">*</span></label>
                                        <div className="avatar-upload">
                                            <div className="avatar-preview">
                                                <div id="imagePreview" style={{
                                                    backgroundImage: adminDetails.profileImage ? `url(${adminDetails.profileImage})` : `url(${IMAGES.noimage})`
                                                }}>
                                                </div>

                                            </div>
                                            <div className="change-btn mt-2 mb-lg-0 mb-3">
                                                <input type='file' className="form-control d-none" name='' onChange={(e) => setSelectedFile(e.target.files[0])} id="imageUpload1" accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload1" className="dlab-upload mb-0 btn btn-primary btn-xs">Choose File</label>
                                                <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-xs" onClick={() => setAminDetails({ ...adminDetails, profileImage: '' })}>Remove</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row mt-3">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="firstName" className="form-label text-primary">First Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="firstName" placeholder="Enter First Name" value={adminDetails.firstName} name='firstName' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="lastName" className="form-label text-primary">Last Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="lastName" placeholder="Enter Last Name" value={adminDetails.lastName} name='lastName' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="contact" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                                <div className="d-flex">
                                                    <input type="number" className="form-control" id="contact" placeholder="Enter Contact Number" value={adminDetails.contact} name='contact' onChange={(e) => inputChangeHandler(e.target)} />{adminDetails.mobileVerify ? <TiTickOutline size={30} color={'green'} className='mt-2' /> : <RxCross2 size={30} color={'red'} className='mt-2' />}
                                                </div>

                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label text-primary">Email<span className="required">*</span></label>
                                                <div className="d-flex">
                                                    <input disabled type="email" className="form-control bg-light" id="email" placeholder="Enter Email" value={adminDetails.email} name='email' onChange={(e) => inputChangeHandler(e.target)} /> {adminDetails.emailVerify ? <TiTickOutline size={30} color={'green'} className='mt-2' /> : <RxCross2 size={30} color={'red'} className='mt-2' />}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="city" className="form-label text-primary">City<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="city" placeholder="Enater City Name" value={adminDetails.city} name='city' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="state" className="form-label text-primary">State<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="state" placeholder="Enter Your State" value={adminDetails.state} name='state' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="country" className="form-label text-primary">Country<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="country" placeholder="Enter Country Name" value={adminDetails.country} name='country' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="DOB" className="form-label text-primary">Date of Birth<span className="required">*</span></label>
                                                <div className="d-flex">
                                                    <input type="text" className="form-control" id="DOB" placeholder="Date" value={adminDetails.date} name='date' onChange={(e) => inputChangeHandler(e.target)} />
                                                    <input type="text" className="form-control" id="DOB" placeholder="Month" value={adminDetails.month} name='month' onChange={(e) => inputChangeHandler(e.target)} />
                                                    <input type="text" className="form-control" id="DOB" placeholder="Year" value={adminDetails.year} name='year' onChange={(e) => inputChangeHandler(e.target)} />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="optionalSubject" className="form-label text-primary">Course<span className="required">*</span></label>
                                                <input type="text" className="form-control" id="optionalSubject" placeholder="Enter Optional Subject" value={adminDetails.optionalSubject} name='optionalSubject' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="address" className="form-label text-primary">Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="address" rows="6" value={adminDetails.address} name='address' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="communicationAddress" className="form-label text-primary">Communication Address<span className="required">*</span></label>
                                                <textarea className="form-control" id="communicationAddress" rows="6" value={adminDetails.communicationAddress} name='communicationAddress' onChange={(e) => inputChangeHandler(e.target)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex justify-content-between mt-0">
                                    <div>
                                        <button className="btn btn-outline-info me-3 btn-sm" onClick={() => navigate('/my-account')} >Go Back</button>
                                    </div>
                                    <div>
                                        <button className="btn btn-outline-warning me-3 btn-sm" onClick={() => setAminDetails(intialData)}>Reset</button>

                                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleUpdateAdmin}>{!adminDetails.sn ? 'Save Admin' : 'Update Admin'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditMyProfile;