import React, { useEffect, useState } from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { getNewsSubCategories } from '../CommonHelper';

const CategoryInput = ({ inputValue, handleChangeCallBack, labelName, inputName }) => {
    const [buttonNames, setButtonNames] = useState([])

    useEffect(() => {
        getNewsSubCategories().then((subCategories) => {          
            setButtonNames(subCategories)
        })
    }, [])

    console.log(buttonNames)

    const handleSelectCategory = (category) => {
        handleChangeCallBack(inputName, inputValue.concat(category + ", "))
    }
    return (
        <>
            <div className="col-xl-2 col-sm-2 pt-2">
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary ">{labelName}<span className="required">*</span></label>
                </div>
            </div>
            <div className="col-xl-10 col-sm-10">
                <div className="mb-3 " >
                    <input type='text' id={labelName} className='form-control ' name={inputName} value={inputValue} onChange={(e) => handleChangeCallBack(inputName, e.target.value)} />
                    <Stack direction="horizontal" gap={2} className='d-flex flex-wrap mt-2'>
                        {buttonNames?.map(button => {
                            return <Badge bg="secondary" key={button.name} value={button.name} style={{ cursor: 'pointer' }} onClick={() => handleSelectCategory(button.name)} >{button.name}</Badge>
                        })}
                    </Stack>
                </div>

            </div>
        </>
    );
}

export default CategoryInput;