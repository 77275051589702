import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from 'react-bootstrap';

import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdKeyboardArrowUp } from "react-icons/md";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { BiEdit } from "react-icons/bi";
import Select from 'react-select';
import CurrentAffairsModal from './CurrentAffairModal';
import { setDropDownStyle } from '../CommonHelper';
import { Pagination } from '@mui/material';
import SearchBox from '../Common-Components/SearchBox';
import { showTableResponse } from '../common-helpers/commonServices';



const CurrentAffairs = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [currentAffairs, setCurrentAffairs] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState('All Categories');
    const [courseNameArray, setCourseNameArray] = useState([]);
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [courseWiseSelectedBatches, setCourseWiseSelectedBatches] = useState([]);
    const [selectedBatchType, setSelectedBatchType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(currentAffairs);
    const [unchecked, setUnChecked] = useState(true);
    const [loadNews, setNewsLoading] = useState(false);
    const [totalPublishedNews, setTotalPublishedNews] = useState([]);
    const [totalUnPublishedNews, setTotalUnPublishedNews] = useState([]);
    const [archivedNews, setArchivedNews] = useState([]);

   
    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredBatches = currentAffairs?.filter(
            item => item?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredBatches)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getCurrentAffairs').then((res) => {
            let currentAffairs = res.data.currentAffairs;  //recieving batch here           
            if (currentAffairs) {
                console.log(currentAffairs)
                setTotalPublishedNews(currentAffairs.filter(news => news.isPublished && news.hide == 0))
                setTotalUnPublishedNews(currentAffairs.filter(news => !news.isPublished && news.hide == 0))
                setArchivedNews(currentAffairs.filter(news => news.hide == 1))
                let news = currentAffairs.filter(news => news.hide == 0)
                setCurrentAffairs(news);
                setChecked(news);

            }
        })
    }, [loadNews])

    const handleChecked = (sn) => {
        let temp = checked.map((data) => {
            if (sn === data.sn) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value) => {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const pageSize = 50;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    //handle delete course
    const handleDeleteBatches = (courseId) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this batch",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(BACKEND_SERVER_URL + '/deleteBatch/' + courseId).then((res) => {
                    if (res) {
                        swal("Poof! Your batch has been deleted!", {
                            icon: "success",
                        });
                        setNewsLoading(!loadNews);
                    }
                })
            }
        })
    }

    //common bulk handler
    const commonHandler = (action) => {
        let ids = records.filter(record => record.inputchecked).map(record => record.sn);
        if (ids.length <= 0) {
            toast.error('Please select atleast one news');
            return;
        }
        const postData = { ids: ids };
        switch (action) {
            case "deleteBulk":
                swal({
                    title: "Are you sure?",
                    text:
                        "Once deleted, you will not be able to recover these news",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/deleteNews', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setNewsLoading(!loadNews)
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "Publish":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to publish these news",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/publishNews', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setNewsLoading(!loadNews)

                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
            case "UnPublish":
                swal({
                    title: "Are you sure?",
                    text:
                        "Do you really want to UnPublish these news",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((yes) => {
                    if (yes) {
                        axios.post(BACKEND_SERVER_URL + '/unPublishNews', postData).then((response) => {
                            if (response) {
                                toast.success(response.data.message);
                                setNewsLoading(!loadNews)

                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });

                    }
                })
                break;
        }
    }

 

    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }
    const selectCategoryHandler = (value) => {
        console.log(value)
        setSelectedCategoryName(value)
        if (value == "All Categories") {
            setChecked(currentAffairs)
            return;
        }
        if (value == "Editorial") {
            setChecked(currentAffairs.filter(news => news.category && news.category.toLowerCase() == 'editorial'))
            return;
        }
        if (value == "News") {
            setChecked(currentAffairs.filter(news => news.category && news.category.toLowerCase() == 'news'))
            return;
        }
        if (value == "Yojana") {
            setChecked(currentAffairs.filter(news => news.category && news.category.toLowerCase() == 'yojana'))
            return;
        }
        if (value == "Unlisted") {
            setChecked(currentAffairs.filter(news => news.category && news.category.toLowerCase() == 'unlisted'))
            return;
        }
        if (value == "Big Picture") {
            setChecked(currentAffairs.filter(news => news.category && news.category.toLowerCase() == 'big picture'))
            return;
        }

    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-8 d-flex justify-content-start'>
                                    <button type="button" className="btn btn-dark btn-sm me-2"
                                        onClick={() => childRef.current.openModal()}
                                    >
                                        + Add News
                                    </button>
                                    <div className=" col-5 basic-dropdown">
                                        <Select className=''
                                            value={{ value: selectedCategoryName, label: selectedCategoryName }}
                                            onChange={(selectedOption) => selectCategoryHandler(selectedOption.value)}
                                            options={["All Categories", "Editorial", "News", "Big Picture", "Yojana", "Unlisted"].map((category) => ({ value: category, label: category }))}
                                            styles={setDropDownStyle()}
                                        />
                                    </div>
                                </div>

                                <div className="input-group search-area mb-md-0 mb-3">
                                    <SearchBox setChecked={setChecked} dataArray={currentAffairs} searchingProperty={'title'} />                                   
                                </div>
                            </div>

                            <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: "0" }}>
                                <div className="container-fluid p-1 pb-0">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-sm-flex ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => setChecked(currentAffairs)}
                                                    type="button"
                                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    All News: {currentAffairs.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6  ">
                                                <button
                                                    onClick={() => setChecked(totalPublishedNews)}
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                                >
                                                    Published: {totalPublishedNews?.length}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                                <button
                                                    onClick={() => setChecked(totalUnPublishedNews)}
                                                    type="button"
                                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    UnPublished: {totalUnPublishedNews?.length}
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-6 ">
                                                <button
                                                    onClick={() => setChecked(archivedNews)}
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                                >
                                                    Archived: {archivedNews?.length}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div>
                                    <Button variant="danger ms-3" size='xs' onClick={(e) => commonHandler("deleteBulk")}>Delete</Button>
                                    <Button variant="success ms-3 " size='xs' onClick={(e) => commonHandler("Publish")}>Publish</Button>
                                    <Button variant="warning ms-3" size='xs' onClick={(e) => commonHandler("UnPublish")}>UnPublish</Button>
                                </div>

                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className=" col-6 d-flex justify-content-end align-items-center   mb-0 pt-2"
                                    id="example-student_wrapper">
                                    {/* <div className="col-4 d-flex justify-content-end">
                                        <label htmlFor="jumpPage" className='me-2 mt-2'>  Jump to Page</label>
                                        <input className='form-control p-1' type="number" name="" id="jumpPage" style={{ width: "40%" }} min="1" max={number.length} value={currentPage} onChange={(e) => setCurrentPage(parseInt(e.target.value))} />
                                    </div> */}
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">


                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 " id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' text-dark  p-2'>SN</th>
                                                <th className=' text-dark  p-2'>
                                                    <input type="checkbox" className="form-check-input" id="checkAll"
                                                        checked={!unchecked}
                                                        onClick={() => handleCheckedAll(unchecked)}
                                                    />
                                                </th>
                                                <th className='p-2  text-dark text-start '>TITLE</th>
                                                <th className=' text-start text-dark  p-2' >CATEGORY</th>
                                                <th className='p-2 text-dark '>PUBLISH DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight  mb-0"> {item.sn}</h6></td>
                                                    <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                                        <div className="checkbox me-0 align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`stud-${item.sn}`}
                                                                    checked={item.inputchecked}
                                                                    onChange={() => handleChecked(item.sn)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.title}{' '}
                                                                <CiEdit cursor='pointer' title='Edit Batch' size={18} className='ms-3' onClick={() => childRef.current.openModal(item.sn)} />
                                                                <MdDeleteOutline title='Delete Course' cursor='pointer' size={18} className='ms-2' onClick={() => { handleDeleteBatches(item.id) }}
                                                                />
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td className='p-1 active-col-width' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0 ">{item.category}</h6>
                                                    </td>
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.publishDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6>
                                                    </td>

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {
                                                                showTableResponse(currentAffairs,checked)
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CurrentAffairsModal ref={childRef} loadNews={loadNews} setNewsLoading={setNewsLoading} />
        </>
    )
}

export default CurrentAffairs;