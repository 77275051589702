import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdKeyboardArrowUp } from "react-icons/md";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { BiEdit } from "react-icons/bi";


const Enrollment = () => {
    const childRef = useRef();
    const [batches, setBatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(batches);
    const [unchecked, setUnChecked] = useState(true);
    const [loadBatches, setBatchLoading] = useState(false);


    // search functionality    
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredBatches = batches?.filter(
            item => item?.batchTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setChecked(filteredBatches)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    //set Table data

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('userDetails');
        let studentId = JSON.parse(tokenDetailsString)?.studentId       
            axios.post(BACKEND_SERVER_URL + '/student/getStudentBatches/' + studentId,).then((res) => {
                let batches = res.data.batches;
                console.log(batches)
                if (batches) {             
                    batches = batches.filter(batch => batch.hide == 0)
                    setBatches(batches);
                    setChecked(batches);
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
    }, [])
    const pageSize = 15;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked.slice(firstIndex, lastIndex);

    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const getSn = (index) => {
        return batches.length - index - ((currentPage - 1) * pageSize);
    }

   
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}

                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input type="text" className="form-control" onChange={handleSearch} value={searchTerm} placeholder="Search here..." />
                                    <span className="input-group-text"><Link to={"#"}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                                        </svg>
                                    </Link></span>
                                </div>

                            </div>
                           
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">

                                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div className='dataTables_info text-dark'>
                                            Records: {records.length}
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                            id="example-student_wrapper">
                                            <Link
                                                className="paginate_button previous disabled"
                                                to="#"
                                                onClick={prePage}
                                            >
                                                <i className="fa-solid fa-angle-left" />
                                            </Link>
                                            <span>
                                                {number.map((n, i) => (
                                                    <Link className={`paginate_button ${currentPage === n ? 'current' : ''}  rounded-circle  `} key={i}
                                                        onClick={() => changeCPage(n)}
                                                    >
                                                        <span style={{ fontSize: "14px", display: "flex", justifyContent: "center", alignItems: "center" }} >{n}</span>

                                                    </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                                to="#"
                                                onClick={nextPage}
                                            >
                                                <i className="fa-solid fa-angle-right" />
                                            </Link>
                                        </div>
                                    </div>


                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 " id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' text-dark  p-2'>SN</th>
                                                <th className=' text-start text-dark  p-2' >BATCH NAME</th>
                                                <th className='text-start  text-dark p-2'>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ?records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="text-primary  mb-0"> {getSn(ind)}</h6></td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.batchTitle}{' '}  </h6>
                                                        </div>
                                                    </td>
                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}><h6 className="mb-0">{item.batchDetails}</h6></td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            <Spinner animation="grow" variant="info" />
                                                        </div>
                                                    </td>
                                                </tr>
}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enrollment;