import React, { Fragment, useState, useRef, useEffect } from "react";
import parse from 'html-react-parser';
import { Link, useParams } from "react-router-dom";
import { Dropdown, Tab, Nav, Button, ButtonGroup, Toast, SplitButton } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import { toast } from 'react-toastify';
import { createQuestion, deleteQuestion, getTestQuestions, updateQuestion } from "./questionBankService";
import Select from "react-select";
import './QuestionBank.css'
import swal from "sweetalert";
import TestModal from "../TestManagement/TestModal";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
const QuestionBank = () => {
    const childRef = useRef();
    const { id } = useParams();
    const [testSessionId, setSessionTestId] = useState(id ?? 0)
    const [testId, setTestId] = useState(id ?? 0)

    const [questionLevels, setQLevels] = useState();
    const [questionTypes, setQTypes] = useState();
    const [comprehensions, setComprehensions] = useState();
    const [tests, setTests] = useState();
    const [testNames, setTestNames] = useState([]);
    // const [sectionNameArray, setSectionNameArray] = useState();
    const [selectedTestName, setSelectedTestName] = useState('Select Test Name');
    const [selectedTest, setSelectedTest] = useState();
    const [testSections, setTestSections] = useState();

    const [selectedSectionName, setSelectedSectionName] = useState(null);
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState();
    const [editSelectedQuestion, setEditSelectedQuestion] = useState(false);
    const [questionUpdatedOrCreated, setQuestionUpdatedOrCreated] = useState(false);
    console.log('editSelectedQuestion', questionUpdatedOrCreated)
    //question data
    /*  const [questionData, setQuestionData] = useState({ option1: '', option2: '', option3: '', option4: '', option5: '', correctAnswer: 'Answer', explanation: '', selectedQuestionLevel: 'Q.level', selectedQuestionType: 'Q.type', selectedComprehension: 'Comprehension' }); */
    const [questionTitle, setQuestion] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');
    const [option5, setOption5] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [explanation, setExplaination] = useState('');
    const [selectedQuestionLevel, setQLevelValue] = useState('Q.level');
    const [selectedQuestionType, setQTypeValue] = useState('Q.type');
    const [selectedComprehension, setSelectedComprehension] = useState('Comprehension');
    console.log(
        `Question Title: ${questionTitle}\n` +
        `Option 1: ${option1}\n` +
        `Option 2: ${option2}\n` +
        `Option 3: ${option3}\n` +
        `Option 4: ${option4}\n` +
        `Option 5: ${option5}\n` +
        `Correct Answer: ${correctAnswer}\n` +
        `Explanation: ${explanation}\n` +
        `Selected Question Level: ${selectedQuestionLevel}\n` +
        `Selected Question Type: ${selectedQuestionType}\n` +
        `Selected Comprehension: ${selectedComprehension}`
    );


    const [questionId, setQuestionId] = useState(0);
    const [selectedQuestion, setSelectedQuestion] = useState()

    const [existedQuestionsInCurrentTest, setExistedQuestionsInCurrentTest] = useState()
    const [allComprehensionsForCurrentTest, setAllComprehenstionsForCurrentTest] = useState()

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/get-qlevels').then((qLevelsResponse) => {
            setQLevels(qLevelsResponse.data.qlevels);
        });

        axios.get(BACKEND_SERVER_URL + '/get-qtypes').then((qTypesResponse) => {
            setQTypes(qTypesResponse.data.qtypes);
        });
        axios.get(BACKEND_SERVER_URL + '/getComprehensions').then((res) => {
            setComprehensions(res.data.comprehensions.filter((comprehension) => comprehension.hide == 0));
        });

        axios.get(BACKEND_SERVER_URL + '/getTestsForQBank').then((response) => {
            const tests = response.data.tests;
            setTests(tests);
            const testNameArray = tests.map((test) => test?.testName);
            setTestNames(testNameArray);

            if (testSessionId) {
                const test = tests.find((test) => test.sessionId == testSessionId);
                setSelectedTest(test)
                setSelectedTestName(test.testName);
                setTestId(test.id);
                setTestSections(JSON.parse(test.sectionDetails));
                //  setSectionNameArray(testSections?.map((section) => section.sectionName))

                setSelectedSectionName(null);
                setSelectedQuestionNumber(0);
                loadTestQuestions(test.id);
            }
        })
    }, [])

    const [existedQuestionNumbers, setExistedQuestionNumbers] = useState(new Set());
    const loadTestQuestions = (testId) => {
        getTestQuestions(testId).then((response) => {
            setExistedQuestionsInCurrentTest(response.data?.questions)

            let questionNumbers = response.data?.questions.map((question) => {
                return question.questionNumber
            });
            let questionsSet = new Set(questionNumbers);// it should be questionNumbersSet
            setExistedQuestionNumbers(questionsSet);
            if (selectedQuestionNumber && questionsSet.has(selectedQuestionNumber)) {
                const question = response.data?.questions.find((question) => question.questionNumber == selectedQuestionNumber)
                setQuestionId(question.id)
            }
        }).catch((error) => {
            //  toast.error(error.message)
        })
    }


    const [sectionsArray, setSectionsArray] = useState([]);
    useEffect(() => {
        let selectedTest = tests?.find((test) => test.testName === selectedTestName);
        if (!selectedTest) return;
        setSelectedTest(selectedTest)
        let selectedSectionDetails = JSON.parse(selectedTest?.sectionDetails || '[]');

        setTestSections(selectedSectionDetails);
        // setSelectedSectionName(null);
        if (!selectedQuestionNumber) {
            setSelectedQuestionNumber(1);
            setSelectedSectionName(selectedSectionDetails[0].sectionName)
        }

        if (selectedSectionDetails && selectedSectionDetails.length > 0) {
            let questionNumber = 1;
            let sectionsArray = new Array(selectedSectionDetails.length);
            for (let loopIndex = 0; loopIndex < selectedSectionDetails.length; loopIndex++) {
                let questionBoxArray = new Array(selectedSectionDetails[loopIndex].totalQuestion);

                for (let innerLoopIndex = 0; innerLoopIndex < selectedSectionDetails[loopIndex].totalQuestion; innerLoopIndex++) {
                    questionBoxArray[innerLoopIndex] = { questionNumber, isQuestionExisted: existedQuestionNumbers.has(questionNumber), currentSectionName: selectedSectionDetails[loopIndex].sectionName };
                    questionNumber++;
                }
                sectionsArray[loopIndex] = questionBoxArray;
            }
            setSectionsArray(sectionsArray);
        }
        let comprehensionsForCurrentTest = comprehensions?.filter((comprehension) => comprehension.testId == testId);
        setAllComprehenstionsForCurrentTest(comprehensionsForCurrentTest)
        setInitialValue();
    }, [selectedTestName, existedQuestionNumbers]);


    const handleQuestionBoxClick = (sectionName, questionNumber) => {
        setSelectedSectionName(sectionName);
        setSelectedQuestionNumber(questionNumber);
        setEditSelectedQuestion(false);
    }


    const selectTestHandler = async (value) => {
        let test = tests?.find((test) => test.testName == value);
        setTestId(test?.id);
        setSelectedQuestionNumber(0)
        loadTestQuestions(test?.id);
        setSelectedTestName(value);


        //  const sectionNameArray = tests?.map((test) => test?.JSON.parse(sectionDetails).sectionName);
    };
    const handleSaveQuestion = () => {
        if (testId <= 0) {
            toast.error('Please select a test to proceed');
            return;
        } else if (!correctAnswer || selectedQuestionLevel === 'Question level' || !selectedQuestionType === 'Question type') {
            toast.error('Please select the question specifications');
            return;
        } else if (selectedQuestionNumber <= 0) {
            toast.error('Please choose the question number which you want to add');
            return;
        }

        let section = testSections.find((section) => section.sectionName == selectedSectionName);

        let selectedLevel = questionLevels.find((level) => level.levelName === selectedQuestionLevel);
        let comprehensionToBeSend = comprehensions.find((comprehension) => comprehension.comprehensionName == selectedComprehension);
        let question = {
            testId,
            sectionId: selectedSectionName,
            questionNumber: selectedQuestionNumber,
            selectedQuestionType,
            questionLevelId: selectedLevel.id,
            comprehensionId: comprehensionToBeSend?.id || -1,
            questionTitle, option1, option2, option3, option4, option5, explanation, correctAnswer,
            questionMarks: section.marksPerQestion,
            negativeMarks: section.negativeMarks

        }


        if (!existedQuestionNumbers.has(selectedQuestionNumber)) {
            createQuestion(question).then((response) => {
                loadTestQuestions(testId)
                setExistedQuestionNumbers(new Set([...existedQuestionNumbers, question.questionNumber]))
                toast.success(response.data.message);
                setQuestionUpdatedOrCreated(false);

            }).catch((error) => {
                toast.error(error.message);
            })
        } else {
            updateQuestion(question, questionId).then((response) => {
                loadTestQuestions(testId)
                setExistedQuestionNumbers(new Set([...existedQuestionNumbers, question.questionNumber]))
                toast.success(response.data.message);
                setEditSelectedQuestion(false)
                setQuestionUpdatedOrCreated(false);

            }).catch((error) => {
                toast.error(error.message);
            })
        }
    }

    function setInitialValue() {
        const selectedQuestionToDisplay = existedQuestionsInCurrentTest?.find((question) => question.questionNumber == selectedQuestionNumber)
        let currentSectionObject = sectionsArray?.find(innerArray => {
            return innerArray?.some(obj => obj.questionNumber === selectedQuestionNumber);
        })?.find(selectedObj => selectedObj.questionNumber === selectedQuestionNumber);

        if (existedQuestionNumbers?.has(selectedQuestionNumber)) {
            setSelectedSectionName(currentSectionObject?.currentSectionName)
            setQuestion(selectedQuestionToDisplay?.questionTitle);
            setQuestionId(selectedQuestionToDisplay?.id)
            setOption1(selectedQuestionToDisplay?.option1)
            setOption2(selectedQuestionToDisplay?.option2)
            setOption3(selectedQuestionToDisplay?.option3)
            setOption4(selectedQuestionToDisplay?.option4)
            setOption5(selectedQuestionToDisplay?.option5)
            setExplaination(selectedQuestionToDisplay?.explanation)
            setQTypeValue(selectedQuestionToDisplay?.questionType)
            setQLevelValue(questionLevels?.find((level) => level.id == selectedQuestionToDisplay?.questionLevel)?.levelName)
            setCorrectAnswer(selectedQuestionToDisplay?.correctAnswer)
            let comprehension = comprehensions?.find((comprehension) => comprehension.id == selectedQuestionToDisplay?.comprehensionId)
            if (comprehension) setSelectedComprehension(comprehension?.comprehensionName)
            else setSelectedComprehension('Comprehension')
        } else if (selectedQuestionToDisplay?.questionNumber != selectedQuestionNumber && !existedQuestionNumbers.has(selectedQuestionNumber)) {
            setSelectedSectionName(currentSectionObject?.currentSectionName)
            setQuestion('')
            setQuestionId(0)
            setOption1('')
            setOption2('')
            setOption3('')
            setOption4('')
            setOption5('')
            setExplaination('')
            setQLevelValue('Q.level')
            setQTypeValue('Q.Type')
            setSelectedComprehension('Comprehension')
            setCorrectAnswer('')

        }
    }

    useEffect(() => {
        setInitialValue();
    }, [selectedQuestionNumber])
    const deleteQuestionHandler = async () => {
        try {
            swal({
                title: "Are you sure?",
                text:
                    "Once deleted, you will not be able to recover this question",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    deleteQuestion(questionId).then(async (res) => {
                        if (res.data.statusCode == 200) {
                            loadTestQuestions(testId);
                            const updatedQuestionNumbers = new Set([...existedQuestionNumbers].filter(questionNumber => questionNumber !== selectedQuestionNumber));
                            setExistedQuestionNumbers(updatedQuestionNumbers);
                            setQuestion('');
                            setOption1('')
                            setOption2('')
                            setOption3('')
                            setOption4('')
                            setOption5('')
                            setExplaination('')
                            setCorrectAnswer(null)
                            setQLevelValue('Question level')
                            setQTypeValue('Question Type')
                            swal(`Question ${selectedQuestionNumber} has been deleted!`, {
                                icon: "success",
                            });
                        }
                    })
                }
            })
        } catch (error) {
            console.error("Error deleting question:", error);
            toast.error("Failed to delete the question. Please try again.");
        }
    };
    function handleReset() {
        setQuestion('')
        setOption1('')
        setOption2('')
        setOption3('')
        setOption4('')
        setOption5('')
        setExplaination('')
        setQLevelValue('Q.level')
        setQTypeValue('Q.Type')
        setSelectedComprehension('Comprehension')
        setCorrectAnswer('Answer')
    }
    //upload question file
    function handleUploadFile(selectedFile, testId, totalQuestoins) {
        let formData = new FormData();
        formData.append('uploadedFile', selectedFile, selectedFile.name);
        formData.append('totalQuestions', totalQuestoins);
        axios.post(BACKEND_SERVER_URL + '/uploadQuestions/' + testId, formData)
            .then((response) => {
                if (response.data.statusCode == 201) {
                    toast.success(response.data.message);
                    loadTestQuestions(testId);
                } else {
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }
    const formatAnswers = (correctAnswer) => {

        const formattedAnswers = correctAnswer?.split('')?.map(answer => ({ value: answer.toUpperCase(), label: answer.toUpperCase() }))

        return formattedAnswers
    }
    const setInputElementForCorrectAnswer = (questionType) => {

        if (questionType == 'MCQ') {
            return <Select
                value={correctAnswer ? { value: correctAnswer.toUpperCase(), label: correctAnswer.toUpperCase() } : null}
                onChange={(selectedOption) => setCorrectAnswer(selectedOption?.value)}
                options={['A', 'B', 'C', 'D', 'E'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        } else if (questionType == 'MSQ') {
            return <Select
                isMulti
                value={formatAnswers(correctAnswer)}
                onChange={(selectedOptions) => setCorrectAnswer(selectedOptions ? selectedOptions.map(option => option.value).join('') : '')}
                options={['A', 'B', 'C', 'D', 'E'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        } else if (questionType == 'NVQ') {
            return <input type="number" className="form-control border" placeholder="Correct Answer" value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)} styles={{borderColor: '#17a2b8'}} />

        } else if (questionType == 'OWQ') {
            return <Select
                value={correctAnswer ? { value: correctAnswer.toUpperCase(), label: correctAnswer.toUpperCase() } : null}
                onChange={(selectedOption) => setCorrectAnswer(selectedOption?.value)}
                options={['A', 'B', 'C', 'D', 'E'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        } else if (questionType == 'FBQ') {
            return <Select
                value={correctAnswer ? { value: correctAnswer.toUpperCase(), label: correctAnswer.toUpperCase() } : null}
                onChange={(selectedOption) => setCorrectAnswer(selectedOption?.value)}
                options={['A', 'B', 'C', 'D', 'E'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        } else if (questionType == 'TFQ') {
            return <Select
                value={correctAnswer ? { value: correctAnswer.toUpperCase(), label: correctAnswer.toUpperCase() } : null}
                onChange={(selectedOption) => setCorrectAnswer(selectedOption?.value)}
                options={['A', 'B'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        } else if (questionType == 'MTC') {
            return <Select
                value={correctAnswer ? { value: correctAnswer.toUpperCase(), label: correctAnswer.toUpperCase() } : null}
                onChange={(selectedOption) => setCorrectAnswer(selectedOption?.value)}
                options={['A', 'B', 'C', 'D', 'E'].map((answer) => ({ value: answer, label: answer }))}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        borderColor: '#17a2b8',
                    }),
                }}
            />
        }
    }
    const setEditorsAccordingQType = (selectedQuestionType) => {
        const questionCard = (
            <div className="col-md-12 pt-0">
                <div className="card pt-0">
                    <div className="card-header pt-2 pb-2">
                        <h4 className="card-title">Question {selectedQuestionNumber > 0 ? selectedQuestionNumber : null} </h4>
                        {/*  <button className="btn btn-dark btn-sm" onClick={() => childRef.current.openModal(selectedTest)} >+Import Questions</button> */}

                    </div>
                    <div className="card-body custom-ekeditor">
                        <CKEditor
                            editor={ClassicEditor}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            data={questionTitle}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setQuestion(data)
                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                            }}
                        />
                    </div>
                </div>
            </div>)
        const optionACard = < div className="col-md-6 pt-0" >
            <div className="card pt-0">
                <div className="card-header  pb-2 pt-2">
                    <h4 className="card-title ">Option A</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={option1}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setOption1(data)
                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </div>
            </div>
        </div >
        const trueFalseOptionCard = <div className="card">
            <div className="card-header">
                <h4 className="card-title ">Option A: True </h4>
                <h4 className="card-title mt-0 pt-0">Option B: False </h4>
            </div>

        </div>
        const optionBCard = (<div className="col-md-6">
            <div className="card">
                <div className="card-header  pb-2 pt-2">
                    <h4 className="card-title ">Option B</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={option2}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setOption2(data)

                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </div>
            </div>
        </div>)
        const optionCCard = (<div className="col-md-6">
            <div className="card">
                <div className="card-header  pb-2 pt-2">
                    <h4 className="card-title">Option C</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={option3}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setOption3(data)

                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </div>
            </div>
        </div>)
        const optionDCard = (<div className="col-md-6">
            <div className="card">
                <div className="card-header  pb-2 pt-2">
                    <h4 className="card-title">Option D</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={option4}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setOption4(data)

                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </div>
            </div>
        </div>)
        const optionECard = (<div className="col-md-6">
            <div className="card">
                <div className="card-header pb-2 pt-2">
                    <h4 className="card-title">Option E</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={option5}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setOption5(data)

                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                            //console.log( 'Focus.', editor );
                        }}
                    />
                </div>
            </div>
        </div>)
        const explainationCard = (<div className="col-md-6 ">
            <div className="card pb-1 mb-0">
                <div className="card-header pb-2 pt-2">
                    <h4 className="card-title">Explaination</h4>
                </div>
                <div className="card-body custom-ekeditor">
                    <CKEditor
                        editor={ClassicEditor}
                        // data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                        }}
                        data={explanation}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setExplaination(data)

                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                    />
                </div>
            </div>
        </div>)
        if ((selectedQuestionType == 'MCQ' || selectedQuestionType == 'MSQ' || selectedQuestionType == 'MTC' || selectedQuestionType == 'FBQ' || selectedQuestionType == 'OWQ')) {
            return (<> {questionCard}  {optionACard}  {optionBCard}  {optionCCard}  {optionDCard}  {optionECard}  {explainationCard}</>)
        } else if (selectedQuestionType == 'TFQ') {

            return (<> {questionCard}  {trueFalseOptionCard} {explainationCard}</>)
        } else {
            return (<> {questionCard}  {explainationCard}</>);
        }
    }
    useEffect(() => {
        if (selectedQuestionType === 'TFQ') {
            setOption1('True');
            setOption2('False');
        }
    }, [selectedQuestionType]);

    return (
        <Fragment >
            <div className="card p-0 rounded-0 mb-0 ps-0">
                <div className="row gx-0 ">
                    <div className="col-xl-2 col-xxl-3 col-lg-3  " >
                        <div className="email-left-box dlab-scroll pt-2" >
                            <div className="">
                                <div className="basic-dropdown">
                                    <Select
                                        value={{ value: selectedTestName, label: selectedTestName }}
                                        onChange={(selectedOption) => selectTestHandler(selectedOption.value)}
                                        options={testNames.map((testName) => ({ value: testName, label: testName }))}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                minWidth: '100%',
                                                backgroundColor: 'transparent', // Adjust as needed
                                                borderColor: '#17a2b8', // Adjust as needed

                                            }),
                                        }}

                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-start p-2">
                                {testId ? <button className="btn btn-xs text-light btn-info btn-block" onClick={() => childRef.current.openModal(selectedTest)} >+Import Questions</button> : null}
                            </div>
                            <div className="col-sm-12 mt-0">
                                {
                                    testSections?.map((section, sectionIndex) => (
                                        <div className="col-12 " key={sectionIndex}>
                                            <p className="text-primary mt-1 mb-0 ">{section.sectionName} </p> <hr className="mt-0" />
                                            <div className="row" key={sectionIndex}>
                                                {
                                                    sectionsArray[sectionIndex]?.map((questionBoxData) => (
                                                        <div onClick={() => { handleQuestionBoxClick(section.sectionName, questionBoxData.questionNumber); }} className="col-1 p-1 m-1 ms-3  rounded-circle text-center cursor-pointer"
                                                            style={{
                                                                backgroundColor: questionBoxData.isQuestionExisted ? 'white' : 'white', width: '26px', height: '26px', fontSize: '11px',
                                                                color: questionBoxData.isQuestionExisted ? 'black' : 'black',
                                                                border: questionBoxData.isQuestionExisted ? `${selectedQuestionNumber == questionBoxData.questionNumber ? '2px' : '1px'} solid green` : `${selectedQuestionNumber == questionBoxData.questionNumber ? '2px' : '1px'} solid red`
                                                            }}
                                                        >
                                                            {questionBoxData.questionNumber}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 col-xxl-9 col-lg-9 ">
                        <div className="email-right-box ">
                            {testId ? <Tab.Container defaultActiveKey="Import">
                                <div role="toolbar" className="toolbar ms-1 ms-sm-0 ">
                                    <div className="saprat " style={{ backgroundColor: "#f4f5f6", pointerEvents: existedQuestionNumbers?.has(selectedQuestionNumber) && !editSelectedQuestion ? 'none' : 'auto' }}>
                                        <div className=" w-100 m-auto  ">
                                            <Nav as="ul" className="nav nav-pills d-flex align-items-center justify-content-between pe-3 ps-3" id="pills-tab" role="tablist">
                                                <div style={{ width: "20%" }}>
                                                    <Select
                                                        value={selectedQuestionType ? { value: selectedQuestionType, label: selectedQuestionType } : null}
                                                        onChange={(selectedOption) => setQTypeValue(selectedOption?.value)}
                                                        options={questionTypes?.map((qtype) => ({ value: qtype.typeName, label: qtype.typeName }))}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                minWidth: '100%',
                                                                backgroundColor: 'white', // Adjust as needed
                                                                borderColor: '#17a2b5', // Adjust as needed
                                                            }),
                                                        }}
                                                    />
                                                </div>

                                                <div style={{ width: "20%" }}>

                                                    <Select
                                                        value={selectedQuestionLevel ? { value: selectedQuestionLevel, label: selectedQuestionLevel } : null}
                                                        onChange={(selectedOption) => setQLevelValue(selectedOption?.value)}
                                                        options={questionLevels?.map((qlevel) => ({ value: qlevel.levelName, label: qlevel.levelName }))}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,

                                                                minWidth: '100%',
                                                                backgroundColor: 'white',
                                                                borderColor: '#17a2b8',
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                                <div style={{ width: "20%" }}>
                                                    <Select
                                                        value={selectedComprehension ? { value: selectedComprehension, label: selectedComprehension } : null}
                                                        onChange={(selectedOption) => setSelectedComprehension(selectedOption?.value)}
                                                        options={allComprehensionsForCurrentTest?.map((comprehension) => ({ value: comprehension.comprehensionName, label: comprehension.comprehensionName }))}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                minWidth: '100%',
                                                                backgroundColor: 'white',
                                                                borderColor: '#17a2b8',
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                                <div style={{ width: "30%" }}>
                                                    {
                                                        setInputElementForCorrectAnswer(selectedQuestionType)
                                                    }

                                                </div>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="row me-3 m-0 p-0 pt-1 pb-1">
                                    <div className="col-6 d-flex justify-content-start">
                                        {selectedQuestionNumber != 1 ?
                                            <Button
                                                variant="secondary"
                                                size="xs"
                                                onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                                disabled={selectedQuestionNumber == 1}
                                            >
                                                <SlArrowLeft className="" size={10} /> Previous
                                            </Button> : null
                                        }
                                    </div>
                                    <div className="col-6  d-flex justify-content-end">
                                        {
                                            selectedQuestionNumber != selectedTest?.totalQuestions ?
                                                <Button variant="success" size="xs" className="ms-5" onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)} disabled={selectedQuestionNumber == selectedTest?.totalQuestions}> Next <SlArrowRight size={10} />
                                                </Button> : null
                                        }
                                    </div>
                                </div>
                                {
                                    (!existedQuestionNumbers?.has(selectedQuestionNumber) && !questionUpdatedOrCreated) || (existedQuestionNumbers?.has(selectedQuestionNumber) && editSelectedQuestion) ? <Tab.Content>
                                        <div className="email-list bg-light">
                                            <div className="row">
                                                {setEditorsAccordingQType(selectedQuestionType)}
                                                <div className="row mb-1 page-title mb-2 mt-0 ">
                                                    <div className="col-6"></div>
                                                    <div className="col-6 d-flex justify-content-end ">
                                                        <button className="btn btn-outline-warning  me-3 btn-sm" onClick={handleReset}>RESET</button>
                                                        <Button variant="info" className="btn btn-info me-3 " size="sm" onClick={handleSaveQuestion}>{existedQuestionNumbers?.has(selectedQuestionNumber) ? 'UPDATE QUESTION' : 'CREATE QUESTION'}  </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab.Content> :
                                        <div className="col-xl-12 col-xxl-12 col-lg-12 ">
                                            <div className="card h-auto">
                                                <div className="card-body pt-1">
                                                    <div className="teacher-deatails " style={{ color: 'black' }}>
                                                        {/* <p className="heading">Section: {" " + selectedSectionName} </p> */}
                                                        <p className="heading text-dark">Question: {" " + selectedQuestionNumber} </p>
                                                        <div className="d-flex">
                                                            <div> {questionTitle ? parse(questionTitle) : null}</div>
                                                        </div>
                                                        {option1 && <div className="d-flex">
                                                            <div className="me-2" > <label style={{ marginTop: ".1rem" }}>(A)</label></div>
                                                            <div> <label lassName="">{option1 ? parse(option1) : null}</label> </div>
                                                        </div>}
                                                        {option2 && <div className="d-flex">
                                                            <div className="me-2"> <label style={{ marginTop: ".1rem" }}>(B)</label></div>
                                                            <div> <label>{option2 ? parse(option2) : null}</label> </div>
                                                        </div>}
                                                        {option3 && <div className="d-flex">
                                                            <div className="me-2"> <label style={{ marginTop: ".1rem" }}>(C)</label></div>
                                                            <div> <label>{option3 ? parse(option3) : null}</label></div>
                                                        </div>}
                                                        {option4 && <div className="d-flex">
                                                            <div className="me-2"> <label style={{ marginTop: ".1rem" }}>(D)</label></div>
                                                            <div> <label>{option4 ? parse(option4) : null}</label> </div>
                                                        </div>}
                                                        {option5 &&
                                                            <div className="d-flex">
                                                                <div className="me-2"> <label style={{ marginTop: ".1rem" }}>(E)</label></div>
                                                                <div> <label>{option5 ? parse(option5) : null}</label> </div>
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <div className="me-2"> <label style={{ fontWeight: "600", marginTop: ".1rem" }}>Explanation:</label></div>
                                                            <div> <label>{explanation ? parse(explanation) : null} </label></div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-sm-6">
                                                            <Button variant="info" size="xs" onClick={() => setEditSelectedQuestion(true)}>Update </Button>
                                                            <Button variant="danger" size="xs" className="ms-2" onClick={deleteQuestionHandler}>Delete </Button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                }
                            </Tab.Container> : <div className=" mt-5 d-flex justify-content-center align-items-center">

                                <br />
                                <h3 className="text-info"> Please select any test to proceed </h3>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <TestModal ref={childRef} uploadCallBack={handleUploadFile} />
        </Fragment >

    );
};

export default QuestionBank;
