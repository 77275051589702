import React from 'react';

const AdminDashboard = () => {
    return (
        <div>
            <h2>
                Work in Progress
            </h2>
        </div>
    );
}

export default AdminDashboard;
