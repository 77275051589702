import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Modal, Col, Card, Row, Alert, Spinner, Table, Badge } from "react-bootstrap";
//import { SRLWrapper } from "simple-react-lightbox";
import { LuCheck, LuX } from "react-icons/lu";

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

/// Image
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";

//charts
import ChartPie from "../../charts/Chartjs/pie";
import Select from "react-select";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../../http/http-config";
import TestReport from "./TestReport";


const Reports = () => {
    const [attemptedTests, setAttemptedTest] = useState();
    const [selectedTestName, setSelectedTestName] = useState('Select Test Name');
    const [selectedTestId, setSelectedTestId] = useState(0);
    const [selectedBatchId, setSelectedBatchId] = useState(0);
    console.log(selectedBatchId, selectedTestId)
    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/student/getAttemptedStudentTest').then((res) => {
            if (res?.data?.attemptedTests) {
                let attemptedTests = res.data.attemptedTests
                setAttemptedTest(attemptedTests);
            }
        })
    }, [])

    const selectTestHandler = (selectedOption) => {      
        let selectedTest = attemptedTests?.find((test => (test.batchId == selectedOption.value.batchId) && (test.testId == selectedOption.value.testId)))
        setSelectedTestName(selectedTest?.testName)
        setSelectedTestId(selectedTest?.testId);
        setSelectedBatchId(selectedTest?.batchId)
    }
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                        <div className='d-flex justify-content-between'>
                            <div className="basic-dropdown">
                                <Select
                                    value={{ value: selectedTestName, label: selectedTestName }}
                                    onChange={(selectedOption) => selectTestHandler(selectedOption)}
                                    options={attemptedTests?.map((test) => ({ value: test, label: test.testName }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '30rem',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="row">
                {selectedBatchId && selectedTestId ? <TestReport selectedTestId={selectedTestId} selectedBatchId={selectedBatchId} /> : null}

            </div>
        </>
    );
};

export default Reports;