import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';



const NewsCategoryModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        category: '',
    }

    const [newsCategoryData, setNewsCategoryData] = useState(initialData);
    console.log(newsCategoryData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setNewsCategoryData({ sn: item.sn, category: item.category, sort: item.sort })
            }
            setModalBox(true);

        }
    }));

    const hanldeSaveAcademicYear = (value) => {
        if (newsCategoryData.category.trim().length === 0) {
            toast.error("Please New Category ")
        }
        axios.post(BACKEND_SERVER_URL + `${!newsCategoryData.sn ? '/saveNewsCategory' : `/updateNewsCategory`}`, newsCategoryData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setNewsCategoryData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setNewsCategoryData(initialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {newsCategoryData.sn ? "Update New Category" : "Add New Category"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">News Category<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={newsCategoryData.category} onChange={(e) => setNewsCategoryData({ ...newsCategoryData, category: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={newsCategoryData.sort} onChange={(e) => setNewsCategoryData({ ...newsCategoryData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={hanldeSaveAcademicYear}>{newsCategoryData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default NewsCategoryModal;