import axios from "axios";
import { useState, useEffect, useContext, createContext } from "react";

const AuthContext = createContext();

const UserRoleProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(1)
    const tokenDetailsString = localStorage.getItem('userDetails');
    useEffect(() => {
        if (tokenDetailsString) {
            const userRoleInLocalStorage = JSON.parse(tokenDetailsString)?.userRole
            if (userRoleInLocalStorage && userRoleInLocalStorage != userRole) {
                setUserRole(userRoleInLocalStorage)
            }
        }

    }, [userRole]);
    return (
        <AuthContext.Provider value={[userRole, setUserRole]}>
            {children}
        </AuthContext.Provider>
    )
}
//custom hook
const useRole = () => {
    return useContext(AuthContext)
}
export { useRole, UserRoleProvider }