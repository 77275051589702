import axios from 'axios';
import { v4 as uuid } from 'uuid'
import { BACKEND_SERVER_URL } from '../../../http/http-config';


export async function fetchBlogData(blogId) {
    console.log('called')
    return (await axios.get(BACKEND_SERVER_URL + '/getBlog/' + blogId)).data.blog
}

export async function formateBlogData(data) {
    return {
        title: data.Title,
        urlTitle: data.TitleUrl,
        subtitle: data.Subtitle,
        published: data.Published || 'NO',
        description: data.Description,
        imageURL: data.Image,
        imageAlt: data.ImageAlt,
        imageTitle: data.ImageTitle,
        likeStart: data.PreLikes,
        viewStart: data.preViews,
        imageCaption: data.ImageCaption,
        category: data.Categories,
        categoryId: data.CategoryID,
        keywords: data.Tags,
        metaTitle: data.MetaTitle,
        metaDescription: data.MetaDescription,
        publishDate: data.PublishDate,
        writtenBy: data.WrittenBy
    };
}

export function validateBlogData(formData) {
    if (!formData.title) {
        return "Title is Required";
    } else if (!formData.urlTitle) {
        return "URL Title is Required";
    } else if (!formData.subtitle) {
        return "Subtitle is Required";
    } else if (!formData.published) {
        return "Published status is Required";
    } else if (!formData.description) {
        return "Description is Required";
    } else if (!formData.imageURL) {
        return "Image URL is Required";
    } else if (!formData.imageAlt) {
        return "Image Alt Text is Required";
    } else if (!formData.imageTitle) {
        return "Image Title is Required";
    } else if (!formData.imageCaption) {
        return "Image Caption is Required";
    } else if (!formData.category) {
        return "Category is Required";
    } else if (!formData.categoryId) {
        return "Please Select Category"
    } else if (!formData.keywords) {
        return "Keywords are Required";
    } else if (!formData.metaTitle) {
        return "Meta Title is Required";
    } else if (!formData.metaDescription) {
        return "Meta Description is Required";
    } else if (!formData.publishDate) {
        return "Publish Date is Required";
    } else if (!formData.writtenBy) {
        return "Author Name is Required";
    } else {
        return null; // Return null if all validations pass
    }
}
