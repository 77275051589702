import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Button, Modal, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { CiEdit } from 'react-icons/ci';
import { MdDeleteOutline } from 'react-icons/md';
import swal from 'sweetalert';
import Select from 'react-select';

//format(date, 'dd/MM/yyyy')
const BatchInput = ({ inputValue, handleChangeCallBack, labelName, inputType, inputName, courseId, moduleName, updating }) => {

    const [associatedBatches, setAssociatedBatches] = useState([])
    const [currentBatch, setCurrentBatch] = useState()
    console.log(inputValue)
    // console.log(associatedBatches)

    let initialValue = {
        sNo: 0,
        courseId: courseId,
        startDate: new Date(),
        adminCloseDate: new Date(),
        price: '',
        batchName: '',
        status: "ACTIVE",
        hide: 1,
        testCount: 0 //only for test series batches
    }
    const [batchData, setBatchData] = useState(initialValue);

    const handleSubmitBatch = () => {
        if (!batchData.startDate || !batchData.adminCloseDate || !batchData.price || !batchData.batchName) {
            toast.error("Please Enter All field ")
            return;
        }
        const formattedBatchData = {
            ...batchData,
            startDate: batchData.startDate.toISOString().split('T')[0], // Format startDate as YYYY-MM-DD
            endDate: batchData.adminCloseDate.toISOString().split('T')[0] // Format endDate as YYYY-MM-DD
        };
        axios.post(
            BACKEND_SERVER_URL + (batchData.sNo ? `/edit${moduleName == 'coursePage' ? 'Course' : 'Test'}Batch/${batchData.sNo}` : `/save${moduleName == 'coursePage' ? 'Course' : 'Test'}Batch`),
            formattedBatchData
        ).then(response => {
            if (response.data.statusCode == 200) {
                let associatedBatches = response.data.associatedBatches
                if (moduleName == 'testPage') {
                    setAssociatedBatches(associatedBatches.map(batch => ({ ...batch, AdmCloseDate: batch.AdmissionCloseDate })));
                } else {
                    setAssociatedBatches(associatedBatches)
                }
                setCurrentBatch(associatedBatches[associatedBatches.length - 1])
                setBatchData(initialValue)
                toast.success(response.data.message)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        if (updating) {
            axios.get(BACKEND_SERVER_URL + `/get${moduleName == 'coursePage' ? 'Course' : 'Test'}Batches/` + courseId).then(response => {
                if (response.data.statusCode == 200) {
                    let associatedBatches = response.data.associatedBatches;
                    if (moduleName == 'testPage') {
                        setAssociatedBatches(associatedBatches.map(batch => ({ ...batch, AdmCloseDate: batch.AdmissionCloseDate })));
                    } else {
                        setAssociatedBatches(associatedBatches)
                    }
                    let currentBatch = response.data.associatedBatches?.find((batch) => batch.Sn == inputValue)
                    setCurrentBatch(currentBatch)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [courseId, inputValue])
    const handleBatchDelete = (batch) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this batch",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.post(BACKEND_SERVER_URL + `/delete${moduleName == 'coursePage' ? 'Course' : 'Test'}Batch`, { courseId: batch.CourseID, sNo: batch.Sn }).then(response => {
                    if (response.data.statusCode == 200) {
                        let associatedBatches = response.data.associatedBatches
                        setAssociatedBatches(associatedBatches)
                        setCurrentBatch(associatedBatches[associatedBatches.length - 1])
                        toast.success(response.data.message)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        })

    }
    const handleBatchEdit = (batch) => {
        console.log(batch)
        setBatchData({
            sNo: batch.Sn,
            courseId: batch.CourseID,
            startDate: new Date(batch.StartDate.toString().replace(/\s+/g, "")),
            adminCloseDate: new Date(batch.AdmCloseDate.toString().replace(/\s+/g, "")),
            price: batch.Price,
            batchName: batch.BatchName,
            testCount: batch?.TestCount,
            status: "ACTIVE",
        })
    }
    useEffect(() => {
        if (currentBatch?.Sn > 0) {
            handleChangeCallBack(inputName, currentBatch?.Sn)
        }
    }, [currentBatch])

    return (
        <>
            <div className="col-12 ">
                <div className="col-12 d-flex">
                    <div className="col-xl-2 col-sm-2">
                        <div className="mb-3 card-body ms-0 ps-0" >
                            <label htmlFor="exampleFormControlInput1" className="form-label text-primary">{labelName}<span className="required">*</span></label>

                        </div>
                    </div>
                    <div className="col-xl-10 col-sm-10  "  >
                        <div className="col-12 d-flex">
                            <div className="mb-3" style={{ width: '15%' }} >
                                <label className="form-label text-primary">Start Date<span className="required">*</span></label>
                                <br />
                                <DatePicker
                                    className="form-control full-width full-width-container .react-datepicker-wrapper"
                                    selected={batchData.startDate}
                                    onChange={(date) => setBatchData({ ...batchData, startDate: date })}
                                    dateFormat="dd/MM/yyyy"

                                />
                            </div>
                            <div className="mb-3" style={{ width: '15%' }}>
                                <label className="form-label text-primary">Admns Close Date<span className="required">*</span></label>
                                <br />
                                <DatePicker
                                    className="form-control full-width full-width-container .react-datepicker-wrapper"
                                    selected={batchData.adminCloseDate}
                                    onChange={(date) => setBatchData({ ...batchData, adminCloseDate: date })}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className="mb-3" style={{ width: '15%' }}>
                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Price<span className="required">*</span></label>
                                <input type='number' className="form-control" id="sectionDetails_" placeholder="Enter Price" value={batchData.price} onChange={(e) => setBatchData({ ...batchData, price: e.target.value })} />
                            </div>
                            <div className="mb-3" style={{ width: '15%' }}>
                                <label htmlFor="exampleFormControlInput2" className="form-label text-primary ">Batch Name<span className="required">*</span></label>
                                <input type="text" className="form-control " id="exampleFormControlInput2" placeholder="Batch name" value={batchData.batchName} onChange={(e) => setBatchData({ ...batchData, batchName: e.target.value })} />
                            </div>
                            {moduleName == "testPage" ?
                                <div className="mb-3" style={{ width: '15%' }}>
                                    <label htmlFor="exampleFormControlInput3" className="form-label text-primary ">Test Count<span className="required">*</span></label>
                                    <input type="number" className="form-control " id="exampleFormControlInput3" placeholder="Count" value={batchData.testCount} onChange={(e) => setBatchData({ ...batchData, testCount: e.target.value })} />
                                </div> :
                                null
                            }
                            <div className="mb-3 mt-4" style={{ width: '20%' }}>
                                <Button className='btn-xs h-100' variant="outline-dark btn-square" onClick={handleSubmitBatch} style={{ width: "60%" }}>
                                    {!batchData.sNo ? 'Add Batch' : 'Update Batch'}
                                </Button>
                                {/*  <Button variant="outline-dark btn-square">Dark</Button> */}
                            </div>
                        </div>
                        <div className="col-10">
                            <Table responsive bordered className="text-center">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Start Date</th>
                                        <th>Adms. Close Date</th>
                                        <th>Price</th>
                                        <th>Batch</th>
                                        {moduleName == "testPage" ? <th>Test Count</th> : null}
                                        <th>Delete</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {associatedBatches?.map((batch) => (
                                        <tr key={batch.Sn}>
                                            <td>{batch.Sn}</td>
                                            <td>{new Date(batch?.StartDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</td>
                                            <td>{new Date(batch?.AdmCloseDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</td>
                                            <td>{batch.Price}</td>
                                            <td>{batch.BatchName}</td>
                                            {moduleName == "testPage" ? <td>{batch.TestCount}</td> : null}
                                            <td>< MdDeleteOutline title='Delete Batch' cursor='pointer' size={18} className='ms-2' color='red' onClick={() => handleBatchDelete(batch)} /></td>
                                            <td><CiEdit cursor='pointer' title='Edit Batch' size={18} className='ms-3' color='blue' onClick={() => handleBatchEdit(batch)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="d-flex">
                        <div className="col-xl-2 col-sm-2">
                            <div className="mb-3" >
                                <label htmlFor="exampleFormControlInput1" className="form-label text-primary">Current Batch<span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-xl-10 col-sm-10">
                            <div className="mb-3 col-5" >
                                <Select
                                    value={currentBatch ? { value: currentBatch, label: currentBatch?.BatchName } : null}
                                    onChange={(selectedOption) => setCurrentBatch(selectedOption.value)}
                                    options={associatedBatches?.map((batch) => ({ value: batch, label: batch.BatchName }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default BatchInput;
