import React, { Fragment, useState, useRef, useEffect } from "react";
import parse from 'html-react-parser';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Tab, Nav, Button, ButtonGroup, Toast, SplitButton } from "react-bootstrap";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../../http/http-config";
import { toast } from 'react-toastify';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import swal from "sweetalert";

const StartTest = () => {
    const { state } = useLocation();
    // Access the values
    const batchId = state?.batchId;
    const id = state?.testId;
    const totalTime = state?.totalTime;


    const navigate = useNavigate();
    const [testSessionId, setSessionTestId] = useState(id ?? 0)
    const [testId, setTestId] = useState(id ?? 0)
    const [questionLevels, setQLevels] = useState();
    const [questionTypes, setQTypes] = useState();
    const [comprehensions, setComprehensions] = useState();
    const [tests, setTests] = useState();
    const [testNames, setTestNames] = useState([]);
    // const [sectionNameArray, setSectionNameArray] = useState();
    const [selectedTestName, setSelectedTestName] = useState('Select Test Name');
    const [selectedTest, setSelectedTest] = useState();
    const [testSections, setTestSections] = useState();


    const [selectedSectionName, setSelectedSectionName] = useState(null);
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState();
    const [editSelectedQuestion, setEditSelectedQuestion] = useState(false);
    const [questionUpdatedOrCreated, setQuestionUpdatedOrCreated] = useState(false);

    const [questionTitle, setQuestion] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');
    const [option5, setOption5] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('Answer');
    const [explanation, setExplaination] = useState('');
    const [selectedQuestionLevel, setQLevelValue] = useState('');
    const [selectedQuestionType, setQTypeValue] = useState('');
    const [selectedComprehension, setSelectedComprehension] = useState('Comprehension');
    const [questionId, setQuestionId] = useState(0);
    const [selectedQuestion, setSelectedQuestion] = useState()
    const [actualCorrectAnswers, setActualCorrectAnswers] = useState()

    const [existedQuestionsInCurrentTest, setExistedQuestionsInCurrentTest] = useState()

    const [allComprehensionsForCurrentTest, setAllComprehenstionsForCurrentTest] = useState()
    const [sectionsArray, setSectionsArray] = useState([]);
    const [existedQuestionNumbers, setExistedQuestionNumbers] = useState(new Set());
    const [testSubmitted, setTestSubmitted] = useState(false)

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/student/getTest/' + testId).then((response) => {
            const test = response.data.test[0];
            setSelectedTest(test)
            setSelectedTestName(test.testName);
            setTestSections(JSON.parse(test?.sectionDetails));
            loadTestQuestions(test.id);
        }).catch((error) => {
            console.log(error)
        })

    }, [testId])
    const [timeUp, setTimeUp] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState(totalTime * 60);
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime > 0) {
                    const testDetails = JSON.parse(localStorage.getItem("TestDetails")) || [];
                    const testIndex = testDetails.findIndex(test => test.testId === testId && test.batchId === batchId);
                    if (testIndex !== -1) {
                        const updatedTestDetails = [...testDetails];
                        updatedTestDetails[testIndex].remainingTime = prevTime - 1;
                        localStorage.setItem("TestDetails", JSON.stringify(updatedTestDetails));
                    }
                    return prevTime - 1;
                } else {
                    setTimeUp(true);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [totalTime, testId, batchId, timeRemaining]);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;


    //time spent for perticullar question
    useEffect(() => {
        if (selectedQuestionNumber) {
            let timer;
            clearInterval(timer);
            timer = setInterval(() => {
                const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
                const currentTest = testDetails?.find((test) => test.testId == testId && test.batchId === batchId);

                if (currentTest && currentTest.remainingTime > 0) {
                    const selectedAnswer = currentTest?.correctAnswer?.find(selectedAnswer => selectedAnswer.questionNumber === selectedQuestionNumber);
                    if (selectedAnswer) {
                        selectedAnswer.timeSpent = (selectedAnswer.timeSpent ?? 0) + 1;
                        localStorage.setItem("TestDetails", JSON.stringify(testDetails));
                    }
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [selectedQuestionNumber]);






    const loadTestQuestions = (testId) => {
        axios.get(BACKEND_SERVER_URL + '/student/getTestQuestions/' + testId).then(async (res) => {
            let questions = res.data.questions;
            console.log(questions)
            if (questions) {
                setExistedQuestionsInCurrentTest(questions)
                setActualCorrectAnswers(questions.map((question) => question.correctAnswer))

                let questionNumbers = questions.map((question) => {
                    return question.questionNumber
                });
                let questionsSet = new Set(questionNumbers);// it should be questionNumbersSet
                setExistedQuestionNumbers(questionsSet);
            }
        }).catch((error) => {
            //  toast.error(error.message)
        })
    }


    useEffect(() => {
        // if (!selectedQuestionNumber) {
        //     setSelectedQuestionNumber(1);
        //     //  setSelectedSectionName(testSections[0]?.sectionName)
        // }
        console.log(existedQuestionNumbers)
        if (testSections && testSections.length > 0) {
            let questionNumber = 1;
            let sectionsArray = new Array(testSections.length);
            for (let loopIndex = 0; loopIndex < testSections.length; loopIndex++) {
                let questionBoxArray = new Array(testSections[loopIndex].totalQuestion);

                for (let innerLoopIndex = 0; innerLoopIndex < testSections[loopIndex].totalQuestion; innerLoopIndex++) {
                    questionBoxArray[innerLoopIndex] = { questionNumber, isQuestionExisted: existedQuestionNumbers.has(questionNumber), currentSectionName: testSections[loopIndex].sectionName };
                    questionNumber++;
                }
                sectionsArray[loopIndex] = questionBoxArray;
            }
            setSectionsArray(sectionsArray);
        }
        let comprehensionsForCurrentTest = comprehensions?.filter((comprehension) => comprehension.testId == testId);
        setAllComprehenstionsForCurrentTest(comprehensionsForCurrentTest)
        setInitialValue();
    }, [selectedTestName, existedQuestionNumbers]);


    const handleQuestionBoxClick = (sectionName, questionNumber) => {
        setSelectedSectionName(sectionName);
        setSelectedQuestionNumber(questionNumber);
        // setEditSelectedQuestion(false);
    }


    function setInitialValue() {
        const selectedQuestionToDisplay = existedQuestionsInCurrentTest?.find((question) => question.questionNumber == selectedQuestionNumber)
        // console.log(selectedQuestionToDisplay)
        setSelectedQuestion(selectedQuestionToDisplay)
        let currentSectionObject = sectionsArray?.find(innerArray => {
            return innerArray?.some(obj => obj.questionNumber === selectedQuestionNumber);
        })?.find(selectedObj => selectedObj.questionNumber === selectedQuestionNumber);

        if (existedQuestionNumbers?.has(selectedQuestionNumber)) {
            setSelectedSectionName(currentSectionObject?.currentSectionName)
            setQTypeValue(selectedQuestionToDisplay.questionType)
            setQuestion(selectedQuestionToDisplay?.questionTitle);
            setQuestionId(selectedQuestionToDisplay?.id)
            setOption1(selectedQuestionToDisplay?.option1)
            setOption2(selectedQuestionToDisplay?.option2)
            setOption3(selectedQuestionToDisplay?.option3)
            setOption4(selectedQuestionToDisplay?.option4)
            setOption5(selectedQuestionToDisplay?.option5)
            setExplaination(selectedQuestionToDisplay?.explanation)

            setQTypeValue(selectedQuestionToDisplay?.questionType)
            setQLevelValue(questionLevels?.find((level) => level.id == selectedQuestionToDisplay?.questionLevel)?.levelName)
            let comprehension = comprehensions?.find((comprehension) => comprehension.id == selectedQuestionToDisplay?.comprehensionId)
            if (comprehension) setSelectedComprehension(comprehension?.comprehensionName)
            else setSelectedComprehension('Comprehension')
        } else if (selectedQuestionToDisplay?.questionNumber != selectedQuestionNumber && !existedQuestionNumbers.has(selectedQuestionNumber)) {
            setSelectedSectionName(currentSectionObject?.currentSectionName)
            setQuestion('')
            setQuestionId(0)
            setOption1('')
            setOption2('')
            setOption3('')
            setOption4('')
            setOption5('')
            setExplaination('')
            setQLevelValue('Q.level')
            setQTypeValue('Q.Type')
            setSelectedComprehension('Comprehension')


        }
    }
    const [feedback, setFeedback] = useState()
    // const [correctAnswerDetailsArray, setCorrectAnswerDetailsArray] = useState()

    const [answerOfCurrentQuestion, setAnswerOfCurrentQuestion] = useState('')
    const [attemptedQuestions, setAttemptedQuestions] = useState([])
    const [markForReviewQuestionsArray, setMarkForReviewQuestionsArray] = useState([])
    const [viewedQuestionsArray, setViewedQuestionsArray] = useState([])

    const getCurrentTime = () => {
        const currentTime = new Date();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        hours = (hours < 10 ? '0' : '') + hours;
        minutes = (minutes < 10 ? '0' : '') + minutes;
        seconds = (seconds < 10 ? '0' : '') + seconds;
        return `${hours}:${minutes}:${seconds}`;
    }
    useEffect(() => {
        if (existedQuestionsInCurrentTest && existedQuestionsInCurrentTest.length > 0) {
            let intialQuestionResponseObj = new Array(existedQuestionsInCurrentTest.length).fill(null).map((_, index) => ({
                questionNumber: index + 1,
                answer: '',
                markForReview: false,
                isQuestionViewed: false,
                timeSpent: 0
            }));
            // setCorrectAnswerDetailsArray(intialQuestionResponseObj);

            const testDetails = JSON.parse(localStorage.getItem("TestDetails"));


            if (testDetails?.length > 0) {
                const testToBeContinued = testDetails.find((test) => test.testId === testId && test.batchId === batchId);

                setTimeRemaining(testToBeContinued?.remainingTime)

                if (testToBeContinued) {
                    setSelectedQuestionNumber(testToBeContinued.lastQuestion);
                    setAttemptedQuestions(testToBeContinued.correctAnswer?.filter((question) => question.answer)?.map((question) => question.questionNumber));
                } else {
                    testDetails.push({
                        isTestSubmitted: false,
                        testId: testId,
                        batchId: batchId,
                        startTime: getCurrentTime(),
                        lastQuestion: 1,
                        remainingTime: totalTime * 60,
                        correctAnswer: intialQuestionResponseObj
                    });

                    localStorage.setItem("TestDetails", JSON.stringify(testDetails));
                    setSelectedQuestionNumber(1);
                }
            } else {
                const newTestDetails = [{
                    isTestSubmitted: false,
                    testId: testId,
                    batchId: batchId,
                    startTime: getCurrentTime(),
                    lastQuestion: 1,
                    remainingTime: totalTime * 60,
                    correctAnswer: intialQuestionResponseObj,
                }];

                localStorage.setItem("TestDetails", JSON.stringify(newTestDetails));
                setSelectedQuestionNumber(1);
                setAttemptedQuestions([]);
            }
        }
    }, [existedQuestionsInCurrentTest]);




    useEffect(() => {
        if (selectedQuestionNumber) {
            setInitialValue();
            const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
            if (testDetails) {
                const currentTest = testDetails?.find((test) => test.testId == testId && test.batchId === batchId);
                if (selectedQuestionNumber != existedQuestionsInCurrentTest.length + 1) {
                    currentTest.lastQuestion = selectedQuestionNumber;
                }
                const correctAnswersDetailsInLocalStorage = currentTest?.correctAnswer
                let question = correctAnswersDetailsInLocalStorage?.find((question) => question.questionNumber == selectedQuestionNumber)
                setAnswerOfCurrentQuestion(question?.answer)
                console.log(selectedQuestionNumber)
                console.log(question)
                if (question) {
                    question.markForReview = false;
                    question.isQuestionViewed = true;
                }
                localStorage.setItem("TestDetails", JSON.stringify(testDetails));

                setAttemptedQuestions(correctAnswersDetailsInLocalStorage?.filter((question) => question.answer)?.map((question) => question.questionNumber));
                setMarkForReviewQuestionsArray(correctAnswersDetailsInLocalStorage?.filter((question) => question.markForReview == true)?.map((question) => question.questionNumber))
                setViewedQuestionsArray(correctAnswersDetailsInLocalStorage?.filter((question) => question.isQuestionViewed == true)?.map((question) => question.questionNumber))

                document.querySelectorAll('input[name="mcq"] ,input[name="mark"]').forEach((input) => {
                    input.checked = false;
                });
            }
        }
    }, [selectedQuestionNumber, existedQuestionsInCurrentTest])
    useEffect(() => {
        if (testSubmitted) {
            navigate('/student/test-report', { state: { testId, batchId } });
        }
    }, [testSubmitted]);

    //hanlde fullscreen
    const handle = useFullScreenHandle();
    useEffect(() => {
        handle.enter();
        const preventRightClick = (event) => {
            if (event.button === 2) {
                event.preventDefault();
            }
        };

        document.addEventListener('contextmenu', preventRightClick);

        return () => {
            document.removeEventListener('contextmenu', preventRightClick);
        };
    }, []);
    console.log(testSubmitted)
    useEffect(() => {
        const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
        const indexOfCurrentTest = testDetails?.findIndex((test) => test.testId == testId && test.batchId === batchId);
        if (indexOfCurrentTest>=0) {
            console.log('enter')
            swal({
                title: "Are you sure?",
                text: "Do you really want to leave the test for now!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false,
                buttons: ["No, stay on the test", "Yes, leave the test"],
                confirmButtonText: "Yes, leave the test"
            }).then((willDelete) => {
                if (willDelete) {
                    navigate('/student/test-series')
                } else {
                    handle.enter();
                }
            })
        }
    }, [handle.active, testSubmitted])
  
    //seelct handler
    const selectAnswerHandler = (checked, value) => {
        const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
        const currentTest = testDetails?.find((test) => test.testId == testId && test.batchId === batchId);
        if (selectedQuestionType == 'MSQ') {
            const selectedAnswer = currentTest?.correctAnswer?.find(selectedAnswer => selectedAnswer.questionNumber === selectedQuestionNumber);
            if (checked && !selectedAnswer.answer.includes(value)) {
                selectedAnswer.answer = selectedAnswer.answer + value;
                setAnswerOfCurrentQuestion(selectedAnswer.answer)
                localStorage.setItem("TestDetails", JSON.stringify(testDetails));
            }
            if (!checked && selectedAnswer.answer.includes(value)) {
                selectedAnswer.answer = selectedAnswer.answer.replaceAll(value, '');
                setAnswerOfCurrentQuestion(selectedAnswer.answer)
                localStorage.setItem("TestDetails", JSON.stringify(testDetails));
            }
        } else {
            setAnswerOfCurrentQuestion(value)
            const selectedAnswer = currentTest?.correctAnswer?.find(selectedAnswer => selectedAnswer.questionNumber === selectedQuestionNumber);
            selectedAnswer.answer = value;
            localStorage.setItem("TestDetails", JSON.stringify(testDetails));
        }
    }
    //get time spent
    const getTimeSpent = (remainingTimeInSeconds, totalTimeInSeconds) => {
        console.log(remainingTimeInSeconds, totalTimeInSeconds)
        // Check if inputs are valid numbers
        if (isNaN(remainingTimeInSeconds) || isNaN(totalTimeInSeconds)) {
            console.error('Invalid input. Please provide valid numbers.');
            console.log('Invalid inputs provided. Returning default time value: 00:00:00');
            return '00:00:00'; // or any other appropriate default value
        }

        // Ensure remaining time is not greater than total time
        if (remainingTimeInSeconds > totalTimeInSeconds) {
            console.error('Invalid input. Remaining time cannot be greater than total time.');
            console.log('Remaining time is greater than total time. Returning default time value: 00:00:00');
            return '00:00:00'; // or any other appropriate default value
        }

        let spentTimeInSeconds = totalTimeInSeconds - remainingTimeInSeconds;
        var hours = Math.floor(spentTimeInSeconds / 3600);
        var minutes = Math.floor((spentTimeInSeconds % 3600) / 60);
        var seconds = spentTimeInSeconds % 60;

        // Adding leading zeros if necessary
        var formattedHours = hours < 10 ? '0' + hours : hours;
        var formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        var formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

        var formattedTime = formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
        console.log('Formatted time:', formattedTime);
        return formattedTime;
    }
    console.log(existedQuestionsInCurrentTest)
    const submitTestHandler = () => {
        const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
        const currentTest = testDetails?.find((test) => test.testId == testId && test.batchId === batchId);
        console.log('current test response', currentTest)
        const correctAnswersDetailsInLocalStorage = currentTest?.correctAnswer
        let correctAnswersDetailsInLocalStorageCopy = correctAnswersDetailsInLocalStorage;
        let correctQuestions = 0;
        let wrongQuestions = 0;
        let correctMarks = 0;
        let negativeMarks = 0;
        let finalMarks;
        let finalPercentage;
        for (let i = 0; i < correctAnswersDetailsInLocalStorage?.length; i++) {
            const { isQuestionViewed, markForReview, ...remainingProperties } = correctAnswersDetailsInLocalStorage[i];
            correctAnswersDetailsInLocalStorageCopy[i] = { ...remainingProperties, correctAnswer: existedQuestionsInCurrentTest[i].correctAnswer, questionMarks: existedQuestionsInCurrentTest[i].questionMarks, questionId: existedQuestionsInCurrentTest[i].id, questionNumber: existedQuestionsInCurrentTest[i].questionNumber, sectionName: existedQuestionsInCurrentTest[i].sectionId,/* batchId: batchId, testId: testId, studentId: JSON.parse(localStorage.getItem("userDetails"))?.studentId */ };

            if (correctAnswersDetailsInLocalStorage[i]?.answer == existedQuestionsInCurrentTest[i].correctAnswer) {
                correctQuestions = correctQuestions + 1;
                correctMarks = correctMarks + existedQuestionsInCurrentTest[i].questionMarks
            } else {
                if (correctAnswersDetailsInLocalStorage[i]?.answer != '') {
                    wrongQuestions = wrongQuestions + 1;
                    negativeMarks = negativeMarks + existedQuestionsInCurrentTest[i].negativeMarks
                }

            }
        }
        finalMarks = correctMarks - negativeMarks;
        finalPercentage = (finalMarks * 100) / selectedTest.totalMarks

        let studentResponseObj = {
            studentId: JSON.parse(localStorage.getItem("userDetails"))?.studentId,
            testId,
            selectedTestName,
            correctQuestions,
            correctMarks,
            wrongQuestions,
            negativeMarks,
            finalMarks,
            finalPercentage,
            lastQuestion: currentTest.lastQuestion,
            rank: 1,
            startTime: currentTest.startTime,
            endTime: getCurrentTime(),
            timeSpent: getTimeSpent(currentTest.remainingTime, totalTime * 60),
            batchId,
            totalSections: testSections.length,
            feedback,
            adminId: JSON.parse(localStorage.getItem("userDetails"))?.adminId,
            responseArrayOfQuestionObjects: correctAnswersDetailsInLocalStorageCopy
        }
        console.log(studentResponseObj)
        axios.post(BACKEND_SERVER_URL + '/student/saveTestResponse', studentResponseObj).then((response) => {
            if (response && response.data.statusCode == 201) {
                toast.success(response.data.message)
                const indexOfCurrentTest = testDetails?.findIndex((test) => test.testId == testId && test.batchId === batchId);

                testDetails.splice(indexOfCurrentTest, 1)
                localStorage.setItem("TestDetails", JSON.stringify(testDetails));
                setTestSubmitted(true)
                // testSubmitted ? navigate('/student/test-report', { state: { testId, batchId } }) : console.log('Test not submitted yet');

            }
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
        console.log(correctAnswersDetailsInLocalStorageCopy)
    }
    console.log(markForReviewQuestionsArray)
    const markForReviewHandler = (checked) => {
        console.log(checked)
        const testDetails = JSON.parse(localStorage.getItem("TestDetails"));
        const currentTest = testDetails?.find((test) => test.testId == testId && test.batchId === batchId);
        const selectedAnswer = currentTest?.correctAnswer?.find(selectedAnswer => selectedAnswer.questionNumber === selectedQuestionNumber);
        if (checked) {
            selectedAnswer.markForReview = true;
            const updatedMarkForReviewQuestions = [...new Set((markForReviewQuestionsArray || []).concat(selectedQuestionNumber))];
            setMarkForReviewQuestionsArray(updatedMarkForReviewQuestions);
        } else {
            const index = markForReviewQuestionsArray?.indexOf(selectedQuestionNumber)
            if (index !== -1) {
                markForReviewQuestionsArray.splice(index, 1);
                setMarkForReviewQuestionsArray(markForReviewQuestionsArray)
            }
            selectedAnswer.markForReview = false;
        }
        localStorage.setItem("TestDetails", JSON.stringify(testDetails));
    }
    //console.log(attemptedQuestions)
    const getBorderColor = (questionNumber) => {
        let border = selectedQuestionNumber == questionNumber ? '2px solid' : '1px solid';
        if (attemptedQuestions?.includes(questionNumber) && markForReviewQuestionsArray?.includes(questionNumber)) {
            return border + ' violet'
        } else if (markForReviewQuestionsArray?.includes(questionNumber)) {
            return border + ' yellow'
        } else if (attemptedQuestions?.includes(questionNumber)) {
            return border + ' green'
        } else if (viewedQuestionsArray?.includes(questionNumber)) {
            return border + ' red'
        } else {
            return border + ' grey'

        }
    }

    return (
        <FullScreen handle={handle}>
            <Fragment >
                <div className="card p-0 rounded-0 mb-0 ps-0">
                    <div className="row gx-0 " >
                        {!timeUp ? <div className="col-xl-2 col-xxl-2 col-lg-2" style={{ pointerEvents: timeUp ? 'none' : 'auto' }} >
                            <div className="card-header mb-2 p-3  mt-0 bg-light"><h4 className="mb-0">Test Sections</h4></div>
                            <div className="email-left-box dlab-scroll pt-2" >
                                <div className="col-sm-12 mt-0">
                                    {
                                        testSections?.map((section, sectionIndex) => (
                                            <div className="col-12 " key={sectionIndex}>
                                                <p className="text-primary mt-1 mb-0 ">{section.sectionName} </p> <hr className="mt-0" />
                                                <div className="row" key={sectionIndex}>
                                                    {
                                                        sectionsArray[sectionIndex]?.map((questionBoxData) => (
                                                            <div onClick={() => { handleQuestionBoxClick(section.sectionName, questionBoxData.questionNumber); }} className="col-1 p-1 m-1 ms-3  rounded-circle text-center cursor-pointer"
                                                                style={{
                                                                    backgroundColor: questionBoxData.isQuestionExisted ? 'white' : 'white', width: '26px', height: '26px', fontSize: '11px',
                                                                    color: questionBoxData.isQuestionExisted ? 'black' : 'black',
                                                                    border: getBorderColor(questionBoxData.questionNumber)
                                                                }}
                                                            >
                                                                {questionBoxData.questionNumber}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div> : null}
                        <div className={`${!timeUp ? 'col-xl-10 col-xxl-10 col-lg-10' : 'col-12'}`}>
                            <div className="email-right-box">
                                <div className="card-header pt-3 pb-2 bg-light">
                                    <h4 className="mb-0  ">Test Name: <span className="text-primary ">{selectedTest?.testName}</span></h4>
                                    <div className="col-4 d-flex justify-content-end ">
                                        <h4 className="text-danger pe-0 me-2" style={{ minWidth: "150px" }}>
                                            Time Remaining: {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                                        </h4>
                                    </div>
                                </div>
                                {!timeUp ?
                                    <div className="card-header pt-2 pb-2 d-flex">
                                        <div className="col-12">
                                            {
                                                !timeUp || selectedQuestionNumber != existedQuestionsInCurrentTest.length + 1 ? (
                                                    <div className="  d-flex justify-content-between ">
                                                        <h5 className="card-title">Question Type: {selectedQuestionType} </h5>
                                                        <h5 className="card-title">Marks: {selectedQuestion?.questionMarks} </h5>
                                                        <h5 className="card-title">Section: {selectedSectionName} </h5>
                                                    </div>
                                                ) : null
                                            }
                                        </div>


                                    </div> : null
                                }

                                <div className="col-xl-12 col-xxl-12 col-lg-12 ">
                                    <div className="card h-auto">
                                        {(selectedQuestionNumber && existedQuestionsInCurrentTest) && (selectedQuestionNumber != existedQuestionsInCurrentTest.length + 1 && !timeUp) ?
                                            <div className="card-body pt-1 ">
                                                <div className="teacher-deatails " style={{ color: 'black' }}>
                                                    {/* <p className="heading">Section: {" " + selectedSectionName} </p> */}
                                                    <div className="d-flex">
                                                        <p className="heading text-dark">Question: {" " + selectedQuestionNumber} </p>
                                                        <div className="form-check col-5 d-flex justify-content-end ">
                                                            <label
                                                                className="form-check-label text-info me-4"
                                                                htmlFor="markForReviewCheckbox"

                                                            >Mark for Review
                                                            </label>
                                                            <input
                                                                name="mark"
                                                                type='checkbox'
                                                                className="form-check-input cursor-pointer"
                                                                onClick={(e) => markForReviewHandler(e.target.checked)}
                                                                id="markForReviewCheckbox"
                                                            /*  checked={`${ markForReviewQuestionsArray.includes(selectedQuestionNumber)}`} */
                                                            />


                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div> {questionTitle ? parse(questionTitle) : null}</div>
                                                    </div>
                                                    <div>
                                                        {selectedQuestionType != 'NVQ' ? <>
                                                            {option1 && <div className="d-flex">
                                                                <div className="form-check ">
                                                                    <input
                                                                        name="mcq"
                                                                        value='A'
                                                                        type={`${selectedQuestionType == 'MSQ' ? 'checkbox' : 'radio'}`}
                                                                        className="form-check-input cursor-pointer"
                                                                        onClick={(e) => selectAnswerHandler(e.target.checked, e.target.value)}
                                                                        id="checkbox1"
                                                                        checked={answerOfCurrentQuestion?.includes("A")}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="checkbox1"
                                                                    />
                                                                </div>
                                                                <div> <label className="ms-2 cursor-pointer" htmlFor="checkbox1" >{option1 ? parse(option1) : null}</label> </div>
                                                            </div>
                                                            }
                                                            {option2 && <div className="d-flex">
                                                                <div className="form-check ">
                                                                    <input
                                                                        type={`${selectedQuestionType == 'MSQ' ? 'checkbox' : 'radio'}`}
                                                                        name="mcq"
                                                                        value='B'
                                                                        className="form-check-input cursor-pointer"
                                                                        onClick={(e) => selectAnswerHandler(e.target.checked, e.target.value)}
                                                                        id="checkbox2"
                                                                        checked={answerOfCurrentQuestion?.includes("B")}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="checkbox2"
                                                                    />
                                                                </div>

                                                                <div> <label className="ms-2 cursor-pointer" htmlFor="checkbox2">{option2 ? parse(option2) : null}</label> </div>
                                                            </div>}
                                                            {option3 && <div className="d-flex">
                                                                <div className="form-check custom-checkbox">
                                                                    <input
                                                                        name="mcq"
                                                                        type={`${selectedQuestionType == 'MSQ' ? 'checkbox' : 'radio'}`}
                                                                        className="form-check-input cursor-pointer"
                                                                        value='C'
                                                                        onClick={(e) => selectAnswerHandler(e.target.checked, e.target.value)}
                                                                        id="checkbox3"
                                                                        checked={answerOfCurrentQuestion?.includes("C")}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="checkbox3"
                                                                    />
                                                                </div>

                                                                <div> <label className="ms-2 cursor-pointer" htmlFor="checkbox3">{option3 ? parse(option3) : null}</label></div>
                                                            </div>}
                                                            {option4 && <div className="d-flex">
                                                                <div className="form-check custom-checkbox">
                                                                    <input
                                                                        name="mcq"
                                                                        value='D'
                                                                        type={`${selectedQuestionType == 'MSQ' ? 'checkbox' : 'radio'}`}
                                                                        className="form-check-input cursor-pointer"
                                                                        onClick={(e) => selectAnswerHandler(e.target.checked, e.target.value)}
                                                                        id="checkbox4"
                                                                        checked={answerOfCurrentQuestion?.includes("D")}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="checkbox4"
                                                                    />
                                                                </div>
                                                                <div> <label className="ms-2 cursor-pointer" htmlFor="checkbox4">{option4 ? parse(option4) : null}</label> </div>
                                                            </div>}
                                                            {option5 &&
                                                                <div className="d-flex">
                                                                    <div className="form-check custom-checkbox">
                                                                        <input
                                                                            name="mcq"
                                                                            value='E'
                                                                            type={`${selectedQuestionType == 'MSQ' ? 'checkbox' : 'radio'}`}
                                                                            className="form-check-input cursor-pointer"
                                                                            onClick={(e) => selectAnswerHandler(e.target.checked, e.target.value)}
                                                                            id="checkbox5"
                                                                            checked={answerOfCurrentQuestion?.includes("E")}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="checkbox5"
                                                                        />
                                                                    </div>
                                                                    <div> <label className="ms-2 cursor-pointer" htmlFor="checkbox5">{option5 ? parse(option5) : null}</label> </div>
                                                                </div>
                                                            }
                                                        </> :
                                                            <div className="col-4 mt-5">
                                                                <input type="number" className="form-control" placeholder="Correct Answer"
                                                                    value={answerOfCurrentQuestion}
                                                                    onChange={(e) => selectAnswerHandler(e.target.checked || false, e.target.value)} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="comment-respond" id="respond">
                                                <h4
                                                    className="comment-reply-title text-primary m-3 mb-1"
                                                    id="reply-title"
                                                >
                                                    Leave Feedback{" "}
                                                </h4>
                                                <div className="row">

                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3 ms-2 me-2">
                                                            <label
                                                                htmlFor="comment"
                                                                className="text-black font-w600"
                                                            >
                                                            </label>
                                                            <textarea
                                                                onChange={(e) => setFeedback(e.target.value)}
                                                                rows={8}
                                                                className="form-control"
                                                                name="comment"
                                                                placeholder="Comment"
                                                                id="comment"
                                                                defaultValue={""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className=" d-flex justify-content-between  px-3 py-2">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                disabled={selectedQuestionNumber === 1 || timeUp}
                                                onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                            >
                                                <SlArrowLeft className="" size={10} /> Previous
                                            </Button>
                                            <div>
                                                {!timeUp && selectedQuestionNumber !== selectedTest?.totalQuestions + 1 && (
                                                    <>
                                                        <Button variant="success" size="sm" className="ms-2" onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)}>
                                                            Save & Next <SlArrowRight size={10} />
                                                        </Button>
                                                    </>
                                                )}

                                                {(timeUp || selectedQuestionNumber === selectedTest?.totalQuestions + 1) && (
                                                    <Button variant="success" size="sm" className="ms-2" onClick={submitTestHandler}>
                                                        Submit Test
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-12 fixed-bottom bg-light p-3 "></div>
                </div>
            </Fragment >
        </FullScreen>

    );
};

export default StartTest;
