import React, { useState } from 'react';

const Input = ({ inputValue, handleChangeCallBack, labelName, inputType, inputName, divSizes }) => {
    //console.log(inputValue)
    const handleChange = (e) => {
        //   console.log(e.target.value, e.target.name)
        handleChangeCallBack(e.target.name, e.target.value)
    }
    const checkSpecialCase = () => {
        if (inputName == 'courseId' || inputName == 'testId' || inputName == 'packageId' || inputName == 'sectionId') return true
    }

    return (
        <>
            <div className={`col-sm-${divSizes[0]} pt-2`}>
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary ">{labelName}<span className="required">*</span></label>
                </div>
            </div>
            <div className={`col-sm-${divSizes[1]}`}>
                <div className="mb-3 " >
                    {
                        inputType != "textarea" ? <input disabled={checkSpecialCase() ? true : false} type={inputType} id={labelName} className={`form-control ${checkSpecialCase() ? 'bg-light' : ''}`}
                            name={inputName} value={inputValue} onChange={handleChange} />
                            :
                            <textarea type={inputType} id={labelName} className='form-control ' name={inputName} value={inputValue} onChange={handleChange} />
                    }
                </div>

            </div>
        </>
    );
}

export default Input;
