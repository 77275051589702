import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download files/course_sample_file.docx'
import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput'
import BatchInput from './BatchInput';
import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from './CourseHelper';
import DateInput from '../Common-Components/DateInput';
import './Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';

let initialFormData = {
    examType: ' Select Exam Type',
    sNo: 0,
    title: '',
    urlTitle: '',
    price: 0,
    seatBookingPrice: 0,
    discount: 0,
    appDiscount: 0,
    type: '',
    topicCount: 0,
    duration: 0,
    faculty: 'Select Faculty',
    subscriptionCount: 0,
    currentBatchID: 0,
    description: '',
    imageURL: '',
    pdfURL: '',
    syllabusPDFURL: '',
    topics: '',
    tags: '',
    demoVideoLink: '',
    metaTitle: '',
    metaDescription: '',
    courseId: '',
    startDate: new Date(),
    admissionStartDate: new Date(),
}


const CourseModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);    //new course
    const [courseData, setCourseData] = useState('');
    const [courseId, setCourseId] = useState('');
    const [modalName, setModal] = useState("")
    const [updatingCourseId, setUpdatingCourseId] = useState();
    const [examTypesArray, setExamTypesArray] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const [facultyNamesArray, setFacultyNames] = useState([])
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    //console.log(updatingCourseId)
    useImperativeHandle(ref, () => ({
        openModal(CourseID) {
            getTypes().then((res) => {
                setTypesArray([...new Set(res.map((type) => type.courseType))])
            })
            getExamTypes().then((res) => {
                setExamTypesArray(res.map((type) => type.exambodyvalue));
            })
            getFaculties().then((res) => {
                setFacultyNames(res.map((type) => type.facultyName));
            })
            if (CourseID) {
                setUpdatingCourseId(CourseID)
                setCourseId(CourseID)
                fetchCourseData(CourseID).then((course) => {
                    formateCourseData(course).then(formatedData => {
                        if (formatedData.title) {
                            setFormData(formatedData)
                            setUpdatingDataReady(true)
                            setModalBox(true);
                        }
                    })

                })
            } else {
                setFormData(initialFormData)
                let courseId = generateUniqueID();
                setCourseId(courseId);
                handleChange('courseId', courseId)//property name and value   
                setModalBox(true);
            }


        }
    }));

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmitCourse = () => {
        let errorMessgae = validateCourseData(formData)
  
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        }

        axios.post(BACKEND_SERVER_URL + (updatingCourseId ? `/updateCourse/${updatingCourseId}` : '/createCourse'), formData).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setCourseLoading(!props.loadCourses)
                setModalBox(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            {((updatingCourseId && updatingDataReady && modalBox) || (!updatingCourseId && modalBox)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingCourseId ? 'Update Course' : 'Create Course'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Exam Type" selectedValue={formData?.examType} inputName='examType' dropdownValuesArray={examTypesArray} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="S. No." inputType="number" inputValue={formData?.sNo} inputName='sNo' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="URL Title" inputValue={formData?.urlTitle} inputName='urlTitle' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Price" inputType="number" inputValue={formData?.price} inputName='price' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Seat Booking Price" inputType="number" inputValue={formData?.seatBookingPrice} inputName='seatBookingPrice' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">

                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Discount" inputType="number" inputValue={formData?.discount} inputName='discount' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="App Discount" inputType="number" inputValue={formData?.appDiscount} inputName='appDiscount' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Type" selectedValue={formData?.type} inputName='type' dropdownValuesArray={typesArray} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Course ID" inputType="text" inputValue={formData?.courseId || courseId} inputName='courseId' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Topic Count" inputValue={formData?.topicCount} inputName='topicCount' inputType="number" divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Duration" inputValue={formData?.duration} inputName='duration' inputType="number" divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Faculty" selectedValue={formData?.faculty} inputName='faculty' dropdownValuesArray={facultyNamesArray} />
                                </div>

                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Subscription Count" inputType="number" inputValue={formData?.subscriptionCount} inputName='subscriptionCount' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Start Date" selected={formData?.startDate} handleChangeCallBack={handleChange} inputName='startDate' />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Admission Start Date" selected={formData?.admissionStartDate} handleChangeCallBack={handleChange} inputName='admissionStartDate' />
                                </div>
                            </div>
                            <div className="col-12 d-flex  justify-content-between mt-3">
                                <BatchInput handleChangeCallBack={handleChange} labelName="Add Batch" courseId={courseId} inputValue={formData?.currentBatchID} inputName='currentBatchID' moduleName="coursePage" updating={updatingCourseId} />
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="PDF URL" inputType="text" uploadedFileURL={formData?.pdfURL} inputName='pdfURL' accept="pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Syllabus PDF URL" inputType="text" uploadedFileURL={formData?.syllabusPDFURL} inputName='syllabusPDFURL' accept="pdf" />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Topics" inputType="text" inputValue={formData?.topics} inputName='topics' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Tags" inputType="text" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Demo Video Link" inputType="text" inputValue={formData?.demoVideoLink} inputName='demoVideoLink' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Title" inputType="text" inputValue={formData?.metaTitle} inputName='metaTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Description" inputType="textarea" inputValue={formData?.metaDescription} inputName='metaDescription' divSizes={[2, 10]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitCourse}>{updatingCourseId ? 'Update Course' : 'Create Course'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default CourseModal;