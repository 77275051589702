import React from 'react';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
const DateInput = ({ selected, handleChangeCallBack, labelName, inputName }) => {
    return (
        <>
            <div className="col-xl-4 col-sm-4 pt-2">
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary">{labelName}<span className="required">*</span></label>
                </div>
            </div>
            <div className="col-xl-8 col-sm-8">
                <div className="mb-3">
                    <DatePicker
                        className="form-control full-width full-width-container .react-datepicker-wrapper"
                        selected={new Date(selected)}                        
                        showTimeSelect                        
                        timeFormat="HH:mm:ss.SSS"
                        timeIntervals={15}
                        timeCaption="Time"
                        onChange={(date) => handleChangeCallBack(inputName, format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"))}
                        dateFormat="dd/MM/yyyy"
                        yearDropdownItemNumber={20}
                        showYearDropdown
                        scrollableYearDropdown
                        todayButton="Today"
                    />                    
                </div>

            </div>
        </>
    );
}

export default DateInput;
