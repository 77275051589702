import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import insightDelhiLogo from '../new images/logoInsightDelhi.png'
import smallLogo from '../new images/smallLogoInsightDelhi.png'

import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';
import {
	formatError,
	login,
	runLogoutTimer,
	saveTokenInLocalStorage,
} from '../../services/AuthService';
import { loginFailedAction } from '../../store/actions/AuthActions'
// image

import BgImage from "../../images/bg1.png";
import logo from "../../images/logo-full.png";
import logolight from "../../images/logo-white.png";
import pol from "../../images/pol.jpg";
import { Form } from 'react-bootstrap';
import { useRole } from '../../context/UserRoleContext';


function Login(props) {
	const [userRole, setUserRole] = useRole();
	console.log("login page", userRole)

	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [role, setRole] = useState();
	const [email, setEmail] = useState(`${userRole == 2 ? 'mohdafzal330@gmail.com' : 'sbaliyanindia@gmail.com'}`);
	const [password, setPassword] = useState(`${userRole == 2 ? '12345' : 'kamal12@'}`);

	useEffect(() => {
		if (role == 'Admin') {
			setUserRole(1)
			setEmail('7503663732')
			setPassword('7503663732')
		} else if (role == 'Student') {
			setUserRole(2)
			setEmail('mohdafzal330@gmail.com')
			setPassword('12345')
		}
	}, [role])

	const dispatch = useDispatch();
	const navigate = useNavigate()
	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));

		dispatch(loginAction(email, password, userRole, navigate));
	}

	const element = document.querySelector("body");
	let dataTheme = element.getAttribute("data-theme-version");

	return (
		<div className="container h-100">
			<div className="row h-100 align-items-center justify-contain-center">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body p-0">
							<div className="m-0 d-flex justify-content-center">
								<div className="col-xl-6 col-md-6 " >
									<div className="sign-in-your px-2">
										<div className="d-flex justify-content-center mb-0 pb-0">
											<img src={insightDelhiLogo} alt="logo " className='mb-0 pb-0' />
										</div>
										<form onSubmit={onLogin}>
											<div className="mb-3">
												<input type="text" className="form-control"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													placeholder="Type Your Email Address"
												/>

												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
											</div>
											<div className="mb-3">
												<label className="mb-1"><strong>Password</strong></label>
												<input
													type="password"
													className="form-control"
													value={password}
													placeholder="Type Your Password"
													onChange={(e) =>
														setPassword(e.target.value)
													}
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>
											{/* <div className="row d-flex justify-content-between mt-4 mb-2">
												<div className="mb-3">
													<div className="form-check custom-checkbox ms-1">
														<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
														<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
													</div>
												</div>
												<div className="mb-3">
													<Link to="/page-register">Sign up</Link>
												</div>
											</div> */}
											<div className="text-center">
												<button type="submit" className="btn btn-primary btn-block">Sign Me In</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
