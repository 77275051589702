import React from 'react';

const ProdutsDetailsTabsAtTop = ({ setChecked, totalItems, totalActiveItems, totalInactiveItems, totalArchivedItems }) => {
   // console.log(setChecked, totalItems, totalActiveItems, totalInactiveItems, totalArchivedItems)
   
    return (
        <>
            <div className='page-title pt-1 mb-1 pb-2' style={{ zIndex: "0" }}>
                <div className="container-fluid p-1 pb-0">
                    <div className="row">
                        <div className="col-12 col-sm-6 d-sm-flex ">
                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                <button
                                    onClick={() => setChecked(totalItems)}
                                    type="button"
                                    className="btn btn-primary btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                >
                                    Total: {totalItems?.length}
                                </button>
                            </div>
                            <div className="col-12 col-sm-6  ">
                                <button
                                    onClick={() => setChecked(totalActiveItems)}
                                    type="button"
                                    className="btn btn-success btn-sm btn-block m-auto ps-0 pe-3 w-75"
                                >
                                    Active: {totalActiveItems?.length}
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 d-sm-flex mt-3 mt-sm-0 ">
                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                                <button
                                    onClick={() => setChecked(totalInactiveItems)}
                                    type="button"
                                    className="btn btn-warning btn-block btn-sm  m-auto ps-0 w-75"
                                >
                                    Inactive: {totalInactiveItems?.length}
                                </button>
                            </div>
                            <div className="col-12 col-sm-6 ">
                                <button
                                    onClick={() => setChecked(totalArchivedItems)}
                                    type="button"
                                    className="btn btn-danger btn-block btn-sm  m-auto ps-0 w-75"
                                >
                                    Archived: {totalArchivedItems?.length}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProdutsDetailsTabsAtTop;
