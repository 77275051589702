import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download files/course_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput'
import BatchInput from '../Course/BatchInput';
import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from '../Course/CourseHelper';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';
import { fetchTestData, formateTestData } from './TestHelper';

let initialFormData = {
    examType: ' Select Exam Type',
    sNo: '',
    title: '',
    titleHeading: '',
    price: 0,
    seatBookingPrice: 0,
    discount: 0,
    appDiscount: 0,
    type: '',
    topicCount: 0,
    duration: 0,
    faculty: 'Select Faculty',
    subscriptionCount: 0,
    currentBatchID: 0,
    description: '',
    imageURL: '',
    pdfURL: '',
    syllabusPDFURL: '',
    topics: '',
    tags: '',
    demoVideoLink: '',
    metaTitle: '',
    metaDescription: '',
    testId: '',
    startDate: new Date(),
    admissionStartDate: new Date(),
    mcqRules:'',
    quizCount:0,
    subjectiveRules:''
}
const TestModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);    
    const [courseData, setCourseData] = useState('');
    const [testId, setTestId] = useState(''); 
    const [updatingTestId, setUpdatingTestId] = useState(0);
    const [examTypesArray, setExamTypesArray] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const [facultyNamesArray, setFacultyNames] = useState([])
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    //console.log(updatingCourseId)
    useImperativeHandle(ref, () => ({
        openModal(CourseID) {
            console.log(CourseID) 

            getTypes().then((res) => {
                setTypesArray([...new Set(res.map((type) => type.courseType))])
            })
            getExamTypes().then((res) => {
                setExamTypesArray(res.map((type) => type.exambodyvalue));
            })
            getFaculties().then((res) => {
                setFacultyNames(res.map((type) => type.facultyName));
            })


            if (CourseID) {
                setUpdatingTestId(CourseID)
                setTestId(CourseID)
                fetchTestData(CourseID).then((test) => {
                    console.log(test)
                    formateTestData(test).then(formatedData => {
                        if (formatedData.title) {
                            console.log(formatedData)
                            setFormData(formatedData)
                            setUpdatingDataReady(true)
                            setModalBox(true);
                        }
                    })

                })
            } else {
                setFormData(initialFormData)
                let courseId = generateUniqueID();
                setTestId(courseId);
                handleChange('courseId', courseId)//property name and value   
                setModalBox(true);
            }


        }
    }));

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
    console.log(formData)
    const handleSubmitTest = () => {
        /* let errorMessgae = validateCourseData(formData)
        console.log(errorMessgae)
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        } */
        axios.post(BACKEND_SERVER_URL + (updatingTestId ? `/editTest/${updatingTestId}` : '/createTest'), formData).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setCourseLoading(!props.loadCourses)
                setModalBox(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            {((updatingTestId && updatingDataReady && modalBox) || (!updatingTestId && modalBox)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingTestId ? 'Update Test' : 'Create Test'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Exam Type" selectedValue={formData?.examType} inputName='examType' dropdownValuesArray={examTypesArray} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="S. No." inputType="number" inputValue={formData?.sNo} inputName='sNo' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title Heading" inputValue={formData?.titleHeading} inputName='titleHeading' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Price" inputType="number" inputValue={formData?.price} inputName='price' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Seat Booking Price" inputType="number" inputValue={formData?.seatBookingPrice} inputName='seatBookingPrice' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Discount" inputType="number" inputValue={formData?.discount} inputName='discount' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="App Discount" inputType="number" inputValue={formData?.appDiscount} inputName='appDiscount' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Type" selectedValue={formData?.type} inputName='type' dropdownValuesArray={typesArray} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Test Series ID" inputType="text" inputValue={formData?.testId || testId} inputName='testId' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Quiz Count" inputValue={formData?.quizCount} inputName='quizCount' inputType="number" divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Duration" inputValue={formData?.duration} inputName='duration' inputType="number" divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Faculty" selectedValue={formData?.faculty} inputName='faculty' dropdownValuesArray={facultyNamesArray} />
                                </div>

                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Subscription Count" inputType="number" inputValue={formData?.subscriptionCount} inputName='subscriptionCount' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Start Date" selected={formData?.startDate} handleChangeCallBack={handleChange} inputName='startDate' />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Admission Start Date" selected={formData?.admissionStartDate} handleChangeCallBack={handleChange} inputName='admissionStartDate' />
                                </div>
                            </div>
                            <div className="col-12 d-flex  justify-content-between mt-3">
                                <BatchInput handleChangeCallBack={handleChange} labelName="Add Batch" courseId={testId} inputValue={formData?.currentBatchID} inputName='currentBatchID' moduleName="testPage" updating={updatingTestId}/>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="MCQ Rules" inputValue={formData?.mcqRules} inputName='mcqRules' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Subjective Rules" inputValue={formData?.subjectiveRules} inputName='subjectiveRules' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="PDF URL" inputType="text" uploadedFileURL={formData?.pdfURL} inputName='pdfURL' accept="pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Syllabus PDF URL" inputType="text" uploadedFileURL={formData?.syllabusPDFURL} inputName='syllabusPDFURL' accept="pdf" />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Topics" inputType="text" inputValue={formData?.topics} inputName='topics' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Tags" inputType="text" inputValue={formData?.tags} inputName='tags' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Demo Video Link" inputType="text" inputValue={formData?.demoVideoLink} inputName='demoVideoLink' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Title" inputType="text" inputValue={formData?.metaTitle} inputName='metaTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Description" inputType="textarea" inputValue={formData?.metaDescription} inputName='metaDescription' divSizes={[2, 10]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTest}>{updatingTestId ? 'Update Test' : 'Create Test'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default TestModal;