export async function getCurrentTime() {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    let seconds = currentTime.getSeconds();
    hours = (hours < 10 ? '0' : '') + hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;
    seconds = (seconds < 10 ? '0' : '') + seconds;
    return `${hours}:${minutes}:${seconds}`;
}


export function convertSpentTimeToSeconds(timeString) {

    const [hours, minutes, seconds] = timeString?.split(':').map(Number);
    // Calculate the total number of seconds
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
};

export function getRemarks(action, value) {

    if (action === 'performance') {
        return (//here value is finalMarks
            value > 80 ? (
                <span>
                    <strong className="text-success">Excellent Performance! </strong>
                </span>
            ) : (
                value > 60 ? (
                    <span>
                        <strong className="text-primary">Good Performance! </strong>
                    </span>
                ) : (
                    value > 50 ? (
                        <span>
                            <strong className="text-info">Average Performance! </strong>
                        </span>
                    ) : (
                        value > 33 ? (
                            <span>
                                <strong className="text-warning">Below Average Performance! </strong>
                                Needs Improvement
                            </span>
                        ) : (
                            <span>
                                <strong className="text-danger">Poor Performance! </strong>
                                Needs Improvement
                            </span>
                        )
                    )
                )
            )
        );



    } else if (action == 'speed') {
        return (
            value < 50 ? (//here value is percentage time spent
                <span>
                    <strong className="text-success">Excellent Speed!</strong>
                </span>
            ) : (
                value < 60 ? (
                    <span>
                        <strong className="text-primary">Good speed! </strong>
                    </span>
                ) : (
                    value < 80 ? (
                        <span>
                            <strong className="text-info">Average speed! </strong>
                        </span>
                    ) : (
                        value < 90 ? (
                            <span>
                                <strong className="text-warning">Below Average speed! </strong>
                            </span>
                        ) : (
                            <span>
                                <strong className="text-danger">Slow Speed! </strong>
                                Needs Improvement
                            </span>
                        )
                    )
                )
            )
        );

    } else if (action == 'accuracy') {
        return (
            value > 80 ? (
                <span>
                    <strong className="text-success">Excellent Accuracy! </strong>
                </span>
            ) : (
                value > 60 ? (
                    <span>
                        <strong className="text-primary">Good Accuracy! </strong>
                    </span>
                ) : (
                    value > 50 ? (
                        <span>
                            <strong className="text-info">Average Accuracy! </strong>
                        </span>
                    ) : (
                        value > 33 ? (
                            <span>
                                <strong className="text-warning">Below Average Accuracy! </strong>
                            </span>
                        ) : (
                            <span>
                                <strong className="text-danger">Poor Accuracy!  </strong>
                                Needs Improvement
                            </span>
                        )
                    )
                )
            )
        );

    }
}

// resetHeaderCheckBox

export function resetHeaderCheckBox() {
    document.querySelectorAll('#checkAll').forEach((input) => {
        input.checked = false;
    });
}
