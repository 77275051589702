import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download files/course_sample_file.docx'

import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput';
import BatchInput from '../Course/BatchInput';
import { fetchBlogData, formateBlogData, validateBlogData } from './BlogHelper';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';
import { getBlogCategories, setDropDownStyle } from '../CommonHelper';



const BlogsModal = forwardRef((props, ref) => {
    let initialFormData = {
        title: '',
        urlTitle: '',
        subtitle: '',
        published: 'NO',
        description: '',
        imageURL: '',
        imageAlt: '',
        imageTitle: '',
        likeStart: 0,
        viewStart: 0,
        imageCaption: '',
        category: 'Select category',
        categoryId: 0,
        keywords: '',
        metaTitle: '',
        metaDescription: '',
        publishDate: new Date(),
        writtenBy: ""
    }
    const [modalBox, setModalBox] = useState(false);    //new course
    const [courseData, setCourseData] = useState('');
    const [blogId, setBlogId] = useState('');
    const [updatingBlogId, setUpdatingBlogId] = useState();
    const [blogCategories, setBlogCategories] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const facultyNamesArray = ['Raaz1', 'Raaz2', 'Raaz3', 'Raaz4']
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    console.log(updatingBlogId)
    useImperativeHandle(ref, () => ({
        openModal(blogId) {
            getBlogCategories().then((blogCategories) => {
                setBlogCategories(blogCategories)

            })
            if (blogId) {
                setUpdatingBlogId(blogId)
                setBlogId(blogId)
                fetchBlogData(blogId).then((blogData) => {
                    formateBlogData(blogData).then(formatedData => {
                        if (formatedData.title) {
                            setFormData(formatedData)
                            setUpdatingDataReady(true)
                            setModalBox(true);
                        }
                    })
                })
            } else {
                setModalBox(true);
                setFormData(initialFormData);
            }
        }
    }));
    console.log(formData)
    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
    //console.log(formData)
    const handleSubmitBlog = () => {
        let errorMessgae = validateBlogData(formData)
        console.log(errorMessgae)
        if (errorMessgae) {
            toast.error(errorMessgae)
            return;
        }
       
        axios.post(BACKEND_SERVER_URL + (updatingBlogId ? `/updateBlog/${updatingBlogId}` : '/createBlog'), formData).then((response) => {
            if (response.data.statusCode == 201) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setBlogsLoading(!props.loadBlogs)
                setModalBox(false);
                setUpdatingBlogId(0);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            console.log('modal close ,data reset')
            setFormData(initialFormData); // Reset form data when modal is closed 
            setUpdatingBlogId(0)
        }
    }, [modalBox]);

    return (
        <>
            {((updatingBlogId && updatingDataReady && modalBox) || (!updatingBlogId && modalBox)) && (<Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingBlogId ? 'Update Blog' : 'Create Blog'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="URL Title" inputValue={formData?.urlTitle} inputName='urlTitle' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Subtitle" inputType="text" inputValue={formData?.subtitle} inputName='subtitle' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Written By" inputType="text" inputValue={formData?.writtenBy} inputName='writtenBy' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Like Start" inputType="number" inputValue={formData?.likeStart} inputName='likeStart' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="View Start" inputType="number" inputValue={formData?.viewStart} inputName='viewStart' divSizes={[4, 8]} />
                                </div>
                               
                            </div>                           
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Published" selectedValue={formData?.published} inputName='published' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <div className="col-sm-4 pt-2">
                                        <div className="mb-3" >
                                            <label htmlFor='news' className="form-label text-primary">Primary Category<span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mb-3">
                                            <Select
                                                value={{ value: formData.category, label: formData.category }}
                                                onChange={(selectedOption) => setFormData({ ...formData, categoryId: selectedOption.value.sn, category: selectedOption.value.title })}
                                                options={blogCategories?.map((item) => ({ value: item, label: item.title }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                  {/*   <DropdownInput handleChangeCallBack={handleChange} labelName="Category" selectedValue={formData?.category} inputName='categories' dropdownValuesArray={["Today's Special", "Blogs"]} /> */}
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Image" inputType="text" uploadedFileURL={formData?.imageURL} inputName='imageURL' accept="image/jpeg, image/png, image/gif" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Image Alt" inputType="text" inputValue={formData?.imageAlt} inputName='imageAlt' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Image Caption" inputType="text" inputValue={formData?.imageCaption} inputName='imageCaption' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Image Title" inputType="text" inputValue={formData?.imageTitle} inputName='imageTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Title" inputType="text" inputValue={formData?.metaTitle} inputName='metaTitle' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Meta Description" inputType="textarea" inputValue={formData?.metaDescription} inputName='metaDescription' divSizes={[2, 10]} />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="KeyWords" inputType="textarea" inputValue={formData?.keywords} inputName='keywords' divSizes={[2, 10]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitBlog}>{updatingBlogId ? 'Update Blogs' : 'Create Blogs'}</Button>
                </Modal.Footer>
            </Modal>)
            }

        </>
    )
})
export default BlogsModal;