import React, {forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify'  

import { BACKEND_SERVER_URL } from '../../../http/http-config';
import Select from 'react-select';

const NotificationModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [notificationTitle, setNewNotificationTitle] = useState('');
    const [notificationDetails, setNotificationDetails] = useState('');

    const [id, setId] = useState();
    const [batches, setBatches] = useState();
    const [selectedBatchId, setSelectedBatchId] = useState(0);    
   

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getBatches').then((res) => {
            if (res.data.batches) {
                setBatches(res.data.batches.filter((batch) => batch.status == 1 && batch.hide == 0));
            }
        })
    }, [])

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            setNewNotificationTitle(item?.notificationTitle);
            setNotificationDetails(item?.notificationDetails);
            setSelectedBatchId(item.batchId)
            setId(item?.id);
            setModalBox(true);
        }
    }));

    const handleNotificationNameChange = (name) => {
        setNewNotificationTitle(name);
    }

    //handle create  course
    const handleSaveNotification = async () => {
        await commonCallBackForSaveUpdate();
    };
    //handle updte course
    const handleUpdateNotification = async () => {
        await commonCallBackForSaveUpdate(id);
    }

    const commonCallBackForSaveUpdate = async (id = 0) => {
        if ((notificationTitle === "" || notificationTitle === undefined)) {
            toast.error("Please Enter notification name ");
        } else if (!selectedBatchId) {
            toast.error("Please select any batch ");
        } else if ((notificationDetails === "" || notificationDetails === undefined)) {
            toast.error("Please Enter notification details ");
        }
        else {
            await props.saveCallBack(notificationTitle, notificationDetails, selectedBatchId, id);
            setModalBox(false);
        }
    }    
  
    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add New Notification</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <label htmlFor="notofication_title" className="form-label d-block">Notification Name</label>
                            <input type="text" className="form-control w-100" id='notofication_title' value={notificationTitle} placeholder="Enter notification Title" onChange={(e) => handleNotificationNameChange(e.target.value)} />

                            {notificationTitle?.length == 0 && <p className='text-danger'>This field cannot be blank</p>}
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="notofication_details" className="form-label d-block">Details</label>
                            <textarea value={notificationDetails ?? ''} id='notofication_details' type="text" className="form-control w-100" placeholder="Enter notification details" onChange={(e) => setNotificationDetails(e.target.value)} />
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="select_batch" className="form-label d-block">Select Batch</label>
                            <Select
                                id='select_batch'
                                value={selectedBatchId ? { value: batches.find((batch) => batch.id == selectedBatchId), label: batches?.find(batch => batch.id == selectedBatchId)?.batchTitle } : null}

                                onChange={(selectedOption) => setSelectedBatchId(selectedOption?.value.id)}
                                options={batches?.map((batch) => ({ value: batch, label: batch.batchTitle }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minWidth: '100%',
                                        backgroundColor: 'white',
                                        borderColor: '#17a2b8',
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs  " onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary  btn-xs  " onClick={!id ? handleSaveNotification : handleUpdateNotification}>{!id ? 'Create' : 'Update'}</button>
                    </div>
                </div>
            </Modal>


        </>
    )
})
export default NotificationModal;