import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";


import Select from 'react-select';
import { getCourses } from '../Batch-Management/BatchHelper';


const AcademicYearModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        inputYear: '',
        currentYear: 'NO'
    }

    const [academicYearData, setAcademicYearData] = useState(initialData);
    console.log(academicYearData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setAcademicYearData({ sn: item.sn, inputYear: item.academicYear, currentYear: item.currentYear ? 'YES' : 'NO', sort: item.sort })
            }
            setModalBox(true);

        }
    }));

    const hanldeSaveAcademicYear = (value) => {
        if (academicYearData.inputYear.trim().length === 0) {
            toast.error("Please Enter Academic Year")
        }
        axios.post(BACKEND_SERVER_URL + `${!academicYearData.sn ? '/saveAcademicYear' : `/updateAcademicYear`}`, { ...academicYearData, currentYear: academicYearData.currentYear === 'YES' ? 1 : 0 }).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                /*  setSn(0);
                 setInputYear(''); */
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setAcademicYearData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setAcademicYearData(initialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {academicYearData.sn ? "Update  Academic Year" : "Add Academic Year"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Academic Year<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={academicYearData.inputYear} onChange={(e) => setAcademicYearData({ ...academicYearData, inputYear: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="currentYear" className="form-label text-primary">Current Year<span className="required">*</span></label>
                            <Select
                                isDisabled={academicYearData.sNo}
                                value={academicYearData.currentYear ? { value: academicYearData.currentYear, label: academicYearData.currentYear } : null}
                                onChange={(selectedOption) => setAcademicYearData({ ...academicYearData, currentYear: selectedOption?.value })}
                                options={['YES', 'NO']?.map((currentYear) => ({ value: currentYear, label: currentYear }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderColor: '#17a2b8',
                                    }),
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={academicYearData.sort} onChange={(e) => setAcademicYearData({ ...academicYearData, sort: Number(e.target.value) })} />
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={hanldeSaveAcademicYear}>{academicYearData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default AcademicYearModal;