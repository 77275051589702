import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

export function createQuestion(question) {
    return axios.post(BACKEND_SERVER_URL + '/createQuestion', question);
}
export function updateQuestion(question, questionId) {
    return axios.post(BACKEND_SERVER_URL + '/updateQuestion/' + questionId, question);
}
export function deleteQuestion(questionId) {
    return axios.post(BACKEND_SERVER_URL + '/deleteQuestion/' + questionId);
}

export function getTestQuestions(testId) {
    return axios.get(BACKEND_SERVER_URL + '/getTestQuestions/' + testId);
}

