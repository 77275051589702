import axios from 'axios';
import { v4 as uuid } from 'uuid'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
export function generateUniqueID() {
    const timestamp = String(Date.now()); // Get current timestamp
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';

    for (let i = 0; i < 8; i++) { // Adjusted to fit 20 characters
        randomId += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }

    const uniqueId = timestamp + randomId;
    return uniqueId.substring(0, 20);
}

export async function getTypes() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTypes')).data.types
}
export async function getExamTypes() {
    return (await axios.get(BACKEND_SERVER_URL + '/getExamTypes')).data.types
}
export async function getFaculties() {
    return (await axios.get(BACKEND_SERVER_URL + '/getCourseFaculties')).data.faculties
}
export async function fetchCourseData(CourseID) {
    return (await axios.get(BACKEND_SERVER_URL + '/getCourse/' + CourseID)).data.course
}
export async function fetchLessionsForCurrrentCourse(CourseID) {
    return (await axios.get(BACKEND_SERVER_URL + '/getLessions/' + CourseID)).data.lessions
}
export async function formateCourseData(data) {
    return ({
        examType: data.ExamBody,
        sNo: data.OrderNo,
        title: data.CourseTitle,
        urlTitle: data.TitleHeading,
        price: data.Price,
        seatBookingPrice: data.SeatBookingPrice,
        discount: data.Discount,
        appDiscount: data.AppDiscount,
        type: data.Type,
        topicCount: data.TopicCount,
        duration: data.Duration,
        faculty: data.Faculty,
        subscriptionCount: data.Subscriber,
        currentBatchID: data.BatchId,
        description: data.Description,
        imageURL: data.Image,
        pdfURL: data.PDFUrl,
        syllabusPDFURL: data.SyllabusPdf,
        topics: data.Topic,
        tags: data.Tag,
        demoVideoLink: data.MetaTitle,
        metaTitle: data.RootType,
        metaDescription: data.MetaDescription,
        courseId: data.CourseID,
        startDate: data.StartDate,
        admissionStartDate: data.AdmissionStart,
    })
}

export function validateCourseData(formData) {
    if (!formData.examType) {
        return "Exam Body is Required";
    }else if (!formData.title) {
        return "Course Title is Required";
    } else if (!formData.urlTitle) {
        return "URL Title is Required";
    } else if (!formData.price) {
        return "Price is Required";
    } else if (!formData.seatBookingPrice) {
        return "Seat Booking Price is Required";
    } else if (!formData.discount) {
        return "Discount is Required";
    } else if (!formData.appDiscount) {
        return "App Discount is Required";
    } else if (!formData.type) {
        return "Type is Required";
    } else if (!formData.topicCount) {
        return "Topic Count is Required";
    } else if (!formData.duration) {
        return "Duration is Required";
    } else if (!formData.faculty) {
        return "Faculty is Required";
    } else if (!formData.subscriptionCount) {
        return "Subscription Count is Required";
    } else if (!formData.currentBatchID) {
        return "Current Batch ID is Required";
    } else if (!formData.description) {
        return "Description is Required";
    } else if (!formData.imageURL) {
        return "Image URL is Required";
    } else if (!formData.pdfURL) {
        return "PDF URL is Required";
    } else if (!formData.syllabusPDFURL) {
        return "Syllabus PDF URL is Required";
    } else if (!formData.topics) {
        return "Topics are Required";
    } else if (!formData.tags) {
        return "Tags are Required";
    } else if (!formData.demoVideoLink) {
        return "Demo Video Link is Required";
    } else if (!formData.metaTitle) {
        return "Meta Title is Required";
    } else if (!formData.metaDescription) {
        return "Meta Description is Required";
    } else if (!formData.courseId) {
        return "Course ID is Required";
    } else if (!formData.startDate) {
        return "Start Date is Required";
    } else if (!formData.admissionStartDate) {
        return "Admission Start Date is Required";
    } else {
        return null; // Return null if all validations pass
    }
}


