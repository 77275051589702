import React from 'react';

const StudentDashboard = () => {
    return (
        <div>
            <h1>Work in progress</h1>
        </div>
    );
}

export default StudentDashboard;
