import axios from "axios"
import { BACKEND_SERVER_URL } from "../../http/http-config"

export function setDropDownStyle() {
    return ({
        control: (provided) => ({
            ...provided,
            width: '100%',
            backgroundColor: 'transparent',
            borderColor: '#17a2b8',
        })
    })
}
export async function getNewsCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getNewsCategories')).data.newsCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ title: news.category, sn: news.sn }))
}
export async function getNewsSubCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getNewsSubCategories')).data.newsSubCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ name: news.subCategory}))
}
export async function getBlogCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBlogCategories')).data.blogCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ title: news.category, sn: news.sn }))
}

export async function getCourses() {
    return (await axios.get(BACKEND_SERVER_URL + '/getCoursesForTopic')).data.courses
}
export async function getSubjects() {
    return (await axios.get(BACKEND_SERVER_URL + '/getSubjectsForTopic')).data.subjects
}
export async function getBatches() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBatchesForDropdowns')).data.batches
}