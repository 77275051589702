import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../Dashboard/Content';
import { Button, Dropdown, Form } from 'react-bootstrap';


import profile from '../../../../images/profile.svg';
import location from '../../../../images/svg/location.svg';
import phone from '../../../../images/svg/phone.svg';
import email from '../../../../images/svg/email.svg';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import axios from 'axios';
import { boolean } from 'yup';
import { CiEdit } from 'react-icons/ci';
import { toast } from 'react-toastify';

const scheduleBlog = [
    { title: 'Basic Algorithm', subtitle: 'Algorithm', image: IMAGES.avat1, color: 'schedule-card' },
    { title: 'Basic Art', subtitle: 'Art', image: IMAGES.avat2, color: 'schedule-card-1' },
    { title: 'React & Scss', subtitle: 'Programming', image: IMAGES.avat3, color: 'schedule-card-2' },
    { title: 'Simple Past Tense', subtitle: 'English', image: IMAGES.avat4, color: 'schedule-card-3' }
];



const StudentAccount = () => {
    const [studentDetails, setStudentDetails] = useState()

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('userDetails');
        setStudentDetails(JSON.parse(tokenDetailsString))
    }, [])


    const [passwords, setPasswords] = useState({ previousPassword: '', newPassword: '', confirmPassword: '' });
    const [matchPassword, setMatchPassword] = useState(false);

    useEffect(() => {
        if ((passwords.newPassword && passwords.confirmPassword) && (passwords.newPassword === passwords.confirmPassword)) {
            setMatchPassword(true);
        } else {
            setMatchPassword(false);
        }
    }, [passwords.newPassword, passwords.confirmPassword]);


    const basicDetail = [
        { title: 'Address', subtitle: studentDetails?.address, image: location },
        { title: 'Email', subtitle: studentDetails?.email, image: email },
        // { title: 'Expiry', subtitle: studentDetails?.expiry, image: email },
        { title: 'Phone', subtitle: '+91 ' + studentDetails?.contact, image: phone },
    ];

    //change password
    const [showPreviousPassword, setShowPreviousPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChangePassword = () => {
        if ((passwords.newPassword && passwords.confirmPassword) && (passwords.newPassword === passwords.confirmPassword)) {
            axios.post(BACKEND_SERVER_URL + '/student/changePassword/' + studentDetails?.studentId, passwords).then((response) => {
                if (response.data.statusCode==200) {
                    console.log(response.data)
                    toast.success(response.data.message)
                     setPasswords({ previousPassword: '', newPassword: '', confirmPassword: '' })
                }else{
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message)
            })
        } else {
            toast.error("Enter similar password")
        }
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card h-auto">
                    <div className="card-body">
                        <div className=" row d-flex justify-content-between">
                            <div className="col-5">
                                <div className="user">
                                    <div>
                                        <div className="  user-media mt-5 pt-5 ">
                                            {studentDetails?.logo ? <img src={BACKEND_SERVER_URL + '/admin-image/' + studentDetails?.logo} alt="" className="avatar avatar-xxl" /> : null}
                                            <h2 className="mb-0 mt-3">{studentDetails?.title}</h2>
                                            <p className="text-primary font-w600">{studentDetails?.subtitle}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-7 d-flex justify-content-between">
                                <div className="user">
                                    <div>
                                        <div className="user-media mt-5 pt-5">
                                            {studentDetails?.photo ? <img src={BACKEND_SERVER_URL + '/getStudentImage/' + studentDetails?.photo} alt="" className="avatar avatar-xxl" /> : null}
                                        </div>
                                        <h3 className="mb-0 mt-0">{studentDetails?.displayName}</h3>
                                        <p className="text-primary font-w600">Student</p>
                                    </div>
                                </div>
                                <Link to={`/student/edit-profile`}>
                                    <CiEdit cursor='pointer' title='Edit Admin Details' size={30} className='ms-3 text-primary' />
                                </Link>
                            </div>

                        </div>
                        <div className="row mt-2">
                            {basicDetail.map((item, ind) => (
                                <div className="col-xl-3 col-xxl-6 col-sm-6" key={ind}>
                                    <ul className="student-details">
                                        <li className="me-2">
                                            <Link to={"#"} className="icon-box bg-secondary">
                                                <img src={item.image} alt="" />
                                            </Link>
                                        </li>
                                        <li>
                                            <span>{item.title}:</span>
                                            <h5 className="mb-0">{item.subtitle}</h5>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card h-auto">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Change Password</h4>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <div className="mb-3 row">
                                    <label className="col-sm-4 col-form-label">Previous Password</label>
                                    <div className="col-sm-8 d-flex">
                                        <input
                                            type={showPreviousPassword ? "text" : "password"}
                                            className="form-control"
                                            placeholder="Previous Password"
                                            value={passwords.previousPassword}
                                            onChange={(e) => setPasswords({ ...passwords, previousPassword: e.target.value })}
                                        />
                                        <div className="input-group-text " onClick={() => setShowPreviousPassword(!showPreviousPassword)}>
                                            {" "}
                                            {showPreviousPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-4 col-form-label">New Password</label>
                                    <div className="col-sm-8 d-flex">
                                        <input
                                            type={showNewPassword ? "text" : "password"}
                                            className="form-control"
                                            placeholder="New Password"
                                            value={passwords.newPassword}
                                            onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
                                        />
                                        <div className="input-group-text " onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {" "}
                                            {showNewPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-4 col-form-label d-flex justify-content-between">Confirm Password</label>
                                    <div className="col-sm-8 d-flex">
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            value={passwords.confirmPassword}
                                            onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })}
                                        />
                                        <div className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {" "}
                                            {showConfirmPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                        </div>
                                    </div>

                                </div>
                                <div className=" d-flex justify-content-end mt-0">
                                    <div>
                                        <button className="btn btn-outline-warning me-3 btn-sm" onClick={() => setPasswords({ previousPassword: '', newPassword: '', confirmPassword: '' })}>Reset</button>

                                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleChangePassword}>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    );
};

export default StudentAccount;