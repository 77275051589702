import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";


import Select from 'react-select';
import { getCourses } from '../Batch-Management/BatchHelper';


const ClassModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    //new course
    const [batchname, setNewBatchName] = useState('');
    const [batchdetails, setNewbatchDetails] = useState('');
    const [sortId, setNewBatchSortID] = useState(0);
    const [modalName, setModal] = useState("")
    const [id, setId] = useState();
    const [isBatchExisted, setIsBatchExisted] = useState(false);

    //batch-testseries mapping 
    const [courses, setCourses] = useState();



    let initialBatchData = {
        sNo: 0,
        courseId: '',
        startDate: new Date(),
        adminCloseDate: new Date(),
        price: 0,
        batchName: '',
        status: '',
        courseTitle: '',
        hide: 0,
        isCurrentBatch: 'NO'
    }
    const [batchData, setBatchData] = useState(initialBatchData);

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setBatchData({ sNo: item.sn, courseId: item.courseId, courseTitle: item.courseTitle, isCurrentBatch: item.isCurrentBatch ? 'YES' : 'NO', startDate: new Date(item.startDate?.toString().replace(/\s+/g, "")), adminCloseDate: new Date(item.adminCloseDate?.toString().replace(/\s+/g, "")), price: item.price, batchName: item.batchName, status: item.status })
            }
            setModalBox(true);
            getCourses().then((res) => {
                setCourses(res)
            })
        }
    }));
    const handleSaveBatch = async () => {
        try {
            const formattedBatchData = {
                ...batchData,
                startDate: batchData.startDate.toISOString().split('T')[0], // Format startDate as YYYY-MM-DD
                adminCloseDate: batchData.adminCloseDate.toISOString().split('T')[0] // Format endDate as YYYY-MM-DD
            };
            const res = await axios.post(BACKEND_SERVER_URL + (batchData.sNo ? `/editCourseBatch/${batchData.sNo}` : '/saveCourseBatches'), formattedBatchData);
            if (res?.data.statusCode == 200) {
                toast.success(res.data.message);
                props.setBatchLoading(!props.loadBatches);
                setModalBox(false);
            }
        } catch (error) {
            console.error("An error occurred during the request:", error.message);

        }
    }
    useEffect(() => {
        if (!modalBox) {
            setBatchData(initialBatchData); // Reset form data when modal is closed
        }
    }, [modalBox]);
    const toggleCurrentBatchHandler = (checked) => {
        console.log(checked)
        if (checked == 'NO') setBatchData({ ...batchData, isCurrentBatch: 'YES' })
        else setBatchData({ ...batchData, isCurrentBatch: 'NO' })
    }
    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {batchData.sNo ? "Update Batch" : "Add Classes"}</h5>
                        <div className=' col-4 m-auto'>                        
                            <Select
                                isDisabled={batchData.sNo}
                                value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                onChange={(selectedOption) => setBatchData({ ...batchData, courseId: selectedOption?.value.CourseID, courseTitle: selectedOption.value.CourseTitle })}
                                options={courses?.map((course) => ({ value: course, label: course.CourseTitle }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderColor: '#17a2b8',
                                    }),
                                }}
                            />
                        </div>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                     
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveBatch}>{batchData.sNo ? "Update Batch" : "Add Classes"}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default ClassModal;