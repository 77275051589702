import React, { Fragment, useEffect, useRef, useState } from "react";
import Course from "./Course";
/// Data
import productData from "../AppsMenu/Shop/productData";
import PageTitle from "../../layouts/PageTitle";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import CourseModal from "./CourseModal";
import axios from "axios";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import swal from 'sweetalert';
import { Button, Spinner } from "react-bootstrap";
import { getExamTypes, getTypes } from "./CourseHelper";
import { toast } from "react-toastify";
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse } from "../common-helpers/commonServices";
import SearchBox from "../Common-Components/SearchBox";
import ProdutsDetailsTabsAtTop from "../Common-Components/ProdutsDetailsTabsAtTop";

const CourseGrid = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [checked, setChecked] = useState(courses);
    const [unchecked, setUnChecked] = useState(true);
    const [loadCourses, setCourseLoading] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState(courses);
    const [examWiseSelectedCourses, setExamWiseSelectedCourses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedExamType, setExamType] = useState('Exam wise selection')
    const [selectedSubjectType, setSubjectType] = useState('Course Type wise selection')
    const [uniqueExamBodies, setUniqueExamBodies] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [uniqueSubjects, setUniqueSubjects] = useState([]);
    const [totalActiveCourses, setTotalActiveCourses] = useState([]);
    const [totalInactiveCourses, setTotalInactiveCourses] = useState([]);
    const [totalArchivedCourses, setTotalUnlisedCourses] = useState([]);

    // search functionality
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredCourses = courses?.filter(
            item => item?.courseTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSelectedCourses(filteredCourses)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getCourses').then((res) => {
            if (res.data) {
                let courses = res.data.courses;
                console.log(courses)
                setCourses(courses)
                setSelectedCourses(courses)
                setTotalActiveCourses(courses.filter(course => course.status == 1 && course.type !== 'Unlised'))
                setTotalInactiveCourses(courses.filter(course => course.status == 0 && course.type !== 'Unlised'))
                let archivedYears = courses.filter(course => course.type == 'Unlised')
                setTotalUnlisedCourses(archivedYears)
            }
        }).catch((error) => {
            console.log(error)
        })
        getExamTypes().then(examTypes => {
            setUniqueExamBodies([...new Set(examTypes.map(course => course.exambody))])
        })
        getTypes().then(types => {
            setCourseTypes(types)
        })

    }, [loadCourses])




    const pageSize = 100;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = selectedCourses.slice(firstIndex, lastIndex);

    const npage = Math.ceil(selectedCourses.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    function splitCamelCase(word) {
        return word?.replace(/([a-z])([A-Z])/g, '$1-$2')
            .split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ');
    }
    const selectExamHandler = (value) => {
        setExamType(value)
        let examWiseSelectedCourses = courses.filter((course) => course.examBody == value)
        setExamWiseSelectedCourses(examWiseSelectedCourses)
        setSelectedCourses(examWiseSelectedCourses)
        setUniqueSubjects([...new Set(courses.filter(course => course.examBody == value).map(course => course.type))]);
        setSubjectType('Subject wise selection')
    }

    const selectSubjectHandler = (value) => {
        setSubjectType(value)
        let subjectWiseSelectedCourses = examWiseSelectedCourses.filter((course) => course.type == value)
        setSelectedCourses(subjectWiseSelectedCourses)
    }
    const handleShowAllCourses = () => {
        setSelectedCourses(courses)
        setExamType('Exam wise selection');
        setSubjectType('Subject wise selection');
        setUniqueSubjects([])
    }


    const handleUnlisedCourse = (CourseID) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this course",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.put(BACKEND_SERVER_URL + `/unlisedCourse/${CourseID}`).then(response => {
                    if (response.data.statusCode == 200) {
                        toast.success(response.data.message)
                        setCourseLoading(!loadCourses)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }
   
    return (
        <Fragment>
            <div className="col-xl-12">
                <div className=" page-title p-2 mb-2">
                    <div className=' col-8 d-flex justify-content-between'>
                        <div className="col-2">
                            <button type="button" className="btn btn-success  btn-xs me-2 " onClick={() => childRef.current?.openModal()}> + Add Course</button>
                        </div>
                        <div className="col-4">
                            <div className="basic-dropdown">
                                <Select
                                    value={{ value: selectedExamType, label: selectedExamType }}
                                    onChange={(selectedOption) => selectExamHandler(selectedOption.value)}
                                    options={uniqueExamBodies.map((examBody) => ({ value: examBody, label: splitCamelCase(examBody) }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="basic-dropdown ms-3">
                                <Select
                                    value={{ value: selectedSubjectType, label: splitCamelCase(selectedSubjectType) }}
                                    onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                                    options={uniqueSubjects?.map((subject) => ({ value: subject, label: splitCamelCase(subject) }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <button type="button" className="btn btn-dark btn-xs ms-2 btn-block " onClick={handleShowAllCourses}> show All</button>
                        </div>
                    </div>
                    <div className=" col-4 input-group search-area mb-md-0 mb-3 w-25 " >

                        <SearchBox setChecked={setSelectedCourses} dataArray={courses} searchingProperty={'courseTitle'} />
                    </div>
                </div>               
                <ProdutsDetailsTabsAtTop setChecked={setSelectedCourses} totalItems={courses} totalActiveItems={totalActiveCourses} totalInactiveItems={totalInactiveCourses} totalArchivedItems={totalArchivedCourses} />
            </div>
            <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div>
                        <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/unlisedCourses', 'Courses', setCourseLoading, loadCourses)}>Delete</Button>
                        <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateCourses', 'Courses', setCourseLoading, loadCourses)}>Active</Button>
                        <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inactivateCourses', 'Courses', setCourseLoading, loadCourses)}>Inactive</Button>
                    </div>
                    <div className='dataTables_info text-dark'>
                        Records: {selectedCourses.length}
                    </div>
                    {selectedCourses.length > pageSize ? <div className="">
                        <div className=""
                            id="example-student_wrapper">
                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                        </div>
                    </div> : null
                    }
                </div>
            </div>

            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="table-responsive full-data">
                    <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                        <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 " id="example-student" >
                            <thead style={{ backgroundColor: "#cccccc" }}>
                                <tr className='text-center text-dark border border-left'>
                                    <th className=' text-dark  p-2'>SN</th>
                                    <th className=' text-dark  p-2'>
                                        <input type="checkbox" className="form-check-input" id="checkAll"
                                            /*   checked={!unchecked} */
                                            onClick={() => handleCheckedAll(unchecked, selectedCourses, setSelectedCourses, setUnChecked)}
                                        />
                                    </th>
                                    <th className='p-2 text-start  text-dark '>STATUS</th>
                                    <th className=' text-start text-dark  p-2' >COURSE TITLE</th>
                                    <th className=' text-start text-dark  p-2' >TITLE HEADING</th>
                                    <th className=' text-start text-dark  p-2' >EXAM BODY</th>
                                    <th className=' text-start text-dark  p-2' >TYPE</th>
                                    <th className='p-2 text-dark '>LESSIONS</th>
                                    <th className='p-2 text-dark '>SUBSCRIBERS</th>

                                </tr>
                            </thead>
                            <tbody>
                                {records.length > 0 ? records.map((item, ind) => (
                                    <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                            <div className="checkbox me-0 align-self-center">
                                                <div className="custom-control custom-checkbox ">
                                                    <input type="checkbox"
                                                        className="form-check-input"
                                                        id={`stud-${item.sn}`}
                                                        checked={item.inputchecked}
                                                        onChange={() => handleChecked(item.sn, setSelectedCourses, selectedCourses)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                            {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                                            {item.status === 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <Link to={`/course-details/${item.courseId}`}>{item.courseTitle}{' '}</Link>

                                                    <CiEdit cursor='pointer' title='Edit Batch' size={18} className='ms-3' onClick={() => childRef.current.openModal(item.courseId)} />
                                                    <MdDeleteOutline  title='Delete Batch' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.courseId, 'Course', '/unlisedCourse/', setCourseLoading, loadCourses) }}
                                                    />
                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 '>{item.titleHeading}{' '}

                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 '>{item.examBody}{' '}

                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 '>{item.type}{' '}

                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1 text-center active-col-width' style={{ border: "1px solid #cccccc", width: '80px' }}><h6 className="mb-0 ">{item.topicCount}</h6></td>
                                        <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                            <h6 className="mb-0 cursor-pointer" onClick={() => navigate('/subscribers', { state: { batchId: item.batchId, courseId: item.courseId } })}>

                                                {item.subscribers}

                                            </h6>
                                        </td>
                                        {/*  <td className='text-start p-1' style={{ border: "1px solid #cccccc" }}>
                                    <h6 className="mb-0" style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.courseTitle}</h6>
                                </td>
                                <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.startDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
                                <td className='text-center p-1' style={{ border: "1px solid #cccccc", width: "65px" }}><h6 className="mb-0">{new Date(item?.adminCloseDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td> */}

                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                {
                                                    showTableResponse(courses,selectedCourses)
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <CourseModal ref={childRef} loadCourses={loadCourses} setCourseLoading={setCourseLoading} />
        </Fragment>
    );
};

export default CourseGrid;