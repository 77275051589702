import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

export async function fetchTestData(CourseID) {
    return (await axios.get(BACKEND_SERVER_URL + '/getTest/' + CourseID)).data.test
}

export async function formateTestData(data) {
    return ({
        sNo: data.Sn,
        examType: data.ExaminationBody,
        title: data.Title,
        titleHeading: data.TitleHeading,
        price: data.Price,
        seatBookingPrice: data.SeatBookingPrice,
        discount: data.Discount,
        appDiscount: data.AppDiscount,
        type: data.Type,
        quizCount: data.QuizCount,
        duration: data.Duration,
        faculty: data.Faculty,
        subscriptionCount: data.SubscriberCount,
        currentBatchID: data.BatchId,
        description: data.Description,
        imageURL: data.Image,
        pdfURL: data.PDFUrl,
        syllabusPDFURL: data.SyllabusPDFUrl,
        topics: data.Topic,
        tags: data.Tags,
        demoVideoLink: data.MetaTitle,
        metaTitle: data.RootType,
        metaDescription: data.MetaDescription,
        courseId: data.CourseID,
        startDate: data.PublishDate,
        admissionStartDate: data.AdmissionStartDate,
        mcqRules: data.Rules,       
        subjectiveRules: data.RulesSubjective
    })
}