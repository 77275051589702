import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import BatchSampleFile from '../../../download files/batch_sample_file.docx'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";


import Select from 'react-select';
import { getCourses } from '../Batch-Management/BatchHelper';


const ExamBodyModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);

    let initialData = {
        sn: 0,
        sort: 0,
        examBody: '',
    }

    const [examBodyData, setExamBodyData] = useState(initialData);
    console.log(examBodyData)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setExamBodyData({ sn: item.sn, examBody: item.examBody, sort: item.sort })
            }
            setModalBox(true);

        }
    }));

    const hanldeSaveAcademicYear = (value) => {
        if (examBodyData.examBody.trim().length === 0) {
            toast.error("Please Exam Body ")
        }
        axios.post(BACKEND_SERVER_URL + `${!examBodyData.sn ? '/saveExamBody' : `/updateExamBody`}`, examBodyData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setExamBodyLoading(!props.loadExamBodies);
                setExamBodyData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setExamBodyData(initialData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {examBodyData.sn ? "Update Exam Body" : "Add Exam Body"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Exam Body<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={examBodyData.examBody} onChange={(e) => setExamBodyData({ ...examBodyData, examBody: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={examBodyData.sort} onChange={(e) => setExamBodyData({ ...examBodyData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={hanldeSaveAcademicYear}>{examBodyData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default ExamBodyModal;